app.directive('processOnBlur', [function(){
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function($scope, $elem, $attr, ngModel){

            //Processar somente quando ocorrer o blur
            ngModel.$overrideModelOptions({
                updateOn: 'blur'
            });
        }
    }
}]);