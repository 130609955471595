app.component('labelInput', {
    template : `
        <div ng-switch="$ctrl.canEdit">
            <div ng-switch-when="false" class="d-flex w-100 justify-content-between"
                style="line-height: 2.5;" ng-click="$ctrl.handleModeChange()">
                <span style="{{$ctrl.labelStyle}}">{{$ctrl.value}}</span>
                <a class="btn btn-link" href="javascript:void(0)"><i class="fa fa-pencil"/></a>
            </div>
            <form ng-switch-default class="input-group" ng-submit="$ctrl.handleModeChange()">
                <input class="form-control" ng-model="$ctrl.value"/>
                <div class="input-group-btn">
                    <button class="btn btn-primary" type="submit"><i class="fa fa-check"/></button>
                </div>
            </form>
        </div>
    `,
    bindings : {
        value: '<',
        onUpdate: '&',
        labelStyle: '@?'
    },
    controller : ['$element', '$timeout', function($element, $timeout){
        var ctrl = this;
        ctrl.canEdit = false;

        ctrl.handleModeChange = function(){
            if(ctrl.canEdit) {
                ctrl.onUpdate({value: ctrl.value});
                ctrl.valueCopy = ctrl.value;
            } else {
                $timeout(function(){
                    $element.find('input').focus();
                }, 100); 
            }
            ctrl.canEdit = !ctrl.canEdit;
        }

        ctrl.$onInit = function(){
            ctrl.valueCopy = ctrl.value;
        }


    }]
})