app.controller('dados.associativo.analise.ctrl', ['$scope', 'analise', 'Message', function($scope, analise, Message){
    //Dados para trabalho
    $scope.analise = analise;
    $scope.plano = analise.plano_associativo;
    
    $scope.addTipo = function() {
        $scope.plano.unidades.push({
            tipo : '',
            area : null,
            qtd : null
        });
        $scope.save();
    }

    $scope.removeTipo = function(tipo) {
        var idx = $scope.plano.unidades.indexOf(tipo);
        $scope.plano.unidades.splice(idx, 1);
        $scope.save();
    }

    $scope.refreshVendas = function(){

        var tabela;
        var size = $scope.plano.prazo_obra;

        if(!$scope.plano.vendas_durante_obra) tabela = [];
        else tabela = angular.copy($scope.plano.vendas_durante_obra);
       
        if(tabela.length > size) {
            tabela = tabela.splice(0, size);
        } else while(tabela.length < size) {
            tabela.push({
                mes: tabela.length + 1, 
                qtd: 0
            });
        }

        $scope.plano.vendas_durante_obra = tabela;
        $scope.save();
    }
}]);