app.controller('cadastro.voucher.controller', ['$scope', 'Voucher', '$state', function($scope, Voucher, $state){
    $scope.voucher = {
        qtd_usos: 1,
        qtd_geral: 1,
        validade: Date.now(),
        desconto: []
    }

    $scope.add = function(){
        for(var i = 0; i  < $scope.voucher.desconto.length; i++) {
            if($scope.voucher.desconto[i].recurso._id == $scope.desconto.recurso._id){
                alert("Este aplicativo já foi adicionado ao voucher.");
                return;
            }
        }

        if(!$scope.desconto.dias && !$scope.desconto.meses && !$scope.desconto.anos){
            alert("Informe um periodo valido.");
            return;
        }

        $scope.voucher.desconto.push($scope.desconto);
        
        $scope.desconto = {
            recurso: $scope.apps[0],
            dias: 0,
            meses: 0,
            anos: 0
        };

        $('#modal-add-app').modal('hide')
    }

    $scope.remove = function(d) {
        var idx = $scope.voucher.desconto.indexOf(d);
        $scope.voucher.desconto.splice(idx, 1);
    }

    $scope.desconto = {
        dias: 0,
        meses: 0,
        anos: 0
    };
    $scope.apps = [];

    Voucher.apps().then(function(res){
        $scope.apps = res.data;
        $scope.desconto.recurso = res.data[0];
    });

    $scope.save = function(){
        Voucher.create($scope.voucher).then(function(res){
            if(res.data.error) {
                alert(res.data.message);
            } else {
                alert('Voucher criado com sucesso!');
            }
            
        }, function(res){
            alert(res.data.message);
        });
    }

}]);