app.controller('dashboard.empresario.analise.ctrl', ['$scope', 'analise', 'Empreendimento', '$filter', function($scope, analise, Empreendimento, $filter){
    $scope.analise = analise;
    $scope.plano = analise.plano_empresario;
    
    $scope.reload = function() {
        $scope.fluxo = $scope.plano.fluxoTotal();

        if($scope.$parent.other) {
            $scope.otherPlano = $scope.$parent.other;
            $scope.otherFluxo = $scope.$parent.other.fluxoTotal();
        } else { 
            $scope.otherFluxo = false;
        }

        $scope.plot.init($scope.fluxo, $scope.otherFluxo);
        $scope.calcularIndices();
    }

    $scope.calcularIndices = function(){
        var f = [];
        $scope.investimento_maximo = 0;
        $scope.qtd_investimento = 0;
        console.log($scope.fluxo)
        for(var i = 0; i < $scope.fluxo.length; i++){
            if($scope.fluxo[i]){
                var saldo = $scope.fluxo[i].total;
                var receita = $scope.fluxo[i].total_faturamento - $scope.fluxo[i].impostos;
                
                var saldo_sem_fin = saldo - ($scope.fluxo[i].financiamento||0) + ($scope.fluxo[i].custo_financiamento||0)
                //console.log(i, saldo, -($scope.fluxo[i].financiamento||0), +($scope.fluxo[i].custo_financiamento||0), saldo_sem_fin);

                var investimento = 0;
                var investimento_total = 0;
                if(saldo < 0) {
                    investimento = saldo * -1;
                    $scope.qtd_investimento++;
                }

                if(saldo_sem_fin < 0) {
                    investimento_total = saldo_sem_fin * -1;
                }

                if(investimento > $scope.investimento_maximo) $scope.investimento_maximo = investimento;
                
                
                f.push({ saldo, receita, investimento, investimento_total });
            } else {
                f.push({
                    value: 0,
                    receita: 0,
                    investimento: 0,
                    investimento_total: 0
                });
            }
        }

        $scope.vpl = Empreendimento.vpl($scope.plano.custo_oportunidade / 100, f, 'saldo', true);
        $scope.rpl = Empreendimento.vpl($scope.plano.custo_oportunidade / 100, f, 'receita', true);
        $scope.ipl = Empreendimento.vpl($scope.plano.custo_oportunidade / 100, f, 'investimento_total', true);
        $scope.ipl_proprio = Empreendimento.vpl($scope.plano.custo_oportunidade / 100, f, 'investimento', true);
        $scope.ipl_medio = $scope.ipl / f.length;

        $scope.margem_r = ($scope.vpl / $scope.rpl) * 100;
        $scope.margem_i = ($scope.vpl / $scope.ipl) * 100;
        $scope.margem_g = ($scope.rpl / $scope.ipl) * 100;
        $scope.margem_h = ($scope.rpl / $scope.ipl_proprio) * 100;
        $scope.fce = (Math.pow(($scope.margem_i / 100 + 1), (1/$scope.qtd_investimento)) - 1) * 100;
    }

    $scope.otherVpl = function(){
        return Empreendimento.vpl($scope.$parent.other.custo_oportunidade / 100, $scope.otherFluxo, 'total', true);
    }

    $scope.tir = function() {
        return Empreendimento.tir($scope.fluxo, 'total');
    }

    $scope.otherTir = function() {
        return Empreendimento.tir($scope.otherFluxo, 'total');
    }

    $scope.otherMargem = function() {
        return $scope.otherVpl() / $scope.$parent.other.vgv * 100;
    }

    $scope.plot = {
        investimentoMaximo: 0,
        series : ["Faturamento"],
        labels : [],
        data : [],
        init : function(fluxo, otherFluxo){
            var max = 0, min = 0;
            
            this.data = [];
            this.labels = [];
            
            //Preencher grafico e guardar maior e menor valor
            var series1 = [];
            for(var i = 0; i < fluxo.length; i++){
                this.labels.push("Mês " + i);
                var total = fluxo[i].total;
                series1.push(total);
                if(total > max) max = total;
                if(total < min) min = total;
            }
            this.data.push(series1);

            if(otherFluxo) {
                var series2 = [];
                for(var i = 0; i < otherFluxo.length; i++){
                    if(this.labels.length < i)
                        this.labels.push("Mês " + i);
    
                    var total = otherFluxo[i].total;

                    series2.push(total);
                    if(total > max) max = total;
                    if(total < min) min = total;
                }
                
                this.data.push(series2);
            } 

            var mult = 1;
            var range = max - min;
            var temp = range;
            while(temp > 10){
                mult *= 10;
                temp = range / mult;
            }
            
            temp = Math.round(temp) * mult;
            var step = temp / 15;

            this.options = {
                tension: 0,
                tooltips : {
                    callbacks : {
                        label : function(tooltipItem){
                            return $filter('currency')(tooltipItem.yLabel, 'R$ ', 2);
                        }
                    }
                },
                scales : {
                    yAxes: [
                        {
                            type: 'linear',
                            display: true,
                            position: 'left',
                            ticks: {
                                maxTicksLimit: 20,
                                stepSize : step,
                                max : max,
                                min : min,
                                callback: function(value, index, values){
                                    return $filter('currency')(value, 'R$ ', 2);
                                }
                            }
                        }
                    ]
                }
            }


        }
    }

    $scope.reload();
}]);