app.component('addUserModal', {
    template: //html 
    `
        <div id="add-user-modal" class="modal fade">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5>Convidar Usuário</h5>
                        <button class="close" data-dismiss="modal">
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <label for="search-name-input">Pesquisar usuário</label>
                                <div class="form-control" ng-click="$ctrl.focusInput();">
                                    <span ng-repeat="u in $ctrl.added_users" class="alert alert-info p-1 mr-1">
                                        {{u.name}} <a href="javascript:void(0)" ng-click="$ctrl.removeUser(u)"><i class="fas fa-times"></i></a>
                                    </span>
                                    <input id="search-name-input" style="border: none; outline: none;" ng-model="$ctrl.name_search" ng-change="$ctrl.searchUsers()" 
                                        autocomplete="off" type="search" ng-model-options='{ debounce: 400 }'>
                                </div>
                            </div>

                            <div class="col-12 mt-2">
                                <div class="list-group list-group-flush">
                                    <a class="list-group-item" ng-repeat="u in $ctrl.user_list" href="javascript:void(0)"
                                        ng-click="$ctrl.addUser(u)" ng-class="{ 'active': $ctrl.isSelected(u) }">
                                        <img src="{{u.profile.image_url}}"/>
                                        {{u.name}}
                                    </a>
                                </div>
                            </div>

                            <div class="col-12 mt-2">
                                <label>Permissão</label>
                                <select class="custom-select form-control" ng-model="$ctrl.authority">
                                    <option value="OWNER">Propietário</option>
                                    <option value="ANALIST">Analista</option>
                                    <option value="AGENT">Corretor</option>
                                </select>
                            </div>

                            <div class="col-12 mt-2 justfify-content-end">
                                <button class="btn btn-primary" ng-click="$ctrl.invite();">
                                    <i class="fa fa-user-plus"></i> Convidar
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    `,
    bindings: {
        group: '='
    },
    controller: ['Group', 'Message', function(Group, Message){
        var scope = this;
        scope.focusInput = function() {
            $('#search-name-input').focus();
        }

        scope.searchUsers = function(){
            
            if(scope.name_search.length < 2) {
                scope.user_list = [];
                return;
            }

            Group.availableUsers(scope.group, {name: scope.name_search}).then((res) => {
                if(res.data.error) {
                    Message.alert(res.data.message, res.data.detail);
                    return;
                }

                scope.user_list = angular.copy(res.data);
            });
        }

        scope.addUser = function(user) {
            if(!scope.isSelected(user))
                scope.added_users.push(user);
            scope.name_search = "";
            scope.user_list = [];
            scope.focusInput();
        }

        scope.removeUser = function(user) {
            for(var i = 0; i < scope.added_users.length; i++) {
                var other = scope.added_users[i];
                if(user._id == other._id) scope.added_users.splice(i, 1);
            }
            scope.focusInput();
        }

        scope.isSelected = function(user) {
            for(var i = 0; i < scope.added_users.length; i++) {
                var other = scope.added_users[i];
                if(user._id == other._id) return true;
            }
            return false;
        }

        scope.invite = function(){
            console.log(scope.authority);
            console.log(scope.group);
            for(var i = 0; i < scope.added_users.length; i++) {
                var user = scope.added_users[i];
                Group.grant(scope.group, user, {
                    authority: scope.authority
                });
            }
            $('#add-user-modal.modal').modal('hide');
        }

        scope.$onInit = function(){
            scope.name_search = "";
            scope.added_users = []; 
            scope.user_list = [];   
            scope.authority = "ANALIST";
        }
    }]
})