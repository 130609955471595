app.controller('criar-grupo.controller', ['$scope', 'Session', 'Group', '$state', 'Message', function($scope, Session, Group, $state, Message){
    $scope.grupo = {};
    $scope.placeholder = 'Grupo de ' + Session.getCredentials().name;
    $scope.loading = false;
    
    $scope.next = function(){
        $scope.loading = true;
        Group.create($scope.group).then(function(res){
            if(res.data.error) {
                $scope.error = res.data.message;
                $scope.loading = false;
                return;
            }

            $state.go('app.contratar', {grupo: res.data._id});
        }, function(err){
            Message.alert('Erro', 'Ocorreu um erro ao criar o grupo, tente novamente mais tarde')
            $scope.loading = false;
        });
    }
}]);