app.controller('analises.ctrl', ['$scope', 'analises', 'recursos', 'group', 'Analise', '$state', 'userGroup', 'Message', function($scope, analises, recursos, group, Analise, $state, userGroup, Message){
    $scope.list = analises;
    $scope.recursos = recursos;
    $scope.grupo = group;
    $scope.userGroup = userGroup;

    $scope.analises = {}

    $scope._filter = ""
    $scope.filter = function(){
        $scope.analises = {};
        for(var i in $scope.list) {
            var analise = $scope.list[i];
            if(analise.nome.indexOf($scope._filter) < 0) continue;

            var recurso = analise.recurso.nome;
            if(!$scope.analises[recurso])
                $scope.analises[recurso] = {
                    nome: analise.recurso.descricao,
                    list: []
                };
            $scope.analises[recurso].list.push(analise);
        }
    }
    $scope.filter();

    $scope.remove = function(analise) {
        $scope.selected = analise;
        $('#confirm-delete-analise').modal('show');
    }

    $scope.confirmRemoveAnalise = function(){
        Message.showSpinner();
        Analise.delete(group._id, $scope.selected).then(function(){
            $state.reload();
        }).finally(() => {
            Message.hideSpinner();
        });
    }

    $scope.analise = {};
    $scope.save = function() {
        Message.showSpinner();
        Analise.create(group._id, $scope.analise).then(function(res){
            if(res.data.error) {
                $scope.error = res.data.message;
            } else {
                $('#cadastro-modal').modal('hide');
                $state.reload();
            }
        }, function(err){
            alert("Ocorreu um erro desconhecido ao criar a analise, tente novamente.")
        }).finally(() => {
            Message.hideSpinner();
        });
    }

    $scope.showNovo = function() {
        $('#cadastro-modal').modal('show');
    }

    $scope.showNovo = function() {
        $('#cadastro-modal').modal('show');
    }

    $scope.showInviteUsers = function() {
        $('#add-user-modal.modal').modal('show');
    }
    
    $scope.showManageUsers = function(){
        $('#manage-user-modal.modal').modal('show');
    }

    $scope.showManageApps = function(){
        $('#active-apps-modal').modal('show');
    }

    $scope.showApplyVoucher = function(){
        $('#voucher-modal').modal('show');
    }

    $scope.hideVoucher = function(){
        $('#voucher-modal').modal('hide');
    }

    $scope.voucherSuccess = function(data){
        $('#voucher-modal').on('hidden.bs.modal', function(){
            $state.reload();
        });
        $('#voucher-modal').modal('hide');
    }
    
}]);