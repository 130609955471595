app.factory('Voucher', ['$http', '$url', function($http, $url){
    var voucher = {};
    
    voucher.find = function(codigo){
        var url = '/voucher/' + codigo;
        return $http.get(url);
    }

    voucher.apps = function(){
        var url = '/voucher/apps';
        return $http.get(url);
    }

    voucher.apply = function(codigo, group){
        return $http.get($url(['voucher', codigo, group._id]));
    }

    voucher.create = function(voucher) {
        return $http.post('/voucher', voucher);
    }

    //TODO create, updaate, delete
    return voucher;
}]);