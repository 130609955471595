app.controller('informacoes-negocio.empresario.analise.ctrl', ['$scope', 'analise', function($scope, analise){
    //dados para trabalho
    $scope.analise = analise;
    $scope.plano = analise.plano_empresario;
    
    var criarItem = function(nomeItem){
        return {
            nome: nomeItem,
            mes : 0,
            valor: 0
        };
    };

    $scope.adicionar = function(){
        $scope.plano.outros_custos.push(criarItem(""));
    }

    $scope.remove = function(o) {
        var index = $scope.plano.outros_custos.indexOf(o);
        $scope.plano.outros_custos.splice(index, 1);
    }

    $scope.total = function(valor, mes){
        return valor * Math.pow(1 + $scope.plano.custo_oportunidade / 100, mes);
    }

    $scope.totalGeral = function(){
        var ttl = 0;
        for(var i = 0; i < $scope.plano.outros_custos.length; i++) {
            ttl += $scope.total($scope.plano.outros_custos[i].valor, $scope.plano.outros_custos[i].mes);
        }
        return ttl;
    }
    
}]);