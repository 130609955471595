app.controller('negociacao.vendas.analise.ctrl', ['$scope', 'userGroup', 'negociacao', 'analise', 'Negociacao', 'Message', 'Session', '$state', function($scope, userGroup, negociacao, analise, Negociacao, Message, Session, $state) {
    $scope.analise = analise;
    $scope.venda = analise[analise.recurso.nome];
    $scope.negociacao = negociacao;
    $scope.userGroup = userGroup;
    
    $scope.user = Session.getCredentials();

    var best = -1, bestVpl = 0;
    for(var i = 0; i < $scope.negociacao.propostas.length; i++) {
        var proposta = $scope.negociacao.propostas[i].proposta;
        var vpl = $scope.venda.vpl(proposta);
        console.log(proposta, vpl);
        if(vpl > bestVpl) {
            best = i;
            bestVpl = vpl;
        }
    }

    console.log(best, bestVpl);
    if(best >= 0) $scope.negociacao.propostas[best].proposta.best = true;

    $scope.aceitar = function(index) {
        Message.showSpinner();
        Negociacao.aceitar($scope.negociacao, index).then((res) => {
            if(res.data.error) {
                Message.alert(res.data.message, res.data.detail);
            } else {
                Message.alert("Vendido!", "Proposta aceita com sucesso!", () => {
                    $state.reload();
                });
            }
        }).finally(() => {
            Message.hideSpinner();
        });
    }

    $scope.recusar = function(index) {
        Message.showSpinner();
        Negociacao.recusar($scope.negociacao, index).then((res) => {
            if(res.data.error) {
                Message.alert(res.data.message, res.data.detail);
            } else {
                Message.alert("Recusado", "Proposta recusada com sucesso!", () => {
                    $state.reload();
                });
            }
        }).finally(() => {
            Message.hideSpinner();
        });
    }
    
}]);