app.directive('bsTooltip', [function(){
    return {
        restrict: 'A',
        link: function(scope, el, attr){
            var $el = $(el);
            
            if(attr.bsTooltip) $el.attr('data-placement', attr.bsTooltip);
            $el.attr('data-toggle', 'tooltip');
            
            $el.tooltip();
        }
    }
}]);