app.controller('app.controller', ['$scope', 'Session', function($scope, Session){
    $scope.user = Session.getCredentials();

    $scope.filterText = "";
    const _glossario = [
        {
            title: 'Pro Soluto',
            content: [
                "Pro soluto -, as promissórias são entregues e recebidas 'como pagamento', ' a título de solução', 'dando quitação'.",
                "A compra e venda, assim sendo, é irrevogável e irretratável, fica perfeita e acabada.",
                "Se as ditas notas promissórias, afinal, não forem pagas, o contrato respectivo não pode ser atacado, não pode ser desfeito, permanece firme e inabalável.",
                "Aquele que comprou o bem continua sendo dono dele, restando ao vendedor recorrer à Justiça, executar o título de crédito, tentar receber o que lhe é devido."
            ]
        },
        {
            title: 'Sinal',
            content: [
                "É a primeira parcela paga, ou seja, a parcela que se dá imediatamente após a assinatura do contrato contrato. Em algumas regiões do Brasil é tambem chamada de parcela no ato. "
            ]
        },
        {
            title: 'Intercaladas',
            content: [
                "São as parcelas intermediárias, entre a inicial e a parcela das chaves, e que são esporádicas."
            ]
        },
        {
            title: 'Custo de Oportunidade',
            content: [
                "O Custo de Oportunidade é um termo usado em economia para indicar o custo de algo em termos de uma oportunidade renunciada, no nosso caso, já descontada a inflação.",
                "Por exemplo: A alternativa a um investimento, nos dias de hoje, é uma aplicação financeira de 5,50% ao ano (mercado), líquida ou 0,407 ao mês. No meu exemplo estou arredondando para 0,5%, ou seja a favor da segurança."
            ]
        },
        {
            title: "NÚMERO DE PARCELAS MENSAIS",
            content: [
                "É a quatidade de parcelas pagas regularmente por mês, e que podem ou não serem efetuadas durante ou após o habite-se, ou seja, antes ou depois do recebimento do imóvel. Quando estas parcelas não possuem vinculação em contrato com referência a compra e venda, é chamada de pro-soluto."
            ]
        },
        {
            title: 'Chaves',
            content: [
                "É a parcela paga pelo mutuário, no momento da entrega da unidade habitacional."
            ]
        },
        {
            title: 'JUROS DURANTE A OBRA',
            content: [
                "Nos empreendimentos com financiamento direto pelo incorporador ou pelo SBPE – Sistema Brasileiro de Poupança e Empréstimo, há uma cobrança de juros durante a obra até o repasse da unidade ao cliente final.",
                "Em algumas regiões do Brasil, esta cobrança pode ser em juros simples ou compostos.",
                "Por isso as duas opções como premissas. No caso dos programas de habitação popular, o mutuario, durante a obra, paga os juros diretamente ao agente financeiro (CAIXA ou BB)."
            ]
        },
    ];

    $scope.glossario = [];
    var search = function(t1, t2) {
        t1 = t1.toUpperCase();
        t2 = t2.toUpperCase();
        var ts = t2.split(' ');
        for(var i in ts) {
            if(t1.indexOf(ts[i]) >= 0) return true;
        }

        return false;
    }

    $scope.filter = function(){
        $scope.glossario = [];
        for(var i in _glossario) {
            var item = _glossario[i];
            if(search(item.title, $scope.filterText)){
                $scope.glossario.push(item);
            }
        }
    }

    $scope.filter();
}]);