app.controller('dashboard.vendas.analise.ctrl', ['$scope', 'analise', 'statistics', function($scope, analise, statistics) {
    $scope.venda = analise[analise.recurso.nome];

    $scope.status = {
        labels: ["Vendidos", "Em Negociação", "Sem Propostas"],
        colors: ["#12e311", "#36a2eb", "#f2f2f2"],
        data: [0, 0, 0],
        options: {
            legend: {
                display: true
            }
        }
    } 

    $scope.meta = {};
    $scope.vendas = [];
    for(var i = 0; i < statistics.length; i++) {
        var apt = statistics[i];

        if(apt.negociacao_aceita) {
            $scope.status.data[0]++;
            
            var proposta = null, valor = 0;
            if(apt.negociacao_aceita.propostas[apt.negociacao_aceita.proposta_aceita]){
                proposta = apt.negociacao_aceita.propostas[apt.negociacao_aceita.proposta_aceita].proposta;
                valor = $scope.venda.total(proposta);
            }

            var corretor = "[Sem Corretor]"
            if(apt.negociacao_aceita.corretor) {
                corretor = apt.negociacao_aceita.corretor.name;
            } 

            if(!$scope.meta[corretor]) $scope.meta[corretor] = {nome: corretor, vendas: 0, propostas: 0, comissao: 0};
            $scope.meta[corretor].vendas++;
            $scope.meta[corretor].comissao += valor * $scope.venda.comissao_corretor / 100;

            $scope.vendas.push({
                codigo: apt.codigo,
                comprador: apt.negociacao_aceita.comprador.nome,
                data: apt.data_venda,
                valor: valor
            });
        } else if(apt.propostas.length > 0) {
            $scope.status.data[1]++;
        } else {
            $scope.status.data[2]++;
        }
        
        for(var j = 0; j < apt.propostas.length; j++) {
            var proposta = apt.propostas[j];
            if(proposta.corretor) {
                var corretor = proposta.corretor.name;
                if(!$scope.meta[corretor]) $scope.meta[corretor] = {nome: corretor, vendas: 0, propostas: 0, comissao: 0};
                $scope.meta[corretor].propostas++;
            } else {
                if(!$scope.meta["[Sem Corretor]"]) $scope.meta["[Sem Corretor]"] = {nome: corretor, vendas: 0, propostas: 0, comissao: 0};
                $scope.meta["[Sem Corretor]"].propostas++;
            }
        }
    }

    $scope.atividade = {
        labels: [],
        series: ["Vendas", "Propostas"],
        data: [
            [],
            []
        ],
        options: {
            legend: {
                display: true
            }
        }
    }

    for(var c in $scope.meta) {
        $scope.atividade.labels.push(c);
        $scope.atividade.data[0].push($scope.meta[c].vendas)
        $scope.atividade.data[1].push($scope.meta[c].propostas)
    }

    $scope.sales = {
        labels: ["Janeiro/2019",  "Fevereiro/2019", "Março/2019", "Abril/2019", "Maio/2019"],
        series: ["Vendas"],
        data: [[3, 4, 9, 7, 8]],
        options: {
            
        }
    }

    $scope.vgv = {
        labels: ["Janeiro/2019",  "Fevereiro/2019", "Março/2019", "Abril/2019", "Maio/2019"],
        series: ["VGV Previsto", "VGV Atual"],
        data: [
            [7200000, 7200000, 7200000, 7200000, 7200000],
            [540000, 1260000, 2880000, 4140000, 5580000]
        ],
        colors: ["#cecece", "#36a2eb"],
        options: {
            
        }
    }

    $scope.ivv = {
        data: [6.4, 5.3, 4.2, 6.5, 6.7],
        labels: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio"]
    }

    $scope.comparacao = {
        data: [
            [5, 5, 5, 5, 5, 5, 5],
            [3, 4, 5, 5, 6, 7, 6]
        ],
        series: ["Previsão", "Vendas"],
        colors: ["#36a2eb", "#12e311"],
        labels: ["Janeiro/2019",  "Fevereiro/2019", "Março/2019", "Abril/2019", "Maio/2019", "Junho/2019", "Julho/2019"]
    }

}]);