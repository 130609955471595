app.component('tabelaVenda', {
    template : //html
    ` 
      <div class="card m-1">
        <div class="card-body">
            
            <div class="d-flex w-100 justify-content-between align-items-center">
                <h5 class="card-title">Regra de Venda</h5>
                <button class="btn btn-danger btn-sm" ng-click="$ctrl.remove()"><i class="fas fa-times"></i></button>
            </div>

            <div class="form-row">
                <div class="col">
                    <label for="mes-apuracao">Mês de Apuração</label>
                    <input id="mes-apuracao" class="form-control form-control-sm" number-format="0" ng-model="$ctrl.model.mes_inicio"
                        ng-change="$ctrl.handleChange('mes-apuracao')">
                </div>
            </div>

            <div class="form-row">
                <div class="col">
                    <label for="sinal">Sinal + Contrato (%)</label>
                    <input id="sinal" class="form-control form-control-sm" number-format ng-model="$ctrl.model.pct_sinal"
                        ng-change="$ctrl.handleChange('pct-sinal')">
                </div>
            </div>

            <div class="form-row">
                <div class="col-12 col-md-4">
                    <label for="qtd-parcela">Qtd. Parcelas</label>
                    <input id="qtd-parcela" class="form-control form-control-sm" number-format ng-model="$ctrl.model.numero_parcelas"
                    ng-change="$ctrl.handleChange('qtd-parcela')">
                </div>

                <div class="col-12 col-md-8">
                    <label for="valor-parcela">Valor de cada Parcela (%)</label>
                    <input id="valor-parcela" class="form-control form-control-sm" number-format ng-model="$ctrl.model.pct_parcela"
                        ng-change="$ctrl.handleChange('valor-parcela')">
                </div>
            </div>
            
            <div class="">
            </div>
            <div class="d-flex w-100 justify-content-between mt-2">
                <h6 class="card-title">Intercaladas</h6>
                <span>
                    <button class="btn btn-sm btn-primary" ng-click="$ctrl.addInt()"><i class="fas fa-plus"></i></button>
                    <button class="btn btn-sm btn-danger" ng-click="$ctrl.rmvInt()"><i class="fas fa-minus"></i></button>    
                </span>
            </div>

            <div class="form-row mt-2 align-items-center" ng-repeat="intercalada in $ctrl.model.intercaladas">
                <div class="col text-right">
                    <label for="int-mes-{{$index}}">{{$index + 1}}º Intercalada</label>
                </div>
                <div class="col">
                    <div class="input-group input-group-sm">
                        <span class="input-group-addon">Mês</span>
                        <input id="int-mes-{{$index}}" class="form-control" number-format="0" ng-model="intercalada.mes"
                            ng-change="$ctrl.handleChange('int-mes-' + $index)">
                    </div>
                </div>
                <div class="col">
                    <div class="input-group input-group-sm">
                        <span class="input-group-addon">Valor (%)</span>
                        <input id="int-pct-{{$index}}" class="form-control" number-format ng-model="intercalada.pct"
                            ng-change="$ctrl.handleChange('int-pct-' + $index)">
                    </div>
                </div>
            </div>

            <div class="form-row">
                <div class="col">
                    <label for="valor-chaves">Chaves (%)</label>
                    <input id="valor-chaves" class="form-control form-control-sm" number-format ng-model="$ctrl.model.pct_chaves"
                        ng-change="$ctrl.handleChange('valor-chaves')">
                </div>
            </div>

            <div class="row mt-2">
                <div class="col">
                    <label>Total (%)</label>
                    <input class="form-control form-control-sm" ng-class="{'is-invalid':$ctrl.error.total}" disabled value="{{$ctrl.model.total | number : 2}}">
                    <span class="invalid-feedback">{{$ctrl.error.total}}</span>
                </div>
            </div>
        </div>
      </div>  
    `,
    bindings : {
        model: '=',
        onRemove: '&',
        onChange: '&'
    },
    transclude: true,
    controller : [function(){
        
        this.addInt = () => {
            var mes = this.model.intercaladas.reduce((t, i) => (i.mes > t ? i.mes : t), 0) + 1;
            this.model.intercaladas = [...this.model.intercaladas, { mes: mes }];
            this.validate();
        }

        this.rmvInt = () => {
            this.model.intercaladas.pop();
            this.validate();
        }

        this.remove = () => {
            if(this.onRemove) this.onRemove({model: this.model});
        }

        this.validate = () => {
            this.error = {};
            this.model.total = this.model.pct_sinal + (this.model.numero_parcelas * this.model.pct_parcela) + this.model.intercaladas.reduce((t, i) => t + (i.pct || 0), 0) + this.model.pct_chaves;

            if(this.model.total > 100) {
                this.error.total = 'O total é maior que 100%';
            } else if (this.model.total < 100) {
                this.error.total = 'O total é menor que 100%';
            }

            if(this.model.mes_inicio < 1) this.model.mes_inicio = 1;
        }

        this.handleChange = (field) => {
            this.validate();
            if(this.onChange) this.onChange({field: field});
        }

        this.$onInit = () => {
            
            if(!this.model) {
                this.model = {
                    mes_inicio: 0,
                    pct_sinal: 0,
                    
                    numero_parcelas: 0,
                    pct_parcela: 0,
                    
                    intercaladas: [],
                    pct_intercaladas: 0,

                    pct_chaves: 0,
                }
            }

            this.validate();
        }

    }]
})