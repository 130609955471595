app.factory('Math', [function(){
    var math = Math;

    const TOLERANCE = 0.01;

    math.abs = function(a) {
        if(a < 0) return -a;
        return a;
    }

    math.equals = function(a, b) {
        return math.abs(b - a) <= TOLERANCE;
    }

    math.compare = function(a, b) {
        var diff = a - b;
        if(math.abs(diff) < TOLERANCE) diff = 0;
        return diff;
    }

    return math;
}]);