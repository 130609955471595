app.controller('entrada-dados.empresario.analise.ctrl', ['$scope', 'analise', function($scope, analise){
    $scope.analise = analise;
    $scope.plano = analise.plano_empresario;
    

    const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    $scope.last_added = $scope.plano.fc_det_unidades && $scope.plano.fc_det_unidades.length ? $scope.plano.fc_det_unidades.length : 0;

    $scope.getTipoPlaceHolder = function(index) {
        let tp = "";
        let idx = index;
        while(idx >= ALPHABET.length) {
            tp = ALPHABET[(idx%ALPHABET.length)] + tp;
            idx = Math.floor(idx / ALPHABET.length) -1
        }
        tp = ALPHABET[idx] + tp;

        return `Tipo ${tp}`;
    }

    $scope.addTipo = function() {
        $scope.plano.fc_det_unidades.push({
            tipo : '',
            area : 0.0,
            qtd : 0.0
        });
    }

    $scope.removeTipo = function(tipo) {
        var idx = $scope.plano.fc_det_unidades.indexOf(tipo);
        $scope.plano.fc_det_unidades.splice(idx, 1);
    }
}]);