app.component('propostaCard', {
    template : //html
    ` 
        <div class="card-header {{$ctrl.headerClass}}">
            <h6 class="card-title">
                {{$ctrl.header}}

                <span class="fa-layers fa-fw fa-3x fa-pull-right" ng-show="$ctrl.proposta.best">
                    <i class="fas fa-certificate text-dark" data-fa-transform="grow-3"></i>
                    <i class="fas fa-certificate text-warning"></i>
                    <span class="fa-layers-text text-dark" data-fa-transform="shrink-12.5 rotate-30" style="font-weight:900">Melhor</span>
                </span>
                
            </h6>
            <small ng-show="$ctrl.subtitle" class="text-muted">{{$ctrl.subtitle}}</small>
            
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <label>Sinal</label>
                    <input value="{{$ctrl.proposta.sinal | currency : 'R$ '}}" disabled class="form-control form-control-sm">
                    <table class="w-100">
                        <tr>
                            <th>Subsidio</th>
                            <td class="text-right">{{$ctrl.proposta.subsidio | currency : 'R$ '}}</td>
                        <tr>
                        <tr>
                            <th>FGTS</th>
                            <td class="text-right">{{$ctrl.proposta.fgts | currency : 'R$ '}}</td>
                        <tr>
                    </table>
                </div>
            </div>
            
            <hr ng-if="($ctrl.proposta.intercaladas && $ctrl.proposta.intercaladas.length > 0)"/>
            
            <div class="row" ng-if="($ctrl.proposta.intercaladas && $ctrl.proposta.intercaladas.length > 0)">
                <div class="col-12">
                    Intercaladas
                </div>
                
                <div class="col-6" ng-repeat-start="intercalada in $ctrl.proposta.intercaladas">
                    <label>Mês</label>
                    <input value="{{intercalada.mes | currency : 'R$ '}}" class="form-control form-control-sm"="0" disabled/>
                </div>
                <div class="col-6" ng-repeat-end>
                    <label>Valor</label>
                    <input value="{{intercalada.valor | currency : 'R$ '}}" class="form-control form-control-sm" disabled/>
                </div>
            </div>

            <hr/>

            <div class="row">
                <div class="col-6">
                    <label>Nº de Parcelas</label>
                    <input value="{{$ctrl.proposta.numero_parcelas | currency : 'R$ '}}" disabled class="form-control form-control-sm">
                </div>
                <div class="col-6">
                    <label>Valor da Parcela</label>
                    <input value="{{$ctrl.proposta.valor_parcela | currency : 'R$ '}}" disabled class="form-control form-control-sm">
                </div>
            </div>
            
            <hr/>

            <div class="row">
                <div class="col-12">
                    <label>Chaves</label>
                    <input value="{{$ctrl.proposta.chaves | currency : 'R$ '}}" disabled class="form-control form-control-sm">
                </div>
            </div>
        </div>
        <div class="card-footer">
            <table class="w-100">
                <tr>
                    <th>Valor Total</th>
                    <td class="text-right">{{$ctrl.venda.total($ctrl.proposta) | currency : 'R$ '}}</td>
                </tr>
                <tr ng-if="$ctrl.showVpl">
                    <th>Valor Presente</th>
                    <td class="text-right">{{$ctrl.venda.vpl($ctrl.proposta) | currency : 'R$ '}}</td>
                </tr>
                <tr ng-if="$ctrl.vplMinimo">
                    <th ng-class="{'text-success': $ctrl.satisfatoria, 'text-danger': !$ctrl.satisfatoria}">{{$ctrl.situacao}}</th>
                    <td class="text-right" ng-class="{'text-success': $ctrl.satisfatoria, 'text-danger': !$ctrl.satisfatoria}">
                        <span ng-if="$ctrl.showVpl">{{($ctrl.venda.vpl($ctrl.proposta) - $ctrl.vplMinimo) | currency : 'R$ '}}</span>
                    </td>
                </tr>
            </table>
            <ng-transclude></ng-transclude>
        </div>
    `,
    transclude: true,
    bindings : {
        proposta: '=',
        venda: '=',
        headerClass: '@',
        header: '@',
        subtitle: '@',
        vplMinimo: '<',
        showVpl: '<',
    },
    controller : ['$element', function($element){
        var scope = this;

        scope.$postLink = function(){
            $element.addClass('card');
        }

        scope.$onInit = function(){
            if(scope.showVpl) {
                var vpl = scope.venda.vpl(scope.proposta);
                scope.satisfatoria = vpl >= scope.vplMinimo;
                scope.situacao = scope.satisfatoria ? "Satisfatória" : "Insatisfatória";
            }
        }

    }]
})