app.component('groupList', {
    template: `
    <div class="list-group">
        <a ng-repeat="group in $ctrl.list" ui-sref="app.group.list({grupo: group._id})" class="list-group-item list-group-item-action flex-column align-item-start" >
            <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{group.nome}}</h5>
                <!-- Adicionar informaçoes sobre o grupo -->
            </div>
            <div>
                <small class="text-muted">Criado por {{group._owner.name}}</small>
            </div>
        </a>
    </div>
    `,
    bindings : {
        list: '='
    }
});