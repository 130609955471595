app.filter('relativeDate', ['$filter', function($filter){
    return function(input){
        var date = new Date(input);
        var now = new Date(Date.now());
        var diff = now.getTime() - date.getTime();
        
        if(diff < 60 * 1000) {
            var m = Math.floor(diff/(1000));
            if(m < 2) return "agora há pouco";
            return "há " + m + " segundos";
        }

        if(diff < 60 * 60 * 1000) {
            var m = Math.floor(diff/(60 * 1000));
            if(m == 1) return "há um munito";
            return "há " + m + " minutos";
        }

        if(diff < 5 * 60 * 60 * 1000) {
            var m = Math.floor(diff/(60 * 60 * 1000));
            if(m == 1) return "há uma hora";
            return "há " + m + " horas";
        }

        if(now.getDate() == date.getDate() && now.getMonth() == date.getMonth() && now.getYear() == date.getYear()) {
            return "hoje de " + $filter('date')(input, 'HH:mm:ss');
        }

        if(now.getDate()-1 == date.getDate() && now.getMonth() == date.getMonth() && now.getYear() == date.getYear()) {
            return "ontem"
        }

        if(now.getDate()-2 == date.getDate() && now.getMonth() == date.getMonth() && now.getYear() == date.getYear()) {
            return "anteontem"
        }

        return "em " + $filter('date')(input,'dd/MM/yyyy');
    }
}]);