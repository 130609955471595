app.controller('negociacao.publico.ctrl', ['$scope', 'negociacao', 'NegociacaoPublico', 'Message', '$state', function($scope, negociacao, NegociacaoPublico, Message, $state) {

    $scope.negociacao = negociacao;
    $scope.analise = negociacao.apartamento.tipologia.analise;
    $scope.recurso = $scope.analise.recurso;
    $scope.venda = negociacao.apartamento.tipologia.analise[negociacao.apartamento.tipologia.analise.recurso.nome];
    
    $scope.status = {
        financiamento: {},
        proposta: {}
    }

    $scope.novaPropostaShow = function() {
        $scope.negociacao.proposta = angular.copy($scope.negociacao.apartamento.tipologia.proposta);
        $scope.negociacao.proposta.fgts = $scope.negociacao.comprador.fgts;
        $scope.negociacao.proposta.subsidio = $scope.negociacao.comprador.subsidio;
        $scope.nova_proposta_modo = 'financiamento';
        $("#proposta-modal").modal('show');
    }

    $scope.prosseguirProposta = function() {
        $scope.nova_proposta_modo = 'proposta';
    }

    $scope.send = function() {
        Message.showSpinner();
        NegociacaoPublico.proposta($scope.negociacao, $scope.negociacao.proposta).then((res) => {
            if(res.data.error) {
                Message.alert(res.data.message, res.data.detail);
            }
            $("#proposta-modal").modal('hide');
            $state.reload();
        }).finally(() => {
            Message.hideSpinner();
        });
    }

    $scope.atualizar = function() {
        Message.showSpinner();
        NegociacaoPublico.update($scope.negociacao).then((res) => {
            if(!res.data.error) {
                $scope.negociacao.apartamento = angular.copy(res.data.apartamento);
                $scope.negociacao.comprador = angular.copy(res.data.comprador);
            } else {
                Message.alert(res.data.message, res.data.detail);
            }
            $("#buyer-modal").modal('hide');
        }).finally(() => {
            Message.hideSpinner();
        });
    }

}]);