app.component('tipologiaForm', {
    template: //html 
    `
        <div id="{{$ctrl.id}}" class="modal fade">
            <div class="modal-dialog modal-lg">
                <form class="modal-content">
                    <div class="modal-header">
                        <h5>Cadastro de Tipologia</h5>
                    </div>
                    <div class="modal-body" ng-if="$ctrl.tipologia">
                        <div class="row">
                            <div class="form-group col-12">
                                <label class="font-weight-bold">Nome</label>
                                <input class="form-control form-control-sm" ng-model="$ctrl.tipologia.nome"
                                    >
                            </div>
                            
                        </div>
                        
                        <hr/>

                        <div class="row">
                            
                            <div class="form-group col-12 col-md-6">
                                <label class="font-weight-bold">Valor de Avaliação</label>
                                <input class="form-control form-control-sm" ng-model="$ctrl.tipologia.proposta.valor_avaliacao" number-format="2">
                            </div>
                            <div class="form-group col-12 col-md-6">
                                <label class="font-weight-bold">Desconto</label>
                                <input class="form-control form-control-sm" ng-model="$ctrl.tipologia.proposta.desconto" number-format="2">
                            </div>
                        </div>
                        
                        <hr/>

                        <div class="row">
                            <div class="form-group col-12">
                                <label class="font-weight-bold">Sinal</label>
                                <input class="form-control form-control-sm" ng-model="$ctrl.tipologia.proposta.sinal" number-format="2"
                                    ng-class="{'is-invalid': ($ctrl.venda.sinal_minimo > $ctrl.tipologia.proposta.sinal)}">
                                <div class="invalid-feedback">
                                    O sinal deve ser maior que {{$ctrl.venda.sinal_minimo | currency : 'R$ '}}
                                </div>
                            </div>
                        </div>
                    
                        <hr/>

                        <div class="row">

                            <div class="col-12">
                                <label class="font-weight-bold">Intercaladas</label>
                                <button class="btn btn-sm btn-danger"  ng-click="$ctrl.removeIntercalada();"><i class="fa fa-minus"/></button>
                                <button class="btn btn-sm btn-primary" ng-click="$ctrl.addIntercalada();"><i class="fa fa-plus"/></button>
                            </div>

                            <div class="col-12">
                                <table class="w-100">
                                    <tr>
                                        <th style="width: 130px">Mês</th>
                                        <th>Valor</th>
                                    </tr>
                                    <tr ng-repeat="intercalada in $ctrl.tipologia.proposta.intercaladas">
                                        <td><input class="form-control form-control-sm" ng-model="intercalada.mes" number-format="0"></td>
                                        <td><input class="form-control form-control-sm" ng-model="intercalada.valor" number-format="2"></td>
                                    </tr>
                                </table>
                            </div>

                        </div>
                    
                        <hr/>

                        <div class="row">

                            <div class="form-group col-12 col-md-4">
                                <label class="font-weight-bold">Nº Parcelas</label>
                                <input class="form-control form-control-sm" ng-model="$ctrl.tipologia.proposta.numero_parcelas" number-format="0">
                            </div>
                            <div class="form-group col-12 col-md-8">
                                <label class="font-weight-bold">Valor Parcelas</label>
                                <input class="form-control form-control-sm" ng-model="$ctrl.tipologia.proposta.valor_parcela" number-format="2">
                            </div>
                            <div class="form-group col-12">
                                <label class="font-weight-bold">Chaves</label>
                                <input class="form-control form-control-sm" ng-model="$ctrl.tipologia.proposta.chaves" number-format="2">
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-sm">
                                    <tr>
                                        <th>Total</th>
                                        <td class="text-right">{{$ctrl.venda.total($ctrl.tipologia.proposta) + $ctrl.tipologia.proposta.desconto | currency : 'R$ '}}</td>
                                    </tr>
                                    <tr>
                                        <th>Valor Final com Desconto</th>
                                        <td class="text-right">{{$ctrl.venda.total($ctrl.tipologia.proposta) | currency : 'R$ '}}</td>
                                    </tr>
                                    <tr>
                                        <th>VPL</th>
                                        <td class="text-right">{{$ctrl.venda.vpl($ctrl.tipologia.proposta) | currency : 'R$ '}}</td>
                                    </tr>
                                    <tr ng-if="($ctrl.venda.total($ctrl.tipologia.proposta) + $ctrl.tipologia.proposta.desconto) > $ctrl.tipologia.proposta.valor_avaliacao" class="text-warning">
                                        <th colspan="2">O valor total excede o valor de avaliação do imóvel</th>
                                    </tr>
                                    <tr ng-if="$ctrl.venda.minimo_durante_obras($ctrl.venda.total($ctrl.tipologia.proposta) + $ctrl.tipologia.proposta.desconto) > $ctrl.venda.pago_durante_obra($ctrl.tipologia.proposta)" class="text-danger">
                                        <th colspan="2">Esse proposta não cobre {{$ctrl.venda.pct_minimo_pagamento_durente_obra | number : 0}}% do valor da obra até o Habite-se</th>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-secondary" ng-click="$ctrl.cancel();">
                            Cancelar
                        </button>
                        <button class="btn btn-primary" ng-click="$ctrl.save();">
                            <i class="fa fa-save"></i> Salvar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    `,
    bindings: {
        id: '<',
        analise: '=',
        onSave: '&',
        onError: '&',
        onCancel: '&',
        passApi: '&'
    },
    controller: ['Tipologia', 'Message', function(Tipologia, Message){
        var scope = this;
        
        scope.addIntercalada = function(){
            if(scope.tipologia) {
                if(!scope.tipologia.proposta.intercaladas)
                    scope.tipologia.proposta.intercaladas = [];

                scope.tipologia.proposta.intercaladas.push(Tipologia.newIntercalada());
            }
        }

        scope.removeIntercalada = function(){
            if(scope.tipologia) {
                if(scope.tipologia.proposta.intercaladas) {
                    scope.tipologia.proposta.intercaladas.pop();
                }
            }
        }

        scope.save = function() {
            Message.showSpinner();
            if(scope.is_update) {
                Tipologia.update((scope.analise.group._id || scope.analise.group), scope.analise._id, scope.tipologia)
                .then((res) => {
                    scope.jq().modal('hide');
                    if(scope.onSave) scope.onSave({result: res.data});
                }, (err) => {
                    if(scope.onError) scope.onError({err: err});
                }).finally(() => {
                    Message.hideSpinner();
                });
            } else {
                Tipologia.create((scope.analise.group._id || scope.analise.group), scope.analise._id, scope.tipologia)
                .then((res) => {
                    scope.jq().modal('hide');
                    if(scope.onSave) scope.onSave({result: res.data});
                }, (err) => {
                    if(scope.onError) scope.onError({err: err});
                }).finally(() => {
                    Message.hideSpinner();
                });
            }
        }

        scope.cancel = function(){
            if(scope.onCancel) scope.onCancel();
            scope.jq().modal('hide');
        }

        scope.jq = function(){
            return $('#' + scope.id);
        }

        scope.$onInit = function(){
            if(!scope.id) scope.id = "tipologia-modal-" + parseInt(Math.random() * 10000);
            scope.venda = scope.analise[scope.analise.recurso.nome];

            scope.passApi({
                api: {
                    new: function(analise){
                        scope.is_update = false;
                        scope.tipologia = Tipologia.new(analise);
                        scope.jq().modal('show');
                    },
                    edit: function(tipologia){
                        scope.is_update = true;
                        scope.tipologia = angular.copy(tipologia);
                        scope.jq().modal('show');
                    },
                    hide: function(){
                        scope.jq().modal('hide');
                    }
                }
            })
        }
    }]
})