app.component('tipologiaCrud', {
    template: //html
    `   
        <div class="row justify-content-center">
            <div class="table-responsive col-12">
                <table class="table table-sm table-bordered table-striped">
                    <thead>
                        <tr>   
                            <th>Nome</th>
                            <th>Valor Avaliado</th>
                            <th>Desconto</th>
                            <th>Sinal</th>
                            <th>Pro Soluto</th>
                            <th>Chaves</th>
                            <th>Total</th>
                            <th class="text-center" colspan="3">
                                <button class="btn btn-sm btn-primary" ng-click="$ctrl.api.tipologia_form.new()"><i class="fa fa-plus"></i> Adicionar</button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr ng-show="(!$ctrl.list || $ctrl.list.length == 0)">
                            <td colspan="8">
                                Nenhuma tipologia registrada, clique em "<i class="fa fa-plus"></i> Adicionar" par incluir uma nova.
                            </td>
                        </tr>
                        <tr ng-repeat="t in $ctrl.list">
                            <td>{{t.nome}}</td>
                            <td class="text-right">{{t.proposta.valor_avaliacao | currency : 'R$ '}}</td>
                            <td class="text-right">{{t.proposta.desconto | currency : 'R$ '}}</td>
                            <td class="text-right">{{t.proposta.sinal | currency : 'R$ '}}</td>
                            <td class="text-right">{{t.pro_soluto | currency : 'R$ '}}</td>
                            <td class="text-right">{{t.proposta.chaves | currency : 'R$ '}}</td>
                            <td class="text-right">{{t.total | currency : 'R$ '}}</td>
                            <td class="text-center">
                                <a href="javascript:void(0)" ng-click="$ctrl.api.unidade_form.show(t)" title="Unidades" bs-tooltip>
                                    <i class="fa fa-building"></i>
                                </a>
                            </td>
                            <td class="text-center">
                                <a href="javascript:void(0)" ng-click="$ctrl.api.tipologia_form.edit(t)" title="Alterar" bs-tooltip>
                                    <i class="fa fa-edit"></i>
                                </a>
                            </td>
                            <td class="text-center">
                                <a href="javascript:void(0)" class="text-danger" ng-click="$ctrl.delete(t)" title="Remover" bs-tooltip>
                                    <i class="fa fa-trash"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <nav>
                <ul class="pagination">
                    <li class="page-item" ng-class="{'disabled': ($ctrl.loading || $ctrl.current_page == 1)}">
                        <a class="page-link" href="javascript:void(0)" ng-click="$ctrl.first()"><i class="fa fa-angle-double-left"></i></a>
                    </li>

                    <li class="page-item" ng-class="{'disabled': ($ctrl.loading || $ctrl.current_page == 1)}">
                        <a class="page-link" href="javascript:void(0)" ng-click="$ctrl.previous()"><i class="fa fa-angle-left"></i></a>
                    </li>

                    <li class="page-item disabled">
                        <a class="page-link" href="javascript:void(0)">
                            <span ng-show="$ctrl.loading"><i class="fa fa-cog fa-spin"></i> Carregando</span>
                            <span ng-hide="$ctrl.loading">Página {{$ctrl.current_page}} de {{$ctrl.page_count}}</span>
                        </a>
                    </li>
                    
                    <li class="page-item" ng-class="{'disabled': ($ctrl.loading || $ctrl.current_page == $ctrl.page_count)}">
                        <a class="page-link" href="javascript:void(0)" ng-click="$ctrl.next()"><i class="fa fa-angle-right"></i></a>
                    </li>

                    <li class="page-item" ng-class="{'disabled': ($ctrl.loading || $ctrl.current_page == $ctrl.page_count)}">
                        <a class="page-link" href="javascript:void(0)" ng-click="$ctrl.last()"><i class="fa fa-angle-double-right"></i></a>
                    </li>
                </ul>
            </nav>

            <confirm-modal modal-id="confirm-delete" header="Confirmação" message="Tem certeza que deseja excluir esse registro?" on-confirm="$ctrl.confirmDelete()"></confirm-modal>

            <tipologia-form analise="$ctrl.analise" pass-api="$ctrl.receive_api('tipologia_form', api)" on-save="$ctrl.refresh()"></tipologia-form>
            <unidade-form analise="$ctrl.analise" pass-api="$ctrl.receive_api('unidade_form', api)"></tipologia-form>
        </div>
    `,
    bindings : {
        analise: '='
    },
    controller: ['Tipologia', function(Tipologia){
        var $scope = this;

        $scope.api = {};
        $scope.receive_api = function(name, api){
            $scope.api[name] = api;
        }

        $scope.refresh = function(){
            $scope.loading = true;
            Tipologia.page($scope.group_id, $scope.analise_id, $scope.current_page).then(res => {
                $scope.current_page = res.data.current_page;
                $scope.page_count = res.data.page_count;
                $scope.list = Tipologia.process(res.data.list);
            }, err => {
                console.log(err);
            }).finally(function(){
                $scope.loading = false;
            });
        }


        $scope.first = function(){
            $scope.current_page = 1;
            $scope.refresh();
        }

        $scope.next = function(){
            $scope.current_page++;
            $scope.refresh();
        }

        $scope.previous = function(){
            $scope.current_page--;
            $scope.refresh();
        }

        $scope.last = function(){
            $scope.current_page = $scope.page_count;
            $scope.refresh();
        }

        $scope.delete = function(t){
            $scope.selecetd = t;
            $('#confirm-delete').modal('show');
        }

        $scope.confirmDelete = function(){
            console.log('deleted');
            Tipologia.delete($scope.group_id, $scope.analise_id, $scope.selecetd).then(res => {
                $scope.refresh();
            });
        }

        $scope.$onInit = function(){
            $scope.list = [];
            $scope.current_page = 1;
            $scope.page_count = 0;

            $scope.group_id = ($scope.analise.group._id || $scope.analise.group);
            $scope.analise_id = $scope.analise._id;

            $scope.refresh();
        }
    }]
});