app.factory('Message', function(){
    var id = 0;
    var spinnerCount = 0;

    var factory = {
        _container : null,
        _defaultLifespan : 5000,
        _loadCount: 0,
        _loadingModal: $('#loading-modal'),

        _init : function(){
            if(this._container == null){
                let body = $('body');
                let container = $('<div>');
                container.addClass('message-container');
                body.append(container);
                this._container = container;
            }
        },

        clear : function(){
            this._init();
            let msgs = this._container.children();
            for(let i = 0; i < msgs.length; i++){
                $(msgs[i]).addClass('message-hidden');
                setTimeout(function(){
                    $(msgs[i]).remove();
                }, 400);
            }
        },

        showMessage : function(message, severity, icon, lifespan){ 
            this._init();
            if(!severity) severity = 'info';
            if(lifespan === undefined) lifespan = this._defaultLifespan;

            let m = $('<div>');
            m.addClass('alert alert-'+severity+' message message-hidden message-'+(++id));

            if(icon) {
                let i = $('<i>');
                i.addClass('fa fa-'+icon);
                m.append(i);
                m.append(' ');
            }

            m.append(message);
            m.removeClass('message-hidden');
            this._container.append(m);
            
            var $m = '.message-container .message-'+id;
            var hideMessage = function(){
                $($m).addClass('message-hidden');
                setTimeout(function(){
                    $($m).remove()
                }, 800);
            }

            if(lifespan){
                setTimeout(hideMessage, lifespan);
            }

            return hideMessage;
        },

        showLoading : function(message){
            if(!message) message = 'Carregando...';
            return this.showMessage(message, 'info', 'cog fa-spin', false);
        },

        showInfo : function(message, icon, lifespan){
            return this.showMessage(message, 'info', (icon || 'bell'), lifespan);
        },

        showSuccess : function(message, icon, lifespan){
            return this.showMessage(message, 'success', (icon || 'check'), lifespan);
        },
        
        showWarning : function(message, icon, lifespan){
            return this.showMessage(message, 'warning', (icon || 'exclamation'), lifespan);
        },
        
        showErro : function(message, icon, lifespan){
            return this.showMessage(message, 'danger', (icon || 'close'), lifespan);
        },

        showSpinner: function(){
            if(++spinnerCount > 0) 
                $('#spinner').fadeIn();
        },

        hideSpinner: function(){
            if(--spinnerCount <= 0) 
                $('#spinner').fadeOut();
        },

        loadBlock : function(text){
            // if(!text) text = 'Carregando...';
            
            // $('.modal-text', this._loadingModal).text(text);
            
            // if(this._loadCount == 0) {
            //     this._loadingModal.modal({
            //         keyboard: false,
            //         //backdrop: 'static'
            //     });
            // }

            // this._loadCount++;
            // var factory = this;
            return {
                close: function(){
                    // factory._loadCount--;

                    // if(factory._loadCount <= 0) {
                    //     factory._loadCount = 0;
                    //     factory._loadingModal.modal('hide');
                    // }
                }
            }
        },

        alert: function(title, message, cb){
            var comp = $('#message-modal');

            $('.modal-title', comp).text(title);

            if(typeof message == "string") {
                $('.modal-body', comp).text(message);
            } else {
                $('.modal-body', comp).empty();
                for(var i in message) {
                    var e = $("<div></div>");
                    e.text(message[i]);
                    $('.modal-body', comp).append(e);
                }
            }

            if(cb) {
                comp.unbind('hidden.bs.modal');
                comp.on('hidden.bs.modal', cb);
            }

            comp.modal('show');
        }

    };

    factory.success = factory.showSuccess;
    factory.error = factory.showErro;
    factory.info = factory.showInfo;
    factory.warn = factory.showWarning;

    return factory;

})