app.controller('investidor.direto.analise.ctrl', ['$scope', 'analise', function($scope, analise){
    $scope.analise = analise;
    $scope.plano = analise.plano_direto;
    
    if(!$scope.plano.investidor) $scope.plano.investidor = [];
    
    var Investimento = function(){
        this.mes = 0;
        this.valor = 0;
    }

    $scope.add = function(){
        $scope.plano.investidor.push(new Investimento());
    }

    $scope.remove = function(investimento) {
        var idx = $scope.plano.investidor.indexOf(investimento);
        $scope.plano.investidor.splice(idx, 1);
    }

    
}]);