app.component('financiamentoForm', {
    template: //html 
    `
    <div class="col-12">
        <h6>
            Selecione a forma de Financiamento
        </h6>
    </div>
    <div class="col-12 justify-content-start">
        <div>
            <div class="form-check form-check-inline form-check-label">
                <input id="fin-radio-sac" name="fin-radio" class="form-check-input" 
                    type="radio" ng-model="$ctrl.negociacao.proposta.tipo_financiamento" value="sac" autocomplete="off" ng-change="$ctrl.tipoChange()">
                <label style="font-size: 1.4em;" for="fin-radio-sac">SAC</label>
            </div>
            <div class="form-check form-check-inline form-check-label">
                <input id="fin-radio-price" name="fin-radio" class="form-check-input" 
                    type="radio" ng-model="$ctrl.negociacao.proposta.tipo_financiamento" value="price" autocomplete="off" ng-change="$ctrl.tipoChange()">
                <label style="font-size: 1.4em;" for="fin-radio-price">PRICE</label>
            </div>
            <div class="form-check form-check-inline form-check-label">
                <input id="fin-radio-none" name="fin-radio" class="form-check-input" 
                    type="radio" ng-model="$ctrl.negociacao.proposta.tipo_financiamento" value="sem_financiamento" autocomplete="off" ng-change="$ctrl.tipoChange()">
                <label style="font-size: 1.4em;" for="fin-radio-none">Sem Financiamento</label>
            </div>
        </div>

        <div ng-show="$ctrl.negociacao.proposta.tipo_financiamento && $ctrl.negociacao.proposta.tipo_financiamento != 'sem_financiamento'" class="col-12">
            <div class="row">

                <div class="col-12 mb-2">
                    <div class="form-group">
                        <label>Qual o prazo do financiamento? (Mês)</label>
                        <input class="form-control form-control-sm" ng-model="$ctrl.negociacao.proposta.prazo_financiamento" 
                            number-format="0" ng-change="$ctrl.calcularParcelas()"
                            ng-class="{'is-invalid': $ctrl.error.prazo}">
                        <div class="invalid-feedback" ng-show="$ctrl.error.prazo">
                            {{$ctrl.error.prazo}}
                        </div>
                    </div>
                </div>

                <div class="col-12 mb-2">
                    <div class="form-group">
                        <label>Valor Financiado</label>
                        <input class="form-control form-control-sm" ng-model="$ctrl.negociacao.proposta.chaves" 
                            number-format ng-change="$ctrl.calcularParcelas()"
                            ng-class="{'is-invalid do-shake': $ctrl.error.valor}">
                        <div class="invalid-feedback" ng-show="$ctrl.error.valor">
                            {{$ctrl.error.valor}}
                        </div>
                    </div>
                </div>

                <div class="col-12 mb-2 d-flex justify-content-between">
                    <span>Juros ao ano</span>
                    <span>{{$ctrl.juros() | number : 3}} %</span>
                </div>

                <div class="col-12 mb-2">
                    <table class="table table-sm table-striped">
                        <tbody>
                            <tr>
                                <th>1º Prestação</th>
                                <td class="text-right">{{$ctrl.parcelas[0].total | currency : 'R$ '}}</td>
                            </tr>
                                <tr>
                                    <td class="text-right"><small>Amortização</small></td>
                                    <td class="text-right"><small>{{$ctrl.parcelas[0].parcela | currency : 'R$ '}}</small></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><small>Juros</small></td>
                                    <td class="text-right"><small>{{$ctrl.parcelas[0].juros | currency : 'R$ '}}</small></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><small>Seguro MIP</small></td>
                                    <td class="text-right"><small>{{$ctrl.parcelas[0].mip | currency : 'R$ '}}</small></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><small>Seguro DFI</small></td>
                                    <td class="text-right"><small>{{$ctrl.parcelas[0].dfi | currency : 'R$ '}}</small></td>
                                </tr>
                                <tr>
                                    <td class="text-right"><small>Tx. Administração</small></td>
                                    <td class="text-right"><small>{{$ctrl.parcelas[0].adm | currency : 'R$ '}}</small></td>
                                </tr>
                            <tr>
                                <th>Ultima Prestação</th>
                                <td class="text-right">{{$ctrl.parcelas[$ctrl.parcelas.length-1].total | currency : 'R$ '}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    `,
    bindings: {
        analise: '=',
        venda: '=',
        propostaOriginal: '=',
        
        negociacao: '=',
        
        params: '=',
        status: '=',

        onChange: '&',
        passApi: '&'
    },
    controller: ['DateUtils', '$filter', 'Publico', function(DateUtils, $filter, Publico){
        var scope = this;
        scope.error = {};

        scope.maximo_possivel = 0;
        scope.maximo_permitido = 0;
        scope.params_financiamento = null;

        scope.tipoChange = function() {
            if(scope.negociacao.proposta.tipo_financiamento == 'sem_financiamento') {
                scope.negociacao.proposta.chaves = 0;
                scope.negociacao.proposta.prazo_financiamento = 0;
            } else if(scope.negociacao.proposta.tipo_financiamento) {
                scope.params_financiamento = scope.params.financiamento[scope.negociacao.proposta.tipo_financiamento];
                scope.negociacao.proposta.prazo_financiamento = Math.min(scope.negociacao.comprador.prazo_financiamento, scope.params_financiamento.prazo_max);

                //Calcular o valor máximo permitido para financiar
                scope.maximo_permitido = scope.propostaOriginal.valor_avaliacao * scope.params_financiamento.pct_max;
                scope.negociacao.proposta.chaves = Math.min(scope.maximo_possivel(), scope.maximo_permitido);
                
                scope.calcularParcelas();
            }
        }

        scope.juros = function(){
            var tx = 0;
            switch(scope.analise.recurso.nome) {
                case 'mcmv_faixa_15':
                    tx = 5;
                    if(scope.negociacao.comprador.cotista_fgts) tx -= 0.5;
                    break;
                case 'mcmv_faixa_2':
                    //Em funcao da redan
                    if(scope.negociacao.comprador.renda_familiar <= 2600){
                        tx = 5.5;
                    } else if(scope.negociacao.comprador.renda_familiar <= 3000){
                        tx = 6;
                    } else if(scope.negociacao.comprador.renda_familiar <= 4000) {
                        tx = 7;
                    } else if(scope.negociacao.comprador.renda_familiar <= 7000) {
                        tx = 8.16;
                    }
                    if(scope.negociacao.comprador.cotista_fgts) tx -= 0.5;
                    break;
                case 'sbpe':
                default:
                    tx = 9.3396;
                    if(scope.negociacao.comprador.cotista_fgts) tx -= 1.52;
                    break;
            }
            return tx;
        }

        scope.maximo_possivel = function(){
            var renda_disponivel = (scope.negociacao.comprador.renda_familiar * scope.params_financiamento.pct_renda);
            var pct_juros = (scope.juros() / 1200);
            var prazo_financiamento = Math.min(scope.params_financiamento.prazo_max, scope.negociacao.comprador.prazo_financiamento);

            if(!scope.negociacao.proposta.tipo_financiamento || scope.negociacao.proposta.tipo_financiamento == 'sem_financiamento') {
                return 0;
            } else if(scope.negociacao.proposta.tipo_financiamento == 'sac') {
                return renda_disponivel / ((1/prazo_financiamento) + pct_juros);
            } else if(scope.negociacao.proposta.tipo_financiamento == 'price') {
                return (renda_disponivel * ( 1 - Math.pow((1 + pct_juros), -prazo_financiamento)) ) / pct_juros;
            }
        }

        scope.calcularParcelas = function(){
            if(!scope.negociacao.proposta.tipo_financiamento || scope.negociacao.proposta.tipo_financiamento == 'sem_financiamento') return;
            
            var pct_juros = (scope.juros() / 1200); //% do Juros
            var armotizacao = scope.negociacao.proposta.chaves; //Valor armotizado
            // var total = scope.venda.total(scope.propostaOriginal); //Total do Imovel
            
            //Calcular prazo de financiamento possivel
            var prazo_financiamento = Math.min(scope.params_financiamento.prazo_max, scope.negociacao.comprador.prazo_financiamento);
            var fin = (p) => p; //Função de calculo do valor possivel para financiamento

            //Determinar idade do Comprador
            var data_nascimento = DateUtils.fromString(scope.negociacao.comprador.data_nascimento);
            var idade = DateUtils.yearsBetween(data_nascimento, new Date());

            //Determinar faixa do MIP
            var faixa_mip = scope.params.tabela_mip[0];
            for(var i = 0; i < scope.params.tabela_mip.length; i++) {
                if(scope.params.tabela_mip[i].min > idade) break;
                faixa_mip = scope.params.tabela_mip[i];
            }

            var parcelas = []; //Parcelas do financiamento
            var index_mip = 0; //Posição na tabela mip

            console.log(armotizacao, prazo_financiamento);

            if(scope.negociacao.proposta.tipo_financiamento == 'price') { //FINANCIAMENTO TIPO PRICE
                //Valor da parcela é fixa
                var parcela = (armotizacao * pct_juros) / (1 - Math.pow(pct_juros + 1, -prazo_financiamento));

                //Definir parcelas do financiamento no modo PRICE
                for(var i = 1; i <= prazo_financiamento; i++) {
                    var juros = pct_juros * armotizacao; 

                    parcelas.push({
                        index: i,
                        parcela: parcela - juros,
                        juros: juros,
                        mip: armotizacao * faixa_mip.val[index_mip],
                        dfi: scope.propostaOriginal.valor_avaliacao * scope.params.indice_dfi,
                        adm: scope.params.tx_adm,
                        get total() {
                            return this.parcela + this.juros + this.mip + this.dfi + this.adm;
                        }
                    });

                    armotizacao -= (parcela - juros);
                    if(i % 60 == 0) index_mip++;
                }

                //Remover MIP e DFI das ultimas parcelas
                parcelas[prazo_financiamento-1].mip = 0;
                parcelas[prazo_financiamento-1].dfi = 0;
            } else if(scope.negociacao.proposta.tipo_financiamento == 'sac') {//FINANCIAMENTO TIPO SAC
                //Valor da armotização é fixo
                var parcela = (armotizacao / prazo_financiamento);
                
                //Definir parcelas do financiamento no modo PRICE
                for(var i = 1; i <= prazo_financiamento; i++) {
                    var juros = pct_juros * armotizacao;
        
                    parcelas.push({
                        index: i,
                        parcela: parcela,
                        juros: juros,
                        mip: armotizacao * faixa_mip.val[index_mip],
                        dfi: scope.propostaOriginal.valor_avaliacao * scope.params.indice_dfi,
                        adm: scope.params.tx_adm,
                        get total() {
                            return this.parcela + this.juros + this.mip + this.dfi + this.adm;
                        }
                    });
        
                    armotizacao -= parcela;
                    if(i % 60 == 0) index_mip++;
                }
        
                //Remover MIP e DFI das ultimas parcelas

                parcelas[prazo_financiamento-1].mip = 0;
                parcelas[prazo_financiamento-1].dfi = 0;
            }

            scope.status.primeira_parcela = parcelas[0].total;
            scope.status.ultima_parcela = parcelas[parcelas.length-1].total;
            scope.parcelas = parcelas;
            scope.validarFinanciamento();
        }

        scope.validarFinanciamento = function() {
            var error = false;
            scope.error = {};
    
            if(!scope.negociacao.proposta.tipo_financiamento) {
                error = true;
            } else {
                var prazo_max = Math.min(scope.negociacao.comprador.prazo_financiamento, scope.params_financiamento.prazo_max);
                if(prazo_max < scope.negociacao.prazo_financiamento) {
                    scope.error.prazo = 'No seu caso, o prazo máximo para financiamento é de ' + prazo_max + ' mêses';
                    error = true;
                }
        
                var valor_max = Math.min(scope.maximo_permitido, scope.maximo_possivel());
                if(valor_max < scope.negociacao.proposta.chaves) {
                    scope.error.valor = 'Você pode financiar até ' + $filter('currency')(valor_max, 'R$ ');
                    error = true;
                }
            }
            
            scope.status.error = error;
            if(scope.onChange) scope.onChange();
            return error;
        }

        scope.$onInit = function(){
            scope.status = {};
            
            if(scope.passApi) {
                scope.passApi({api: {
                    reset: function(){
                        scope.negociacao.proposta.tipo_financiamento = null;
                        scope.tipoChange();
                    }
                }});
            }

            scope.validarFinanciamento();
        }
    }]
})