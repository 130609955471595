app.controller('contratar.controller', ['$scope', 'Session', 'group', 'recursos', 'planos', 
    'PagSeguro', 'Message', '$state', 
    function($scope, Session, group, recursos, planos, PagSeguro, Message, $state){
    $scope.planos = planos;
    $scope.recursos = recursos;
    $scope.grupo = group;
    $scope.cobranca = {
        group: group,
        plano: planos[0],
        recursos : []
    }
    
    $scope.loading = false;

    $scope.select = function(p) {
        $scope.cobranca.plano = p;
    } 

    $scope.toggle = function(r) {
        var idx = $scope.cobranca.recursos.indexOf(r);
        if(idx >= 0) {
            $scope.cobranca.recursos.splice(idx, 1);
        } else {
            $scope.cobranca.recursos.push(r);
        }
    }

    $scope.isSelected = function(r) {
        return $scope.cobranca.recursos.indexOf(r) >= 0;
    }

    $scope.desconto = function(){
        if($scope.cobranca.recursos.length >= 3) {
            return $scope.total() * 0.3;
        }
        return 0.0;
    }

    $scope.total = function() {
        var total = 0.0;
        for(var i in $scope.cobranca.recursos) {
            total += $scope.cobranca.recursos[i][$scope.cobranca.plano.atributo] * $scope.cobranca.plano.duracao;
        }
        return total;
    }


    var pagSeguro = function(code){
        //Tentar abrir a lightbox
        var openLightbox = PagSeguroLightbox({
            code: code
        }, {
            success : function(transactionCode) {
                $state.go('app.group.list', {grupo: group._id});
            },
            abort : function() {
                
            }
        });

        //Se falhar, redirecionar
        if(!openLightbox){
            location.href = "https://pagseguro.uol.com.br/v2/checkout/payment.html?code=" + code;
        }
    }

    $scope.checkout = function(){
        $scope.loading = true;
        PagSeguro.checkout($scope.cobranca).then(function(res){
            if(res.data.error) {
                return Message.alert(res.data.message, res.data.detail);
            }
            
            pagSeguro(res.data.codigo);
        }, function(err){
            Message.alert('Erro', err.data.message)
        }).finally(function(){
            $scope.loading = false;
        });
    }

    $scope.voucherDone = function(){
        $state.go('app.group.list', {grupo: group._id});
    }

    $scope.grupo = group;
}]);