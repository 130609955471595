app.config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider){

    $stateProvider
        .state('login', {
            url: '/login',
            templateUrl: '/views/login',
            controller: 'login.controller'
        })
        .state('app', {
            url: '/app',
            templateUrl: '/views/app',
            controller: 'app.controller',
            abstract: true
        })
        .state('app.termos', {
            url: '/termos-de-uso',
            templateUrl: '/views/termos'
        })
        .state('app.home', {
            url: '/home',
            templateUrl: '/views/home',
            controller: 'home.controller',
            resolve : {
                groups: ['Group', '$q', function(Group, $q){
                    var defered = $q.defer();
                    Group.list().then(function(res){
                        defered.resolve(res.data);
                    }, function(err){
                        defered.reject(err);
                    });
                    return defered.promise;
                }]
            }
        })
        .state('app.create_group', {
            url: '/criar-grupo',
            templateUrl: '/views/criar-grupo',
            controller: 'criar-grupo.controller',
        })
        .state('app.contratar', {
            url: '/contratar/:grupo',
            templateUrl: '/views/contratar',
            controller: 'contratar.controller',
            resolve: {
                userGroup: ['$q', 'Group', '$stateParams', function($q, Group, $stateParams) {
                    var defered = $q.defer();
                    Group.read($stateParams.grupo).then(function(res){
                        defered.resolve(res.data);
                    }, function(err){
                        defered.reject(err);
                    });
                    return defered.promise;
                }],
                group: ['userGroup', function(userGroup){
                    return userGroup.group;
                }],
                planos : ['Plano', '$q', function(Plano, $q) {
                    var defered = $q.defer();
                    Plano.list().then(function(res){
                        defered.resolve(res.data);
                    });
                    return defered.promise;
                }],

                recursos: ['Recurso', '$q', function(Recurso, $q){
                    var defered = $q.defer();
                    Recurso.list({disponivel: true}).then(function(res){
                        defered.resolve(res.data);
                    });
                    return defered.promise;
                }]
            }
        })
        .state('app.profile', {
            url: '/profile',
            templateUrl: '/views/profile',
            controller: 'profile.controller',
            resolve: {
                estados: ['$q', 'Estado', function($q, Estado){
                    var defered = $q.defer();
                    Estado.list().then(function(res){
                        defered.resolve(res.data);
                    }, function(err){
                        defered.reject(err);
                    });
                    return defered.promise;
                }]
            }
        })
        .state('app.group', {
            url: '/grupo/:grupo',
            template: '<ui-view></ui-view>',
            abstract: true,
            resolve: {
                userGroup: ['$q', 'Group', '$stateParams', function($q, Group, $stateParams) {
                    var defered = $q.defer();
                    Group.read($stateParams.grupo).then(function(res){
                        defered.resolve(res.data);
                    }, function(err){
                        defered.reject(err);
                    });
                    return defered.promise;
                }],
                group: ['userGroup', function(userGroup){
                    return userGroup.group;
                }],
                recursos: ['$q', 'Inscricao', '$stateParams', function($q, Inscricao, $stateParams) {
                    var defered = $q.defer();
                    Inscricao.recursos($stateParams.grupo).then(function(response) {
                        defered.resolve(response.data);
                    }, function(err)  {
                        defered.reject(err);
                    });
                    return defered.promise;
                }]
            }
        })
        .state('app.group.edit', {
            url: '/alterar',
            templateUrl: '/views/alterar-grupo',
            controller: 'edit-group.ctrl',
            resolve: {
                estados: ['$q', 'Estado', function($q, Estado) {
                    var defered = $q.defer();
                    Estado.list().then(function(res){
                        defered.resolve(res.data);
                    });
                    return defered.promise;
                }]
            }
        })
        .state('app.group.list', {
            url: '/analises',
            templateUrl: '/views/lista-analises',
            controller: 'analises.ctrl',
            resolve: {
                analises: ['$q', 'Analise', 'group', function($q, Analise, group) {
                    var defered = $q.defer();
                    Analise.list(group._id).then(function(response) {
                        defered.resolve(response.data);
                    }, function(err)  {
                        defered.reject(err);
                    });
                    return defered.promise;
                }]
            }
        })
        .state('app.group.analise', {
            url: '/analise/:analise',
            templateUrl: 'views/analise',
            abstract: true,
            resolve: {
                estados: ['$q', 'Estado', function($q, Estado) {
                    var defered = $q.defer();
                    Estado.list().then(function(res){
                        defered.resolve(res.data);
                    });
                    return defered.promise;
                }],
                analise: ['$q', '$stateParams', 'group', 'Analise', 'Formulas', function($q, $stateParams, group, Analise, Formulas) {
                    var defered = $q.defer();
                    Analise.read(group._id, $stateParams.analise).then(function(response) {
                        if(!response.error)

                        defered.resolve(Formulas.set(response.data));
                    }, function(err){
                        defered.reject(err);
                    });

                    return defered.promise;
                }],
                situacao: ['recursos', 'analise', function(recursos, analise) {
                    for(var i = 0; i < recursos.length; i++) {
                        if(recursos[i].nome == analise.recurso.nome) {
                            return {
                                available: true,
                                recurso: recursos[i]
                            }
                        }
                    }
                    return {
                        error: true,
                        message: "Congelado",
                        detail: "Sua inscrição em " + analise.recurso.descricao + " está vencida."
                    };
                }]
            },
            controller: 'analise.controller'
        })    
        //-----------------------------------------------------
        //Novo fomrato
        //-----------------------------------------------------
        // Seguir este novo formato para definir estados.
            .state('app.group.analise.plano-empresario', { 
                url: '/plano-empresario', //URL do estado (Analise)
                templateUrl: '/views/plano-empresario/nav', //URL da view de navegação
                abstract: true,
            })
                .state('app.group.analise.plano-empresario.informacoes-negocio', {
                    url: '/informacoes-negocio',
                    templateUrl: 'views/plano-empresario/informacoes-negocio',
                    controller: 'informacoes-negocio.empresario.analise.ctrl' 
                })
                .state('app.group.analise.plano-empresario.entrada-dados', {
                    url: '/entrada-dados',
                    templateUrl: 'views/plano-empresario/entrada-dados',
                    controller: 'entrada-dados.empresario.analise.ctrl' 
                })
                .state('app.group.analise.plano-empresario.tabela-venda', {
                    url: '/tabela-venda',
                    templateUrl: 'views/plano-empresario/tabela-venda',
                    controller: 'tabela-venda.empresario.analise.ctrl' 
                })
                .state('app.group.analise.plano-empresario.fluxo-total', {
                    url: '/fluxo-total',
                    templateUrl: 'views/plano-empresario/fluxo-total',
                    controller: 'fluxo-total.empresario.analise.ctrl' 
                })
                .state('app.group.analise.plano-empresario.dashboard', {
                    url: '/dashboard',
                    templateUrl: 'views/plano-empresario/dashboard',
                    controller: 'dashboard.empresario.analise.ctrl' 
                })
            .state('app.group.analise.plano-direto', { 
                url: '/plano-direto', //URL do estado (Analise)
                templateUrl: '/views/plano-direto/nav', //URL da view de navegação
                abstract: true,
            })
                .state('app.group.analise.plano-direto.informacoes-negocio', {
                    url: '/informacoes-negocio',
                    templateUrl: 'views/plano-direto/informacoes-negocio',
                    controller: 'informacoes-negocio.direto.analise.ctrl' 
                })
                .state('app.group.analise.plano-direto.entrada-dados', {
                    url: '/entrada-dados',
                    templateUrl: 'views/plano-direto/entrada-dados',
                    controller: 'entrada-dados.direto.analise.ctrl' 
                })
                .state('app.group.analise.plano-direto.tabela-venda', {
                    url: '/tabela-venda',
                    templateUrl: 'views/plano-direto/tabela-venda',
                    controller: 'tabela-venda.direto.analise.ctrl' 
                })
                .state('app.group.analise.plano-direto.investidor', {
                    url: '/investidor',
                    templateUrl: 'views/plano-direto/investidor',
                    controller: 'investidor.direto.analise.ctrl' 
                })
                .state('app.group.analise.plano-direto.fluxo-total', {
                    url: '/fluxo-total',
                    templateUrl: 'views/plano-direto/fluxo-total',
                    controller: 'fluxo-total.direto.analise.ctrl' 
                })
                .state('app.group.analise.plano-direto.dashboard', {
                    url: '/dashboard',
                    templateUrl: 'views/plano-direto/dashboard',
                    controller: 'dashboard.direto.analise.ctrl' 
                })
            .state('app.group.analise.plano-associativo', { 
                url: '/plano-associativo', //URL do estado (Analise)
                templateUrl: '/views/plano-associativo/nav', //URL da view de navegação
                abstract: true
            })
                .state('app.group.analise.plano-associativo.informacoes-negocio', {
                    url: '/informacoes-negocio',
                    templateUrl: 'views/plano-associativo/informacoes',
                    controller: 'informacoes.associativo.analise.ctrl' 
                })
                .state('app.group.analise.plano-associativo.dados-empreendimento', {
                    url: '/dados-empreendimento',
                    templateUrl: 'views/plano-associativo/dados',
                    controller: 'dados.associativo.analise.ctrl' 
                })
                .state('app.group.analise.plano-associativo.matriz-conjugacao', {
                    url: '/matriz-conjugacao',
                    templateUrl: 'views/plano-associativo/matriz-conjugacao',
                    controller: 'matriz-conjugacao.associativo.analise.ctrl' 
                })
                .state('app.group.analise.plano-associativo.tabela-vendas', {
                    url: '/tabela-vendas',
                    templateUrl: 'views/plano-associativo/tabela-vendas',
                    controller: 'tabela-vendas.associativo.analise.ctrl' 
                })
                .state('app.group.analise.plano-associativo.fluxo-caixa', {
                    url: '/fluxo-caixa',
                    templateUrl: 'views/plano-associativo/fluxo-caixa',
                    controller: 'fluxo-caixa.associativo.analise.ctrl' 
                })
                .state('app.group.analise.plano-associativo.fluxo-total', {
                    url: '/fluxo-total',
                    templateUrl: 'views/plano-associativo/fluxo-total',
                    controller: 'fluxo-total.associativo.analise.ctrl' 
                })
                .state('app.group.analise.plano-associativo.dashboard', {
                    url: '/dashboard',
                    templateUrl: 'views/plano-associativo/dashboard',
                    controller: 'dashboard.associativo.analise.ctrl' 
                })
            .state('app.group.analise.proposta-vendas', {
                url: '/vendas', //URL do estado (Analise)
                templateUrl: '/views/proposta-vendas/nav',
                abstract: true
            })
                .state('app.group.analise.proposta-vendas.empresa', {
                    url: '/empresa',
                    templateUrl: '/views/proposta-vendas/empresa',
                    controller: 'empresa.vendas.analise.ctrl',
                })
                .state('app.group.analise.proposta-vendas.propostas', {
                    url: '/propostas',
                    templateUrl: '/views/proposta-vendas/propostas',
                    controller: 'propostas.vendas.analise.ctrl'
                })
                .state('app.group.analise.proposta-vendas.dashboard', {
                    url: '/dashboard',
                    templateUrl: '/views/proposta-vendas/dashboard',
                    controller: 'dashboard.vendas.analise.ctrl',
                    resolve: {
                        statistics: ['$q', '$stateParams', 'Venda', ($q, $stateParams, Venda) => {
                            var defered = $q.defer();
                            Venda.statistics($stateParams.analise).then((res) => {
                                defered.resolve(res.data);
                            }, () => {
                                defered.resolve({error: true, message: "Erro de conexão", detail: "Ocorreu um erro na conexão, tente novamente mais tarde"});
                            });
                            return defered.promise;
                        }]
                    }
                })
            .state('app.group.analise.negociacao', {
                url: '/negociacao/:negociacao',
                templateUrl: '/views/proposta-vendas/negociacao',
                controller: 'negociacao.vendas.analise.ctrl',
                resolve: {
                    negociacao: ['$q', '$stateParams', 'Negociacao', function($q, $stateParams, Negociacao){
                        var defered = $q.defer();
                        Negociacao.find($stateParams.negociacao).then((res) => {
                            // Formulas.set(res.data);
                            defered.resolve(res.data)
                        }, () => {
                            defered.resolve({error: true, message: "Erro de conexão", detail: "Ocorreu um erro na conexão, tente novamente mais tarde"});
                        });
                        return defered.promise;
                    }]
                }
            })
            .state('app.group.analise.propostas-corretor', {
                url: '/propostas',
                templateUrl: '/views/proposta-vendas/propostas',
                controller: 'propostas.vendas.analise.ctrl'
            })
            //-----------------------------------------------------
            //-----------------------------------------------------
        .state('cadastro-voucher', {
            url: '/cadastro-voucher',
            templateUrl: '/views/cadastro-voucher',
            controller: 'cadastro.voucher.controller'
        })
        .state('publico', {
            url: '/publico',
            abstract: true,
            template: '<ui-view></ui-view>',
        })
            .state('publico.analise', {
                url: '/analise/:analise',
                templateUrl: '/views/publico/analise',
                controller: 'tipologias.publico.ctrl',
                resolve: {
                    analise: ['$q', '$stateParams', 'Publico', 'Formulas', function($q, $stateParams, Publico, Formulas){
                        var defered = $q.defer();
                        Publico.analise($stateParams.analise).then((res) => {
                            Formulas.set(res.data);
                            defered.resolve(res.data)
                        }, () => {
                            defered.resolve({error: true, message: "Erro de conexão", detail: "Ocorreu um erro na conexão, tente novamente mais tarde"});
                        });
                        return defered.promise;
                    }]
                }
            })
            .state('publico.apartamento', {
                url: '/apartamento/:apartamento',
                templateUrl: '/views/publico/proposta',
                controller: 'apartamento.publico.ctrl',
                resolve: {
                    apartamento: ['$q', '$stateParams', 'Publico', 'Formulas', function($q, $stateParams, Publico, Formulas) {
                        var defered = $q.defer();
                        Publico.apartamento($stateParams.apartamento).then((res) => {
                            if(res.data.error) defered.resolve(res.data);
                            var apartamento = res.data;
                            Formulas.set(apartamento.tipologia.analise);
                            defered.resolve(apartamento);
                        }, () => {
                            defered.resolve({error: true, message: 'Erro de conexão', detail: "Ocorreu um erro na conexão, tente novamente mais tarde"});
                        });
                        return defered.promise;
                    }]
                }
            })
            .state('publico.negociacao', {
                url: '/negociacao/:negociacao',
                templateUrl: '/views/publico/negociacao',
                controller: 'negociacao.publico.ctrl',
                resolve: {
                    negociacao: ['$q', '$stateParams', 'NegociacaoPublico', 'Formulas', function($q, $stateParams, NegociacaoPublico, Formulas) {
                        var defered = $q.defer();
                        NegociacaoPublico.find($stateParams.negociacao).then((res) => {
                            if(res.data.error) defered.resolve(res.data);
                            var negociacao = res.data;
                            Formulas.set(negociacao.apartamento.tipologia.analise);
                            defered.resolve(negociacao);
                        }, () => {
                            defered.resolve({error: true, message: 'Erro de conexão', detail: "Ocorreu um erro na conexão, tente novamente mais tarde"});
                        });
                        return defered.promise;
                    }]
                }
            });

        $urlRouterProvider.otherwise('/app/home');
}]);