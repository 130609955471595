app.component('chat', {
    template : //html
    `
        <div id="chat" class="chat-container card" ng-class="{'hidden': $ctrl.hidden}">
            <div class="card-header bg-primary text-light">
                Chat <span ng-show="$ctrl.alerts>0" class="badge badge-light">{{$ctrl.alerts}}</span>
                <button class="btn btn-light btn-sm float-right" ng-click="$ctrl.toggle();">
                    <i class="fa" ng-class="{'fa-caret-down': !$ctrl.hidden, 'fa-caret-up': $ctrl.hidden}"></i>
                </button>
            </div>
            <div class="card-body chat-message-board">
                <button class="btn btn-primary btn-block mb-4" ng-click="$ctrl.loadPrevious()">Carregar mais antigas</button>
                <div class="d-flex w-100" ng-repeat="message in $ctrl.messages" ng-class="{'justify-content-end': (message.userid == $ctrl.userid)}">
                    <span class="alert alert-secondary" ng-class="{'alert-success': (message.userid == $ctrl.userid), 'alert-secondary': (message.userid != $ctrl.userid)}">
                        <strong>{{message.username}}</strong>
                        <div>{{message.text}}</div>
                        <small class="text-muted">{{message.timestamp | date : 'dd/MM/yyyy HH:mm:ss'}}</small>
                    </span>
                </div>

            </div>
            <div class="card-footer">
                <form class="input-group" ng-submit="$ctrl.send();">
                    <input ng-model="$ctrl.message" class="form-control">
                    <div class="input-group-btn">
                        <button type="submit" class="btn btn-primary"><i class="fa fa-paper-plane"></i></button>
                    </div>
                </form>
            </div>
        </div>
    `,
    bindings : {
        room: "<",
        username: "<",
        userid: "<"
    },
    controller: ['$scope', 'Messages', function($scope, Messages){
        var self = this;

        self.send = function() {
            if(!self.message) return;
            var message = {
                userid: self.userid,
                username: self.username,
                timestamp: Date.now(),
                text: self.message 
            };

            self.socket.emit('message', message);
            self.messages.push(message);
            self.scrollBottom();
            
            self.message = "";
        }

        self.toggle = function(){
            if(self.hidden) self.show();
            else self.hide();
        }

        self.loadPrevious = function(callback) {
            Messages.list('proposta', self.room, self.messages.length).then((result) => {
                if(!result.data.error) {
                    for(var i = 0; i < result.data.length; i++) {
                        self.messages.unshift(result.data[i]);
                    }
                    if(callback) callback();
                }
            });
        }

        self.scrollBottom = function(){
            $scope.$$postDigest(() => {
                var element = document.getElementsByClassName('chat-message-board')[0];
                element.scrollTop = element.scrollHeight - element.clientHeight
            });
        }

        self.hide = function(){
            var h = $('#chat').height();
            var headHeight = $('#chat .card-header').height();
            var bottom = (h - headHeight) * -1;
            $('#chat').css({
                bottom: bottom
            });
            self.hidden = true;
        }

        self.show = function() {
            $('#chat').css({
                bottom: 0
            });
            self.hidden = false;
            self.alerts = 0;
        }

        self.$onInit = function(){
            self.alerts = 0;
            self.socket = io.connect('/proposta');
            self.messages = [];

            self.loadPrevious(() => {
                self.scrollBottom();
            });
            
            self.socket.on('connect', () => {
                self.socket.emit('room', {
                    userid: self.userid,
                    username: self.username, 
                    room: self.room
                });
            });

            self.socket.on('message', (message) => {
                $scope.$apply(function(){
                    self.messages.push(message);
                    if(self.hidden) self.alerts++;
                    self.scrollBottom();
                });
            });

            self.hide();
        }
    }]
});