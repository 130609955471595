app.factory('Publico', ['$http', '$url', function($http, $url){
    var factory = {};
    
    //Analise
    factory.analise = function(analise){
        if(analise._id) analise = analise._id;
        return $http.get($url(['publico', 'analise', analise]));
    }

    factory.tipologias = function(analise) {
        if(analise._id) analise = analise._id;
        return $http.get($url(['publico', 'analise', analise, 'tipologias']));
    }

    factory.apartamento = function(apartamento) {
        if(apartamento._id) apartamento = apartamento._id;
        return $http.get($url(['publico', 'apartamento', apartamento]));
    }

    factory.subsidio = function(analise, renda_familiar, mais_de_um) {
        if(analise._id) analise = analise._id;
        return $http.get($url(['publico', 'subsidio', analise], {renda_familiar: renda_familiar, mais_de_um: mais_de_um}));
    }

    factory.aprovar = function(negociacao) {
        if(negociacao._id) negociacao = negociacao._id
        return $http.put($url(['publico', 'negociacao', negociacao, 'aprovar']));
    }

    return factory;
}]);