app.controller('tipologias.publico.ctrl', ['$scope', 'analise', 'Publico', function($scope, analise, Publico) {
    $scope.analise = analise;
    $scope.venda = analise[analise.recurso.nome];

    if(analise.error) {
        $('#error-modal').modal({
            backdrop: 'static'
        });
    } else {
        $scope.loading = true;
        Publico.tipologias(analise).then((res) => {
            $scope.tipologias = angular.copy(res.data);
        }).finally(function(){
            $scope.loading = false;
        });
    }

    $scope.showUnidades = function(tipologia) {
        $scope.unidades = tipologia.apartamentos;
        $scope.selected = null;
        $('#select-modal').modal('show');
    }
}]);