app.controller('fluxo-total.associativo.analise.ctrl', ['$scope', 'analise', 'Message', 'Empreendimento', function($scope, analise, Message, Empreendimento){
    $scope.analise = analise;
    $scope.plano = analise.plano_associativo;

    $scope.reload = function() {
        $scope.total_receita_comercializacao = 0;
        $scope.total_ressarcimento_executado = 0;
        $scope.total_ressarcimento_aporte = 0;
        $scope.total_apoio_pj_utilizado = 0;
        $scope.total_terreno = 0;
        $scope.total_receita_comercializacao_direta = 0;
        $scope.total_receita_comercializacao_apos_obra = 0;
        $scope.total_ressarcimento_aporte_terreno = 0;

        $scope.fluxo = $scope.plano.fluxoTotal();

        for(var i = 0; i < $scope.fluxo.length; i++) {
            $scope.total_receita_comercializacao += $scope.fluxo[i].receita_comercializacao || 0.0;
            $scope.total_ressarcimento_executado += $scope.fluxo[i].ressarciamento_executado || 0.0;
            $scope.total_receita_comercializacao_direta += $scope.fluxo[i].receita_comercializacao_direta || 0.0;
            $scope.total_receita_comercializacao_apos_obra += $scope.fluxo[i].receita_comercializacao_apos_obra || 0.0;
            $scope.total_ressarcimento_aporte += $scope.fluxo[i].ressarciamento_aporte || 0.0;
            $scope.total_ressarcimento_aporte_terreno += $scope.fluxo[i].ressarciamento_aporte_terreno || 0.0;
            $scope.total_apoio_pj_utilizado += $scope.fluxo[i].apoio_pj || 0.0;
            $scope.total_terreno += $scope.fluxo[i].terreno || 0.0;
        }

        $scope.plot.init($scope.fluxo);
    }

    $scope.custo = function(i){
        return ($scope.fluxo[i].custo_obra || 0.0)
        + ($scope.fluxo[i].bdi || 0.0)
        + ($scope.fluxo[i].valor_executado || 0.0)
        + ($scope.fluxo[i].aporte || 0.0)
        + ($scope.fluxo[i].juros || 0.0)
        + ($scope.fluxo[i].valor_terreno || 0.0)
        + ($scope.fluxo[i].corretagem_terreno || 0.0)
        + ($scope.fluxo[i].manutencao || 0.0)
        + ($scope.fluxo[i].administracao || 0.0)
        + ($scope.fluxo[i].publicidade || 0.0)
        + ($scope.fluxo[i].impostos || 0.0)
        + ($scope.fluxo[i].corretagem_vendas || 0.0)
        + ($scope.fluxo[i].itbi || 0.0);
    }

    $scope.receita = function(i) {
        return ($scope.fluxo[i].receita_comercializacao || 0.0)
        + ($scope.fluxo[i].receita_comercializacao_direta | 0.0)
        + ($scope.fluxo[i].receita_comercializacao_apos_obra || 0.0)
        + ($scope.fluxo[i].ressarciamento_executado || 0.0)
        + ($scope.fluxo[i].ressarciamento_aporte || 0.0)
        + ($scope.fluxo[i].ressarcimento_aporte_terreno || 0.0)
        + ($scope.fluxo[i].apoio_pj || 0.0)
        + ($scope.fluxo[i].terreno || 0.0);
    }

    $scope.saldo = function(i){
        return $scope.receita(i) - $scope.custo(i);
    }

    $scope.vpl = function(){
        var f = [];
        for(var i = 0; i < $scope.fluxo.length; i++){
            if($scope.fluxo[i]){
                f.push({
                    value : $scope.saldo(i)
                });
            } else {
                f.push({
                    value : 0
                });
            }
        }

        return Empreendimento.vpl($scope.plano.custo_oportunidade / 100, f, 'value', true);
    }

    $scope.tir = function() {
        var f = [];
        for(var i = 0; i < $scope.fluxo.length; i++){
            if($scope.fluxo[i]){
                f.push({
                    value : $scope.saldo(i)
                });
            } else {
                f.push({
                    value : 0
                });
            }
        }

        return Empreendimento.tir(f, 'value');
    }

    $scope.margem = function() {
        return $scope.vpl() / $scope.plano.vgv_total * 100;
    }

    $scope.plot = {
        investimentoMaximo: 0,
        series : ["Faturamento"],
        labels : [],
        data : [],
        init : function(fluxo){
            var max = 0, min = 0;
            
            this.labels = [];
            var series1 = [];

            //Preencher grafico e guardar maior e menor valor
            for(var i in fluxo){
                this.labels.push("Mês " + i);
                var total = $scope.saldo(i);

                series1.push(total);
                if(total > max) max = total;
                if(total < min) min = total;
            }

            this.data = [];
            this.data.push(series1);
            this.investimentoMaximo = min;

            var mult = 1;
            var range = max - min;
            var temp = range;
            while(temp > 10){
                mult *= 10;
                temp = range / mult;
            }
            
            temp = Math.round(temp) * mult;
            var step = temp / 20;

            this.options = {
                tension: 0,
                tooltips : {
                    callbacks : {
                        label : function(tooltipItem){
                            return $filter('currency')(tooltipItem.yLabel, 'R$ ', 2);
                        }
                    }
                },
                scales : {
                    yAxes: [
                        {
                            type: 'linear',
                            display: true,
                            position: 'left',
                            ticks: {
                                maxTicksLimit: 20,
                                stepSize : step,
                                max : max,
                                min : min,
                                callback: function(value, index, values){
                                    return $filter('currency')(value, 'R$ ', 2);
                                }
                            }
                        }
                    ]
                }
            }


        }
    }

    $scope.reload();

}]);