app.factory('Inscricao', ['$http', function($http){
    var inscricao = {};
    
    inscricao.list = function(parameters){
        var url = '/inscricao';

        var first = true;
        for(var i in parameters){
            if(first) {
                url += '?'
                first = false;
            } else url += '&'
            url += i + '=' + parameters[i];
        }

        return $http.get(url);
    }

    inscricao.has = function(group, recurso){
        var url = '/inscricao/' + group._id + '/' + recurso;
        return $http.get(url);
    }

    inscricao.recursos = function(groupId) {
        return $http.get('/inscricao/recursos/' + groupId);
    }

    return inscricao;
}]);