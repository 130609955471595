app.controller('edit-group.ctrl', ['$scope', 'group', 'estados', 'Group', 'Message', function($scope, group, estados, Group, Message){
    $scope.grupo = group;
    $scope.estados = estados;
    $scope.emailForm = {
        value: ''
    };

    $scope.tab = 'dados';

    $scope.addEmail = function(){
        if(!$scope.grupo.emails) 
            $scope.grupo.emails = [];
        
        var email = $scope.emailForm.value;
        if(!email || email.length == 0) {
            Message.showErro("Informe um email");
            return;
        }

        if($scope.grupo.emails.indexOf(email) >= 0) {
            Message.showErro("Email já cadastrado");
            return;
        }

        if(!validateEmail(email)) {
            Message.showErro("Informe um email valido");
            return;  
        }

        $scope.grupo.emails.push(email);
        $scope.emailForm.value = '';
    }

    $scope.removeEmail = function(email) {
        var idx = $scope.grupo.emails.indexOf(email);
        $scope.grupo.emails.splice(idx, 1);
    }

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    $scope.salvar = function(){
        Message.showSpinner();
        Group.update(group).then(function(){
            Message.showSuccess("Dados alterados com sucesso!")
        }).finally(function(){
            Message.hideSpinner();
        });
    }

}]);