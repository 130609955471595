app.controller('fluxo-total.direto.analise.ctrl', ['$scope', 'analise', 'Message', function($scope, analise, Message){
    $scope.analise = analise;
    $scope.plano = analise.plano_direto;
    $scope.detalhar = true;

    $scope.reload = function() {
        $scope.fluxo = analise.plano_direto.fluxoTotal();

        var executado = 0;
        for(var i = 0; i < $scope.plano.executado.length; i++) {
            executado += ($scope.plano.executado[i].pct || 0);
        }

        Message.clear();
        if(executado <= 100) {
            Message.showInfo('Total de obra executado: ' + executado + '%');
        } else {
            Message.showWarning('ATENÇÃO! O total de obra executado excede 100% : ' + executado + '%');
        }
    }

    if(!$scope.plano.executado) $scope.plano.executado = [];

    for(var i = 0; i < $scope.plano.periodo_obra; i++) {
        if(!$scope.plano.executado[i]) $scope.plano.executado[i] = {pct: 0};
    }

    if($scope.plano.executado.length > $scope.plano.periodo_obra)
        $scope.plano.executado.splice($scope.plano.periodo_obra, $scope.plano.executado.length);


    $scope.reload();

    $scope.exportToExcel = function(){
        var htmls = "";
        var uri = 'data:application/vnd.ms-excel;base64,';
        var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'; 
        var base64 = function(s) {
            return window.btoa(unescape(encodeURIComponent(s)))
        };

        var format = function(s, c) {
            return s.replace(/{(\w+)}/g, function(m, p) {
                return c[p];
            }).replace(/\-\<\/output-cell\>/g, '0</output-cell>');
        };

        htmls = document.getElementById('fluxo-table').outerHTML;

        var ctx = {
            worksheet : 'Fluxo Total',
            table : htmls
        }


        var link = document.createElement("a");
        link.download = "Fluxo Total.xls";
        link.href = uri + base64(format(template, ctx));
        link.click();
    }
}]);