app.controller('empresa.vendas.analise.ctrl', ['$scope', 'analise', '$state', 'estados', 'Estado', 'Subsidio', 'Message', 'Apartamento', function($scope, analise, $state, estados, Estado, Subsidio, Message, Apartamento) {
    $scope.analise = analise;
    $scope.vendas = analise[analise.recurso.nome];
    $scope.estados = estados;
    $scope.cidades = [];

    $scope.calcularJurosEfetivo = function() {
        var juros_efetivo = Math.pow((($scope.vendas.pct_juros_fin_nominal / 12 / 100) + 1), 12) - 1;
        $scope.vendas.pct_juros_fin_efetivo = juros_efetivo * 100;
    }

    $scope.calcularJurosNominal = function() {
        var juros_nominal = (Math.pow((1 + ($scope.vendas.pct_juros_fin_efetivo / 100)), 1/12) - 1) * 12;
        $scope.vendas.pct_juros_fin_nominal = juros_nominal * 100;
    }

    $scope.subsidios = [];
    $scope.refresh_subsidios = function(){
        $scope.subsidios = [];
        if(!$scope.analise.estado) return;

        Estado.municipios($scope.analise.estado).then((res) => {
            $scope.cidades = res.data;
        });

        Subsidio.list($scope.analise.estado.uf, $scope.analise.recurso.nome).then(function(result){
            $scope.subsidios = result.data;
        }, function(err){
            
        });
    }
    $scope.refresh_subsidios();

    $scope.apartamento = {
        nome: '',
        error: false
    }
    $scope.addApartamento = function(proposta, apartamento) {
        if(!proposta.apartamentos) proposta.apartamentos = [];
        if(!$scope.apartamento.nome){
            $scope.apartamento.error = 'Informe o número!'
            return;
        } 
        $scope.apartamento.error = false;
        
        proposta.apartamentos.push({
            codigo: $scope.apartamento.nome,
            _qtd_propostas: 0
        });

        $scope.apartamento.nome = '';
    }

    $scope.rmvApartamento = function(proposta, apartamento) {
        var idx = proposta.apartamentos.indexOf(apartamento);
        if(idx > 0) {
            proposta.apartamentos.splice(idx, 1);
            $scope.modal.hide();
        }
    }

}]);    