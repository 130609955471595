app.component('unidadeForm', {
    template: //html 
    `
        <div id="{{$ctrl.id}}" class="modal fade">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5>Unidades de {{$ctrl.tipologia.nome}}</h5>
                    </div>
                    <div class="modal-body" ng-if="$ctrl.tipologia">
                        <form class="row" ng-submit="$ctrl.add()">
                            <div class="form-group col-12 col-sm-8">
                                <label class="font-weight-bold">Nova Unidade</label>
                                <input class="form-control form-control-sm" ng-class="{'is-invalid': $ctrl.error}" ng-model="$ctrl.newUnidade">
                                <div class="invalid-feedback">
                                    Informe o nome da unidade
                                </div>
                            </div>
                            <div class="form-group col-12 col-sm-4">
                                <label class="empty-label d-none d-sm-block"></label>
                                <button class="btn btn-sm btn-block btn-primary" type="submit">
                                    <i class="fa fa-plus"></i> Adicionar
                                </button>
                            </div>
                        </form>

                        <div class="row">
                            <div class="col-12">
                                <ul class="list-group">
                                    <li class="list-group-item" ng-repeat="i in $ctrl.list">
                                        <div class="d-flex justify-content-between align-items-center">
                                            Unidade {{i.codigo}}
                                            <span ng-show="i.propostaAceita" class="badge badge-success badge-pill">Vendido</span>
                                        </div>
                                        <small ng-hide="i.propostaAceita">
                                            <!-- <a href="javascript:void(0)" ng-click="$ctrl.remove(i)"><i class="fa fa-clipboard-list"></i> Ver Propostas</a> -->
                                            <a href="javascript:void(0)" class="text-danger" ng-click="$ctrl.remove(i)"><i class="fa fa-trash"></i> Apagar</a>
                                        <small>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>

                    <div class="modal-footer">
                        <button class="btn btn-secondary" ng-click="$ctrl.return();">
                            Voltar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    `,
    bindings: {
        id: '<',
        analise: '=',
        onReturn: '&',
        passApi: '&'
    },
    controller: ['Apartamento', 'Message', function(Apartamento, Message){
        var scope = this;
        
        scope.newUnidade = "";
        scope.error = false;
        scope.add = function(){
            scope.newUnidade = scope.newUnidade.trim();
            if(!scope.newUnidade) {
                scope.error = true;
                return;
            }

            scope.error = false;

            Message.showSpinner();
            Apartamento.create((scope.analise.group._id || scope.analise.group), scope.analise._id, scope.tipologia._id, {
                codigo: scope.newUnidade
            }).then(res => {
                scope.newUnidade = "";
                scope.list.push(res.data);
                Message.hideSpinner();
            }, err => {
                Message.error("Ocorreu um erro ao criar a unidade. Tente novamente mais tarde.");
            });
        }

        scope.list = [];
        scope.remove = function(unidade) {
            Message.showSpinner();
            Apartamento.delete((scope.analise.group._id || scope.analise.group), scope.analise._id, scope.tipologia._id, unidade)
            .then((res) => {
                if(res.data.error) {
                    Message.error(res.data.detail);
                    Message.hideSpinner();
                    return;
                }

                scope.refresh().finally(() => {
                    Message.hideSpinner();
                    Message.success(res.data.message);
                });

            }, err => {

            });
        }
        
        scope.refresh = function(){
            return Apartamento.list((scope.analise.group._id || scope.analise.group), scope.analise._id, scope.tipologia._id)
            .then(res => {
                scope.list = angular.copy(res.data);
            }, err => {
                Message.error("Ocorreu um erro ao listar as unidade dessa tipologia. Tente novamente mais tarde.");
            });
        }

        scope.return = function(){
            if(scope.onReturn) scope.onReturn();
            scope.jq().modal('hide');
        }

        scope.jq = function(){
            return $('#' + scope.id);
        }

        scope.$onInit = function(){
            if(!scope.id) scope.id = "unidade-modal-" + parseInt(Math.random() * 10000);
            scope.venda = scope.analise[scope.analise.recurso.nome];

            scope.passApi({
                api: {
                    show: function(tipologia){
                        scope.tipologia = tipologia;
                        Message.showSpinner();
                        scope.refresh().finally(() => {
                            Message.hideSpinner();
                            scope.jq().modal('show');
                        });
                    },
                    hide: function(){
                        scope.jq().modal('hide');
                    }
                }
            })
        }
    }]
})