app.factory('Analise', ['$http', '$url', function($http, $url){
    var factory = {};
    
    //Analise
    factory.public = function(id) {
        return $http.get($url(['publico', 'analise', id]));
    }

    factory.subsidio = function(id, buyer) {
        return $http.post($url(['publico', 'subsidio', id]), buyer);
    }

    factory.list = function(groupId, params){
        return $http.get($url(['analise', groupId], params));
    }

    factory.create = function(groupId, analise) {
        return $http.post($url(['analise', groupId]), analise);
    }

    factory.read = function(groupId, analiseId) {
        return $http.get($url(['analise', groupId, analiseId]));
    }

    factory.update = function(groupId, analise) {
        return $http.put($url(['analise', groupId, analise._id]), analise);
    }

    factory.share = function(groupId, analise) {
        return $http.put($url(['analise', groupId, analise._id, 'share']), analise);
    }

    factory.delete = function(groupId, analise) {
        return $http.delete($url(['analise', groupId, analise._id]));
    }

    //Benchmark
    factory.listBenchmarks = function(groupId, analiseId) {
        return $http.get($url(['benchmark', groupId, analiseId]));
    }

    factory.createBenchmark = function(groupId, analiseId, benchmark) {
        return $http.post($url(['benchmark', groupId, analiseId]), benchmark);
    }

    factory.getBenchmark = function(groupId, analiseId, benchmarkId) {
        return $http.get($url(['benchmark', groupId, analiseId, benchmarkId]));
    }

    return factory;
}]);