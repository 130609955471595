app.component('negociacao', {
    template : //html
    ` 
        <div>

            <!-- SINAL -->
            <div id="proposta-sinal" class="mb-2">
                <strong>Sinal (Valor de Entrada)</strong>
                <table class="w-100">
                    <tr>
                        <td class="form-group">
                            <input class="form-control form-control-sm" value="{{$ctrl.propostaEmpresa.sinal | number : 2}}" disabled >
                        </td>
                        <td class="form-group" ng-show="$ctrl.propostaCliente">
                            <input class="form-control form-control-sm" ng-model="$ctrl.propostaCliente.sinal" number-format ng-change="$ctrl.handleChange()" ng-class="{'is-invalid': $ctrl.errors.sinal}"
                                ng-class="{'is-invalid': $ctrl.errors.sinal }">
                        </td>
                    </tr>
                    <tr ng-show="$ctrl.errors.sinal">
                        <td colspan="2">
                            <div class="text-danger">{{$ctrl.errors.sinal}}</div>
                        </td>
                    </tr>
                </table>
            </div>

            
            <!-- INTERCALADAS -->
            <div id="proposta-intercaladas" class="mb-2" ng-if="!$ctrl.propostaCliente">
                <hr/>
                <strong>Intercaladas (Pro-Soluto)</strong>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Mês</th>
                            <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="form-group" ng-repeat="intercalada in $ctrl.propostaEmpresa.intercaladas">
                            <td>
                                <strong>{{intercalada.mes | number : 0}}</strong>
                            </td>
                            <td>
                                <input class="form-control form-control-sm" value="{{intercalada.valor | number : 2}}" disabled>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- INTERCALADAS -->
            <div id="proposta-intercaladas" class="mb-2" ng-if="$ctrl.propostaCliente">
                <hr/>
                <strong>Intercaladas (Pro-Soluto)</strong>
                <table class="table table-sm">
                    <thead>
                        <tr>
                            <th>Mês</th>
                            <th>Valor</th>
                            <th>Sua Proposta</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr class="form-group" ng-repeat="intercalada in $ctrl.propostaCliente.intercaladas">
                            <td>
                                <strong ng-if="!intercalada.new">{{intercalada.mes | number : 0}}</strong>
                                <input ng-if="intercalada.new" class="form-control form-control-sm" ng-model="intercalada.mes" number-format="0" ng-change="$ctrl.handleChange()">
                            </td>
                            <td>
                                <input class="form-control form-control-sm" value="{{$ctrl.propostaEmpresa.intercaladas[$index].valor | number : 2}}" disabled>
                            </td>
                            <td >
                                <input class="form-control form-control-sm" ng-model="intercalada.valor" number-format ng-change="$ctrl.handleChange()">
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="mt-2 d-flex justify-content-end" ng-show="$ctrl.propostaCliente" >
                    <button class="btn btn-danger" ng-click="$ctrl.removerIntercalada()">
                        <i class="fa fa-minus"/> Remover
                    </button>
                    <button class="btn btn-primary ml-2" ng-click="$ctrl.adicionarIntercalada()">
                        <i class="fa fa-plus"/> Adicionar
                    </button>
                </div>
            </div>
            
            
            <!-- MENSAIS -->
            <div id="proposta-numero-parcelas" class="mb-2" ng-hide="$ctrl.vendas.parcela_decrescente">
                <hr/>
                <strong>Número de Parcelas Mensais</strong>
                <table class="w-100">
                    <tr>
                        <td>
                            <input class="form-control form-control-sm" value="{{$ctrl.propostaEmpresa.numero_parcelas | number : 0}}" disabled>
                        </td>
                        <td ng-show="$ctrl.propostaCliente">
                            <input class="form-control form-control-sm" ng-model="$ctrl.propostaCliente.numero_parcelas" number-format="0" ng-change="$ctrl.handleChange()">
                        </td>
                    </tr>
                </table>
            </div>

            <div id="proposta-valor-parcelas" class="mb-2" ng-hide="$ctrl.vendas.parcela_decrescente">
                <strong>Valor das Parcelas Mensais (Pro-Soluto)</strong>
                <table class="w-100">
                    <tr>
                        <td>
                            <input class="form-control form-control-sm" value="{{$ctrl.propostaEmpresa.valor_parcela | number : 2}}" disabled>
                        </td>
                        <td ng-show="$ctrl.propostaCliente">
                            <input class="form-control form-control-sm" ng-model="$ctrl.propostaCliente.valor_parcela" number-format ng-change="$ctrl.handleChange()"
                                ng-class="{'is-invalid': $ctrl.errors.valor_parcela }">
                        </td>
                    </tr>
                    <tr ng-show="$ctrl.errors.valor_parcela">
                        <td colspan="2">
                            <div class="text-danger">{{$ctrl.errors.valor_parcela}}</div>
                        </td>
                    </tr>
                </table>
            </div>

            <div id="proposta-valor-parcelas" class="mb-2" ng-show="$ctrl.vendas.parcela_decrescente">
                <hr/>
                <strong>Soma das Parcelas Mensais (Pro-Soluto)</strong>
                <table class="w-100">
                    <tr>
                        <td>
                            <input class="form-control form-control-sm" value="{{$ctrl.propostaEmpresa.valor_parcela | number : 2}}" disabled>
                        </td>
                        <td ng-show="$ctrl.propostaCliente">
                            <input class="form-control form-control-sm" ng-model="$ctrl.propostaCliente.valor_parcela" number-format ng-change="$ctrl.handleChange()"
                                ng-class="{'is-invalid': $ctrl.errors.valor_parcela }">
                        </td>
                    </tr>
                    <tr ng-show="$ctrl.errors.valor_parcela">
                        <td colspan="2">
                            <div class="text-danger">{{$ctrl.errors.valor_parcela}}</div>
                        </td>
                    </tr>
                </table>

                <small><a href="" data-toggle="collapse" data-target="#{{$ctrl.id}}-parcela-detail">Ver parcelas decrescentes</a></small>

                <div id="{{$ctrl.id}}-parcela-detail" class="collapse">
                    <table class="table table-sm table-stripped">
                        <thead>
                            <tr>
                                <th>Mês</th>
                                <th class="text-muted">Proposta</th>
                                <th class="text-success">Sua Proposta</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr ng-repeat="parcela in $ctrl.parcelas_pa">
                                <th>{{parcela.mes}}</th>
                                <td class="text-muted"> {{parcela.valor_empresa | currency : 'R$ '}} </td>
                                <td class="text-success"> {{parcela.valor_cliente | currency : 'R$ '}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            
            <!-- CHAVES -->
            <div id="proposta-chaves" class="mb-2">
                <hr/>
                <strong>Financiamento</strong>
                <table class="w-100">
                    <tr>
                        <td>
                            <input class="form-control form-control-sm" value="{{$ctrl.propostaEmpresa.chaves | number : 2}}" disabled>
                        </td>
                        <td ng-show="$ctrl.propostaCliente">
                            <input class="form-control form-control-sm" value="{{$ctrl.propostaCliente.chaves | number : 2}}" disabled>
                        </td>
                    </tr>
                </table>
            </div>

            <!-- Desconto -->
            <div id="proposta-desconto" ng-show="$ctrl.propostaEmpresa.desconto" class="mb-2">
                <hr/>
                <strong class="text-success"> (-) Desconto de {{$ctrl.propostaEmpresa.desconto | currency : 'R$ '}}</strong>
            </div>

            
            <!-- Totais -->
            <div id="proposta-total" class="mb-2">
                <hr/>
                <strong>Total</strong>
                <table class="w-100">
                    <tr>
                        <td>
                            <input class="form-control form-control-sm" value="{{$ctrl.total_empresa | number : 2}}" disabled>
                        </td>
                        <td ng-show="$ctrl.propostaCliente">
                            <input class="form-control form-control-sm" value="{{$ctrl.total_cliente | number : 2}}" disabled>
                        </td>
                    </tr>
                </table>
            </div>
            
            <div id="ajustes">
                <table ng-show="$ctrl.propostaCliente" class="table table-sm">
                    <thead>
                        <tr>
                            <th colspan="2" class="text-center {{$ctrl.proposta_status_class}}">
                                {{$ctrl.proposta_status}}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Desembolso</td>
                            <td class="text-right"><output-cell value="($ctrl.total_cliente - $ctrl.propostaCliente.subsidio - $ctrl.propostaCliente.fgts) "></td>
                        </tr>
                        <tr ng-show="$ctrl.propostaCliente.subsidio">
                            <td>(+) Subsidio</td>
                            <td class="text-right"><output-cell value="$ctrl.propostaCliente.subsidio"></td>
                        </tr>
                        <tr ng-show="$ctrl.propostaCliente && $ctrl.propostaCliente.fgts">
                            <td>(+) FGTS</td>
                            <td class="text-right"><output-cell value="$ctrl.propostaCliente.fgts"></td>
                        </tr>
                        <tr>
                            <td>(=) Total</td>
                            <td class="text-right"><output-cell value="$ctrl.total_cliente"></td>
                        </tr>
                        <tr ng-show="$ctrl.diff > 1">
                            <td class="text-left">Você está quase lá, falta apenas</td>
                            <td class="text-right text-danger"><output-cell value="$ctrl.diff"/> </td>
                        </tr>
                        <tr class="table-warning" ng-show="($ctrl.diff > 1) && ($ctrl.sugestao_parcela != $ctrl.propostaCliente.valor_parcela)">
                            <td>Sugestão para parcela mensal</td>
                            <td class="text-right">{{$ctrl.sugestao_parcela | currency : 'R$ '}}</td>
                        </tr>
                        <tr class="table-danger" ng-show="$ctrl.errors.pago_durante_obra">
                            <td colspan="2">
                                {{$ctrl.errors.pago_durante_obra}}
                            </td>
                        </tr>
                        <tr class="table-danger" ng-show="$ctrl.errors.valor_minimo">
                            <td colspan="2">
                                {{$ctrl.errors.valor_minimo}}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div ng-show="$ctrl.diff >= 1" class="alert alert-info">
                    Redistribua a diferença remanescente de {{$ctrl.diff | currency : 'R$ '}} nas células verdes, dentro de sua conveniência financeira, para tornar sua proposta satisfatória.
                </div>
            </div>

        </div>
    `,
    bindings : {
        id: '@',
        vendas: '<',
        propostaEmpresa: '=',
        propostaCliente: '=',
        comprador: '=',
        onChange: '&',
        passApi: '&',
        erro: '=',
        status: '='
    },
    controller : ['$filter', 'Math', '$scope', function($filter, Math, $scope){
        var ctrl = this;
        
        ctrl.handleChange = function(){
            ctrl.errors = {}; //Remover mensagens de erro
            
            ctrl.total_empresa = ctrl.vendas.total(ctrl.propostaEmpresa);
            
            if(!ctrl.propostaCliente) return;
            var erro = false;

            ctrl.total_cliente = ctrl.vendas.total(ctrl.propostaCliente);

            //Verificar valor minimo do sinal
            var sinal_minimo = ctrl.vendas.sinal_minimo;
            if( sinal_minimo > (ctrl.propostaCliente.sinal) ) {
                ctrl.errors.sinal = 'O valor minimo aceitavel do sinal é ' + $filter('currency')(sinal_minimo, 'R$ ') + '.';
                erro = true;
            }

            //Verificar valor minimo durante a obra
            var minimo_durante_obra = ctrl.vendas.minimo_durante_obras(ctrl.total_empresa);
            if(ctrl.vendas.pago_durante_obra(ctrl.propostaCliente) < minimo_durante_obra){
                ctrl.errors.pago_durante_obra = 'A proposta deve cobrir ' + $filter('number')(ctrl.vendas.pct_minimo_pagamento_durente_obra, 2) + '% do valor total do imovel (' + $filter('currency')(minimo_durante_obra, 'R$') + ') até o mês do Habite-se.';
                erro = true;
            }

            var valor_minimo = ctrl.total_empresa * ctrl.vendas.pct_minimo_proposta / 100
            if(ctrl.total_cliente < valor_minimo) {
                ctrl.errors.valor_minimo = 'O valor da proposta deve ser no minimo ' + $filter('number')(valor_minimo, 2) + '.';
                erro = true;
            }

            //Verificar se é menor que o valor do cliente
            ctrl.diff = Math.compare(ctrl.total_empresa, ctrl.total_cliente);

            var parcela_sugerida = 0;
            var max = ctrl.comprador.renda_familiar * 0.3;

            if(ctrl.propostaCliente.numero_parcelas > 0) {
                if(ctrl.vendas.parcela_decrescente) {
                    max = parcela_sugerida = ((ctrl.propostaCliente.numero_parcelas + 1) * max / 2)
                } else {
                    parcela_sugerida = ( (ctrl.diff || 0) / (ctrl.propostaCliente.numero_parcelas) ) + ctrl.propostaCliente.valor_parcela;
                }
            }
            
            if(ctrl.propostaCliente.valor_parcela > max) {
                ctrl.errors.valor_parcela = 'O valor da parcela deve ser no máximo ' + $filter('currency')(max, 'R$ ');
                erro = true;
            }

            if(ctrl.diff >= 1 || erro) {
                ctrl.proposta_status = "Sua proposta é insatisfatória."
                ctrl.proposta_status_class = "text-danger";
                ctrl.sugestao_parcela = Math.min(max, parcela_sugerida);
            } else if((ctrl.diff < 1 && ctrl.diff > -1) && !erro) {
                ctrl.proposta_status = "Sua proposta é satisfatória."
                ctrl.proposta_status_class = "text-success";
                ctrl.sugestao_parcela = 0;
            } else if(ctrl.diff <= -1){
                ctrl.proposta_status = "Sua proposta excede o valor nominal do imóvel."
                ctrl.proposta_status_class = "text-warning";
                ctrl.sugestao_parcela = 0;
            }

            ctrl.status.error = erro;
            ctrl.is_satisfatoria = ctrl.diferenca == 0 && !erro;
            
            //Calcular parcelas decrescentes
            ctrl.calcular_parcelas_decrescentes();

            ctrl.onChange({
                negociacao: ctrl.propostaCliente, 
                satisfatoria: (ctrl.diff == 0)
            });
        }

        ctrl.adicionarIntercalada = function(){
            ctrl.propostaCliente.intercaladas.push({
                mes: 0,
                valor: 0,
                new: true
            })
        }

        ctrl.removerIntercalada = function(){
            if(ctrl.propostaCliente.intercaladas.length > 0 && 
                ctrl.propostaCliente.intercaladas[ctrl.propostaCliente.intercaladas.length-1].new) {
                ctrl.propostaCliente.intercaladas.pop();
                ctrl.handleChange();
            }
        }

        ctrl.calcular_parcelas_decrescentes = function(){
            if(ctrl.vendas.parcela_decrescente) {
                var pa1 = ctrl.vendas.parcelas_pa(ctrl.propostaEmpresa);
                var pa2 = null;
                
                if(ctrl.propostaCliente){
                    pa2 = ctrl.vendas.parcelas_pa(ctrl.propostaCliente);
                }

                var pa = [];
                for(var i = 0; i < pa1.length; i++) {
                    var parcela = {
                        mes: i+1,
                        valor_empresa: pa1[i]
                    }

                    if(ctrl.propostaCliente) parcela.valor_cliente = pa2[i];

                    pa.push(parcela);
                }
                ctrl.parcelas_pa = pa;
            }
        }

        ctrl.$onInit = function(){
            ctrl.status = {};

            ctrl.handleChange();
            
            ctrl.passApi({api: {
                triggerChange: function(){
                    ctrl.handleChange();
                }
            }});
            ctrl.calcular_parcelas_decrescentes();
        }

        ctrl.$onChanges = function(changesObj){
            ctrl.handleChange();
        }
        
    }]
})