app.run(['$transitions', 'Session', 'Message', '$state', function($transitions, Session, Message, $state){
    //Quando tentar acessar qualquer estado de 'app'
    $transitions.onStart({to: 'app.**'}, function(trans){
        
        //Sem credenciais
        if(!Session.getCredentials()) {
            //Ir para o login
            window.location = '/auth/login';
        }
    });

    $transitions.onStart({}, function(trans){
        Message.showSpinner();
        trans.promise.finally(Message.hideSpinner);
    });

    // $transitions.onError({}, function(trans){
    //     console.log(trans.from());
    //     // $state.go('error', trans.error().detail);
    //     return trans.redirect('error', trans.error().detail);
    // });
}]);