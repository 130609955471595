app.component('buyerForm', {
    template: //html 
    `
        <div class="form-group form-group-sm">
            <label for="buyer-form-nome">Nome</label>
            <input id="buyer-form-nome" class="form-control" ng-model="$ctrl.negociacao.comprador.nome" type="text" name="nome"
                ng-class="{'is-invalid': $ctrl.error.nome}" ng-change="$ctrl.validate('nome')">
            <div class="invalid-feedback">
                {{$ctrl.error.nome}}
            </div>
        </div>

        <div class="form-group form-group-sm">
            <label for="buyer-form-cpf">CPF</label>
            <input id="buyer-form-cpf" class="form-control" ng-model="$ctrl.negociacao.comprador.cpf" type="text" mask="999.999.999-99"
                ng-class="{'is-invalid': $ctrl.error.cpf}" ng-change="$ctrl.validate()">
            <div class="invalid-feedback">
                {{$ctrl.error.cpf}}
            </div>
        </div>

        <div class="form-group form-group-sm">
            <label for="buyer-form-email">Email</label>
            <input id="buyer-form-email" class="form-control" ng-model="$ctrl.negociacao.comprador.email" type="text"
                ng-class="{'is-invalid': $ctrl.error.email}" ng-change="$ctrl.validate()">
            <div class="invalid-feedback">
                {{$ctrl.error.email}}
            </div>
        </div>

        <div class="form-group form-group-sm">
            <label for="buyer-form-email">Telefone</label>
            <input id="buyer-form-email" class="form-control" ng-model="$ctrl.negociacao.comprador.telefone" type="text" mask="(99) 9?9999-9999"
                ng-class="{'is-invalid': $ctrl.error.telefone}" ng-change="$ctrl.validate()">
            <div class="invalid-feedback">
                {{$ctrl.error.telefone}}
            </div>
        </div>

        <hr/>

        <div class="form-group form-check">
            <input type="checkbox" ng-model="$ctrl.negociacao.comprador.mais_de_um" class="form-check-input" id="form-buyer-multiple"
                ng-change="$ctrl.refresh_subsidio()">
            <label class="form-check-label" for="form-buyer-multiple">Há mais de um comprador ou dependente?</label>
        </div>

        <div class="form-group form-group-sm">
            <label for="form-buyer-data-nascimento">Qual a data de nascimento do proponente mais velho?</label>
            <datepicker id="data-habitese-picker" date-format="dd/MM/yyyy" selector="form-control" 
                datepicker-append-to="#data-habitese-picker" date-typer="true">
                <input id="form-buyer-data-nascimento" class="form-control" ng-model="$ctrl.negociacao.comprador.data_nascimento"
                    mask="99/99/9999" type="text" ng-class="{'is-invalid' : $ctrl.error.idade}"
                    ng-blur="$ctrl.validate()"/>
                <div class="invalid-feedback">
                    {{$ctrl.error.idade}}
                </div>
            </datepicker>
        </div>

        <div class="form-group form-group-sm">
            <label for="form-buyer-renda-familiar">Qual a sua renda familiar mensal?</label>
            <input id="form-buyer-renda-familiar" class="form-control" ng-model="$ctrl.negociacao.comprador.renda_familiar" number-format
                ng-class="{'is-invalid' : $ctrl.error.renda_familiar}" ng-change="$ctrl.refresh_subsidio()">
            <div class="invalid-feedback">
                {{$ctrl.error.renda_familiar}}
            </div>
        </div>

        <div class="form-group form-group-sm" ng-show="$ctrl.params.is_mcmv">
            <label>Subsidio Minha Casa Minha Vida</label>
            <input class="form-control" value="{{$ctrl.negociacao.comprador.subsidio | currency : 'R$ '}}" readonly>
            <small class="text-danger" ng-show="$ctrl.error.subsidio">
                {{$ctrl.error.subsidio}}
            </small>
        </div>

        <div class="form-group form-check">
            <input type="checkbox" ng-model="$ctrl.negociacao.comprador.possui_imovel_cidade" class="form-check-input" id="form-buyer-possui-imovel">
            <label class="form-check-label" for="form-buyer-possui-imovel">Você possui outro imovel nesta cidade?</label>
        </div>
        
        <div class="form-group form-check">
            <input type="checkbox" ng-model="$ctrl.negociacao.comprador.cotista_fgts" class="form-check-input" id="form-buyer-cotista-fgts">
            <label class="form-check-label" for="form-buyer-cotista-fgts">Você é cotista do FGTS?</label>
        </div>

        <div class="form-group form-check">
            <input type="checkbox" ng-model="$ctrl.negociacao.comprador.ja_beneficiado" class="form-check-input" id="form-buyer-beneficiado-fgts">
            <label class="form-check-label" for="form-buyer-beneficiado-fgts">Já foi beneficiado com algum subsídio do FGTS?</label>
        </div>

        <div id="form-buyer-calculadora-fgts" class="collapse">
            <hr>
            <strong>Calculadora do FGTS</strong>
            <div class="form-group">
                <label>Salário Mensal</label>
                <input class="form-control" ng-model="$ctrl.fgts_salario" number-format ng-change="$ctrl.calcular_fgts()">
            </div>
            <div class="form-group">
                <label>Mêses Trabalhados</label>
                <input class="form-control" ng-model="$ctrl.meses_trabalhados" number-format="0" ng-change="$ctrl.calcular_fgts()">
            </div>
            <hr>
        </div>

        <div class="form-group form-group-sm">
            <label for="form-buyer-saldo-fgts">Quanto você possui na conta do FGTS? Se você não sabe o valor, acesse a <a href="" data-toggle="collapse" data-target="#form-buyer-calculadora-fgts">calculadora</a>.</label>
            <input id="form-buyer-saldo-fgts" class="form-control" ng-model="$ctrl.negociacao.comprador.fgts" ng-change="$ctrl.validate()" number-format="2">
        </div>

        <div class="form-group form-check" ng-if="$ctrl.remember">
            <input type="checkbox" ng-model="$ctrl.remember_me" ng-change="$ctrl.rememberMe()" class="form-check-input" id="remember-me">
            <label class="form-check-label" for="remember-me">Lembre-se de mim</label>
        </div>
    `,
    bindings: {
        analise: '=',
        negociacao: '=',
        params: '=',
        onChange: '&',
        status: '=',
        remember: '<'
    },
    controller: ['DateUtils', '$filter', 'Publico', function(DateUtils, $filter, Publico){
        var scope = this;
        scope.error = {};

        scope.subsidio = 0;
        scope.refresh_subsidio = function() {
            Publico.subsidio(scope.analise, scope.negociacao.comprador.renda_familiar, scope.negociacao.comprador.mais_de_um).then(res => {
                scope.negociacao.comprador.subsidio = res.data.valor;
                scope.validate();
            });
        }

        scope.fgts_salario = 0;
        scope.meses_trabalhados = 0;

        scope.calcular_fgts = function() {
            scope.negociacao.comprador.fgts = scope.fgts_salario * scope.meses_trabalhados * 0.08;
            scope.validate();
        }

        scope.validate = function(){
            var error = false;

            if(!scope.negociacao.comprador.nome) {
                scope.error.nome = 'Informe o nome.';
                error = true;
            } else scope.error.nome = false;

            if(!scope.negociacao.comprador.cpf) {
                scope.error.cpf = 'Informe o CPF.';
                error = true;
            } else scope.error.cpf = false;

            if(!scope.negociacao.comprador.email) {
                scope.error.email = 'Informe o Email.';
                error = true;
            } else scope.error.email = false;

            if(!scope.negociacao.comprador.telefone) {
                scope.error.telefone = 'Informe o Telefone.';
                error = true;
            } else scope.error.telefone = false;

            if(!scope.negociacao.comprador.renda_familiar) {
                scope.negociacao.comprador.renda_familiar = 0;
                scope.error.renda_familiar = 'Informe a sua Renda Familiar.';
                error = true;
            } else {
                if(scope.params.limite_renda_min && scope.params.limite_renda_min > scope.negociacao.comprador.renda_familiar) {
                    scope.error.renda_familiar = 'A renda minima aceitavel é de ' + $filter('currency')(scope.params.limite_renda_min, 'R$ ');
                    error = true;
                } else if (scope.params.limite_renda_max && scope.params.limite_renda_max < scope.negociacao.comprador.renda_familiar) {
                    scope.error.renda_familiar = 'A renda máxima aceitavel é de ' + $filter('currency')(scope.params.limite_renda_max, 'R$ ');
                    error = true;
                } else {
                    scope.error.renda_familiar = false;
                }
            }

            if(scope.negociacao.comprador.data_nascimento) {
                var data_nascimento = DateUtils.fromString(scope.negociacao.comprador.data_nascimento);
                var idadeMeses = DateUtils.monthsBetween(data_nascimento, new Date());
                var prazo_permitido = (966 - idadeMeses)
                // console.log(prazo_permitido, scope.params.prazo_padrao);
                if(prazo_permitido < scope.params.prazo_padrao) {
                    scope.negociacao.comprador.prazo_financiamento = prazo_permitido;
                } else {
                    scope.negociacao.comprador.prazo_financiamento = scope.params.prazo_padrao
                }
                // console.log(scope.negociacao.comprador.prazo_financiamento);
            }

            scope.status.error = error;
            if(scope.remember) scope.rememberMe();
            if(scope.onChange) scope.onChange();
        }

        scope.remember_me = false;
        scope.rememberMe = function(){
            if(scope.remember_me) {
                localStorage.setItem('buyer', JSON.stringify(scope.negociacao.comprador));
            } else {
                localStorage.removeItem('buyer');
            }
        }

        scope.$onInit = function(){
            scope.status = {};
            
            if(scope.remember == undefined) scope.remember = true;
            if(scope.remember) {
                var savedBuyer = localStorage.getItem('buyer');
                if(savedBuyer) {
                    scope.negociacao.comprador = JSON.parse(savedBuyer);
                    scope.remember_me = true;
                    scope.refresh_subsidio();
                }
            }
            
            scope.validate();
        }
    }]
})