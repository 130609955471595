app.factory('NegociacaoPublico', ['$http', '$url', function($http, $url){
    var factory = {};

    //Iniciar negociacao
    factory.create = function(negociacao) {
        return $http.post($url(['publico', 'negociacao']), negociacao);
    }

    //Buscar
    factory.find = function(negociacao) {
        if(negociacao._id) negociacao = negociacao._id;
        return $http.get($url(['publico', 'negociacao', negociacao]));
    }

    //Atualizar
    factory.update = function(negociacao) {
        return $http.put($url(['publico', 'negociacao']), negociacao);
    }

    //Adicionar Proposta
    factory.proposta = function(negociacao, proposta) {
        if(negociacao._id) negociacao = negociacao._id;
        return $http.post($url(['publico', 'negociacao', negociacao, 'proposta']), proposta);
    }

    return factory;
}]);