app.component('confirmModal', {
    template : //html
    `
        <div id="{{$ctrl.modalId}}" class="modal fade">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        {{$ctrl.header}}
                    </div>
                    <div class="modal-body">
                        {{$ctrl.message}}
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" ng-click="$ctrl.cancel()">{{$ctrl.cancelLabel}}</button>
                        <button class="btn btn-primary" ng-click="$ctrl.confirm()">{{$ctrl.confirmLabel}}</button>
                    </div>
                </div>
            </div>
        </div>
    `,
    bindings : {
        modalId : '@',
        header : '@',
        message : '@',
        onConfirm : '&',
        onCancel : '&',
        confirmLabel : '@',
        cancelLabel : '@'
    },
    controller: [function(){
        var self = this;

        self.cancel = function(){
            $('#'+self.modalId).modal('hide');
            if(self.onCancel) self.onCancel();
        }

        self.confirm = function(){
            $('#'+self.modalId).modal('hide');
            if(self.onConfirm) self.onConfirm();
        }

        self.$onInit = function(){
            //self.id = self.id || "confirm-dialog-" + (Math.random() * 10000);
            self.confirmLabel =  self.confirmLabel || "Sim";
            self.cancelLabel = self.cancelLabel || "Não";
        }
    }]
});