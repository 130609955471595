app.factory('formatador', ['$filter', function($filter){
    var formatador = {};

    //Converter em float
    formatador.parseFloat = function(valor){
        //Remove os pontos '.', depois troca as virgulas por pontos e remove tudo que não for numeral
        return window.parseFloat(valor.replace(/\./g, '').replace(/\,/g, '.').replace(/[^\d\.]/g, ''));
    }

    //Converte em inteiro
    formatador.parseInt = function(valor){
        //Remove os pontos '.', depois troca as virgulas por pontos e remove tudo que não for numeral
        return window.parseInt(valor.replace(/\./g, '').replace(/\,/g, '.').replace(/[^\d\.]/g, ''));
    }

    return formatador;
}]);