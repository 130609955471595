app.controller('dashboard.associativo.analise.ctrl', ['$scope', 'analise', 'Message', 'Empreendimento', '$filter', function($scope, analise, Message, Empreendimento, $filter){
    $scope.analise = analise;
    $scope.plano = analise.plano_associativo;
    $scope.otherPlano = $scope.$parent.other;

    $scope.reload = function() {
        $scope.fluxo = $scope.plano.fluxoTotal();
        $scope.plot.init($scope.fluxo);
        $scope.calcularIndices();
    }

    $scope.custo = function(i){
        return ($scope.fluxo[i].custo_obra || 0.0)
        + ($scope.fluxo[i].bdi || 0.0)
        + ($scope.fluxo[i].valor_executado || 0.0)
        + ($scope.fluxo[i].aporte || 0.0)
        + ($scope.fluxo[i].juros || 0.0)
        + ($scope.fluxo[i].valor_terreno || 0.0)
        + ($scope.fluxo[i].corretagem_terreno || 0.0)
        + ($scope.fluxo[i].manutencao || 0.0)
        + ($scope.fluxo[i].administracao || 0.0)
        + ($scope.fluxo[i].publicidade || 0.0)
        + ($scope.fluxo[i].impostos || 0.0)
        + ($scope.fluxo[i].corretagem_vendas || 0.0)
        + ($scope.fluxo[i].itbi || 0.0);
    }

    $scope.receita = function(i) {
        return ($scope.fluxo[i].receita_comercializacao || 0.0)
        + ($scope.fluxo[i].receita_comercializacao_direta | 0.0)
        + ($scope.fluxo[i].receita_comercializacao_apos_obra || 0.0)
        + ($scope.fluxo[i].ressarciamento_executado || 0.0)
        + ($scope.fluxo[i].ressarciamento_aporte || 0.0)
        + ($scope.fluxo[i].ressarcimento_aporte_terreno || 0.0)
        + ($scope.fluxo[i].apoio_pj || 0.0)
        + ($scope.fluxo[i].terreno || 0.0);
    }

    $scope.receita_liquida = function(i) {
        return ($scope.fluxo[i].receita_comercializacao || 0.0)
        + ($scope.fluxo[i].receita_comercializacao_direta | 0.0)
        + ($scope.fluxo[i].receita_comercializacao_apos_obra || 0.0)
        + ($scope.fluxo[i].ressarciamento_executado || 0.0)
        + ($scope.fluxo[i].ressarciamento_aporte || 0.0)
        + ($scope.fluxo[i].ressarcimento_aporte_terreno || 0.0)
        + ($scope.fluxo[i].apoio_pj || 0.0)
        + ($scope.fluxo[i].terreno || 0.0)
        - ($scope.fluxo[i].impostos || 0.0);
    }

    $scope.saldo = function(i){
        return $scope.receita(i) - $scope.custo(i);
    }

    $scope.calcularIndices = function(){
        var f = [];
        $scope.investimento_maximo = 0;
        $scope.qtd_investimento = 0;

        for(var i = 0; i < $scope.fluxo.length; i++){
            if($scope.fluxo[i]){
                var saldo = $scope.saldo(i);
                var receita = $scope.receita_liquida(i);
                var investimento = 0;
                if(saldo < 0) {
                    investimento = saldo * -1;
                    $scope.qtd_investimento++;
                }

                if(investimento > $scope.investimento_maximo) $scope.investimento_maximo = investimento;
                f.push({ saldo, receita, investimento });
            } else {
                f.push({
                    value: 0,
                    receita: 0,
                    investimento: 0
                });
            }
        }

        $scope.vpl = Empreendimento.vpl($scope.plano.custo_oportunidade / 100, f, 'saldo', true);
        $scope.rpl = Empreendimento.vpl($scope.plano.custo_oportunidade / 100, f, 'receita', true);
        $scope.ipl = Empreendimento.vpl($scope.plano.custo_oportunidade / 100, f, 'investimento', true);
        $scope.ipl_medio = $scope.ipl / f.length;
        $scope.margem_r = $scope.vpl / $scope.rpl * 100;
        $scope.margem_i = $scope.vpl / $scope.ipl * 100;
        $scope.margem_g = $scope.rpl / $scope.ipl * 100;
        $scope.fce = (Math.pow(($scope.margem_i / 100 + 1), (1/$scope.qtd_investimento)) - 1) * 100;
    }

    $scope.tir = function() {
        var f = [];
        for(var i = 0; i < $scope.fluxo.length; i++){
            if($scope.fluxo[i]){
                f.push({
                    value : $scope.saldo(i)
                });
            } else {
                f.push({
                    value : 0
                });
            }
        }

        return Empreendimento.tir(f, 'value');
    }

    $scope.plot = {
        series : ["Faturamento"],
        labels : [],
        data : [],
        init : function(fluxo){
            var max = 0, min = 0;
            
            this.labels = [];
            var series1 = [];

            //Preencher grafico e guardar maior e menor valor
            for(var i = 0; i < fluxo.length; i++){
                this.labels.push("Mês " + i);
                var total = $scope.saldo(i);

                series1.push(total);
                if(total > max) max = total;
                if(total < min) min = total;
            }

            this.data = [];
            this.data.push(series1);
            
            var mult = 1;
            var range = max - min;
            var temp = range;
            while(temp > 10){
                mult *= 10;
                temp = range / mult;
            }
            
            temp = Math.round(temp) * mult;
            var step = temp / 15;

            this.options = {
                tension: 0,
                tooltips : {
                    callbacks : {
                        label : function(tooltipItem){
                            return $filter('currency')(tooltipItem.yLabel, 'R$ ', 2);
                        }
                    }
                },
                scales : {
                    yAxes: [
                        {
                            type: 'linear',
                            display: true,
                            position: 'left',
                            ticks: {
                                maxTicksLimit: 20,
                                stepSize : step,
                                max : max,
                                min : min,
                                callback: function(value, index, values){
                                    return $filter('currency')(value, 'R$ ', 2);
                                }
                            }
                        }
                    ]
                }
            }


        }
    }

    $scope.reload();

}]);