app.controller('home.controller', ['$scope', 'groups','Session', function($scope, groups, Session){
    $scope.groups = groups;
    $scope.myGroups = [];
    $scope.otherGroups = [];
    
    for(var i = 0; i < groups.length; i++) {
        var group = groups[i];
        if(group._owner._id == Session.getCredentials()._id){
            $scope.myGroups.push(group);
        } else {
            $scope.otherGroups.push(group);
        }
    }

}]);