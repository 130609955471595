app.factory('Formulas', [function(){
    var factory = {};

    factory.set = function(analise) {

        if(analise.plano_associativo){
            analise.plano_associativo = this.setPlanoAssociativo(analise.plano_associativo);
        }
       
        if(analise.plano_empresario){
            analise.plano_empresario = this.setPlanoEmpresario(analise.plano_empresario);
        }

        if(analise.plano_direto){
            analise.plano_direto = this.setPlanoDireto(analise.plano_direto);
        }

        if(analise.sbpe) {
            analise.sbpe = this.setAnaliseVendas(analise.sbpe);
        }

        if(analise.mcmv_faixa_15) {
            analise.mcmv_faixa_15 = this.setAnaliseVendas(analise.mcmv_faixa_15);
        }

        if(analise.mcmv_faixa_2) {
            analise.mcmv_faixa_2 = this.setAnaliseVendas(analise.mcmv_faixa_2);
        }

        if(analise.mcmv_faixa_3) {
            analise.mcmv_faixa_3 = this.setAnaliseVendas(analise.mcmv_faixa_3);
        }

        return analise;
    }

    factory.setPlanoDireto = function(plano) {
        return {
            ...plano,

            get custo_previo_terreno(){
                return this.valor_terreno * Math.pow(1 + this.custo_oportunidade / 100, this.mes_terreno);
            },
            
            get area_privativa_total(){
                return (this.area_privativa || 0) * (this.numero_unidades || 0);
            },

            get area_comun(){
                return this.area_privativa_total * ((this.pct_area_comun || 0) / 100);
            },

            get area_equivalente_total(){
                return this.area_privativa_total + this.area_comun;
            },

            get area_privativa_media(){
                return this.area_equivalente_total / (this.numero_unidades || 0);
            },

            get valor_unidade(){
                return this.area_privativa * (this.valor_vendas || 0);
            },

            get unidades_a_venda(){
                return this.numero_unidades - (this.is_permuta_fisica ? this.permuta_fisica : 0);
            },

            get vgv(){
                return this.numero_unidades * this.valor_unidade;
            },
            
            get valor_terreno_precedente(){
                return this.valor_terreno * Math.pow(1 + this.custo_oportunidade / 100, this.mes_terreno);
            },

            get outros_custos_precedentes(){
                return this.outros_custos.reduce((total, custo) => total + (custo.valor * Math.pow(1 + this.custo_oportunidade / 100, custo.mes)), 0);
            },

            get custo_total(){
                return this.area_equivalente_total * this.custo_unitario;
            },
            
            get custo_mensal(){
                return this.custo_total / this.periodo_obra;
            },

            get administracao(){
                return this.custo_mensal * (this.pct_administracao / 100);
            },

            get publicidade(){
                return this.custo_total * (this.pct_publicidade / 100);
            },
            
            get manutencao_futura(){
                return this.custo_total * (this.pct_custo_manutencao / 100);
            },
            
            get pct_permuta_fisica(){
                return this.permuta_fisica / this.numero_unidades * 100;
            },

            get pct_permuta_financeira_liquida(){
                return this.pct_permuta_financeira;
            },
            
            get pct_unidades_investimento(){
                return this.unidades_investimento / this.numero_unidades * 100;
            },

            get pct_investimento_liquido(){
                
                return (this.pct_investimento / 100) /
                (
                    (
                        (this.pct_publicidade / 100)
                        + (this.pct_impostos / 100)
                        + (this.pct_comissao_venda / 100)
                    ) + 1
                ) * 100;
            },

            fluxoVendas: function(){
                var tabelas = this.tabela_vendas.sort((t1, t2) => t2.mes_inicio - t1.mes_inicio);
                var vendas = [...this.vendas];
                vendas.forEach((venda) => {
                    venda.tabela = tabelas.find((tabela) => tabela.mes_inicio <= venda.mes);
                })
                
                var size = this.vendas.reduce((max, venda) => {
                    if(venda.qtd == 0) return max;
                    var maxParcela = (venda.mes + venda.tabela.numero_parcelas + 1);
                    if(maxParcela > max) max = maxParcela;
                    var maxIntercalada = 0;
                    if(venda.tabela.intercaladas) maxIntercalada = (venda.mes + venda.tabela.intercaladas.reduce((max, intercalada) => max > intercalada.mes ? max : intercalada.mes, 0));
                    if(maxIntercalada > max) max = maxIntercalada;

                    return max;
                }, (this.periodo_obra + 4));
                console.log('size', size);

                var fluxo_faturamento = [];
                for(var i = 0; i <= size; i++) fluxo_faturamento.push({
                    sinal: 0,
                    parcela: 0,
                    chaves: 0,
                    intercalada: 0,
                    comissao_venda: 0,
                    get total() {
                        return this.sinal + this.parcela + this.chaves + this.intercalada;
                    }
                });
                console.log(size, fluxo_faturamento)

                var mod_permuta = (!this.is_permuta_fisica ? (1 - (this.pct_permuta_financeira_liquida / 100) ) : 1);
                var mod_investidor = 1; //(!this.is_investidor_fisico ? (1 - (this.pct_investimento_liquido / 100) ) : 1);
                
                //Acumulador de vendas realizadas
                for(var i = 0; i < this.vendas.length; i++) {
                    var venda = this.vendas[i];
                    if(venda.qtd == 0) continue;
    
                    //Sinal
                    fluxo_faturamento[venda.mes].sinal += this.valor_unidade * ((venda.tabela.pct_sinal || 0) / 100) * venda.qtd * mod_permuta * mod_investidor;
                        
                    //Comissao Venda
                    fluxo_faturamento[venda.mes].comissao_venda += this.valor_unidade * venda.qtd * (this.pct_comissao_venda / 100) ;

                    //Parcelas
                    for(var j = 1; j <= venda.tabela.numero_parcelas; j++) {
                        fluxo_faturamento[venda.mes + j].parcela += this.valor_unidade * ((venda.tabela.pct_parcela || 0) / 100) * venda.qtd * mod_permuta * mod_investidor;
                    }
    
                    //Intercaladas
                    for(var it = 0; it < venda.tabela.intercaladas.length; it++) {
                        var intercalada = venda.tabela.intercaladas[it];
                        
                        if(!intercalada || !intercalada.mes) continue;
    
                        var mes_intercalada = intercalada.mes + venda.mes;
                        console.log(mes_intercalada);
                        fluxo_faturamento[mes_intercalada].intercalada += this.valor_unidade * ((intercalada.pct || 0) / 100) * venda.qtd * mod_permuta * mod_investidor;
                    }
    
                    console.log(this.periodo_obra + 2)
                    //Chaves
                    fluxo_faturamento[this.periodo_obra + 3].chaves += this.valor_unidade * ((venda.tabela.pct_chaves || 0) / 100) * venda.qtd * mod_permuta * mod_investidor;
    
                }
    
                return fluxo_faturamento;
            },

            fluxoInvestidor: function(){
                var fluxo = [];
                this.investidor.forEach((investidor) => fluxo[investidor.mes] = investidor.valor);
                return fluxo;
            },

            fluxoTotal: function() {
                var vendas = this.fluxoVendas();
                // var investidor = this.fluxoInvestidor();
    
                var custo_mensal = this.custo_mensal;
                var administracao = this.administracao;
                var publicidade = this.publicidade / 10;
                var manutencao_futura = this.manutencao_futura;
    
                var size = Math.max((this.periodo_obra + 4), vendas.length);
    
                var fluxo_total = [];
                var evolucao_acumulada = 0;
                for(var i = 0; i < size; i++){
                    var mes = {};
                    var total_custos = 0;
                    var total_faturamento = 0;
                    var total_vendas = 0;
                    //No Mês zero
                    if(i == 0){
                        total_custos += mes.valor_terreno = this.valor_terreno_precedente;
    
                        total_custos += mes.comissao_permuta = this.comissao_terreno;
                        total_custos += mes.outros_custos = this.outros_custos_precedentes;
                    } else {
                        //Publicidade por 10 meses
                        if(i <= 10){
                            total_custos += mes.publicidade = publicidade;
                        }
    
                        //Durante a obra
                        if(i <= this.periodo_obra){
                            total_custos += mes.cronograma_obra = this.custo_total * (this.executado[i-1] ? (this.executado[i-1].pct / 100) : 0);
                            evolucao_acumulada += (this.executado[i-1] ? this.executado[i-1].pct : 0);
                            mes.evolucao_acumulada = evolucao_acumulada;
                            total_custos += mes.administracao = administracao;
                        }
                    }
    
                    //3 meses após a obra
                    if(i == (this.periodo_obra + 3)){
                        total_custos += mes.manutencao_futura = manutencao_futura;
                    }
                    
                    //Se teve vendas
                    if(vendas[i]) {
                        mes.vendas = vendas[i];
                        total_vendas += mes.vendas.total;
                        total_custos += mes.comissao_vendas = mes.vendas.comissao_venda;
                    }
    
                    total_faturamento += total_vendas;
    
                    
                    total_custos += mes.impostos = (this.pct_impostos / 100) * total_vendas;
    
                    mes.total_custos = total_custos;
                    mes.total_faturamento = total_faturamento;
                    mes.total = mes.total_faturamento - mes.total_custos;
    
                    fluxo_total.push(mes);
                }
    
                return fluxo_total;
            }
        }

    }

    factory.setPlanoEmpresario = function(plano) {
        if(!plano.fc_det_unidades) plano.fc_det_unidades = [];

        if(plano.fc_det_unidades.length <= 0 && (plano.numero_unidades || plano.area_privativa)) {
            plano.fc_det_unidades = [{
                tipo : 'Tipo A',
                area : plano.area_privativa,
                qtd : plano.numero_unidades
            }]
        }
        
        return {
            ...plano,

            get custo_previo_terreno(){
                return this.valor_terreno * Math.pow(1 + this.custo_oportunidade / 100, this.mes_terreno);
            },

            get area_privativa_total(){
                return this.fc_det_area_total;
            },

            get fc_det_total_unidades() {
                return this.fc_det_unidades.reduce((pv, cv) => pv + cv.qtd, 0);
            },

            get fc_det_area_total() {
                return this.fc_det_unidades.reduce((pv, cv) => pv + (cv.area * cv.qtd), 0);
            },

            get fc_det_valor_total() {
                return this.fc_det_unidades.reduce((pv, cv) => pv + (cv.area * cv.qtd * this.valor_vendas), 0);
            },

            get fc_det_area_media() {
                return this.fc_det_area_total / this.fc_det_total_unidades;
            },

            get area_comun(){
                return this.area_privativa_total * ((this.pct_area_comun || 0) / 100);
            },

            get area_equivalente_total(){
                return this.fc_det_area_total + this.area_comun;
            },

            get area_privativa_media(){
                return this.area_equivalente_total / (this.numero_unidades || 0);
            },

            get valor_unidade(){
                return this.fc_det_area_media * (this.valor_vendas || 0);
            },
            
            get sinal(){
                return this.valor_unidade * (this.pct_sinal || 0) / 100;
            },

            get parcelas(){
                return this.valor_unidade * (this.pct_parcelas || 0) / 100;
            },

            get chaves(){
                return this.valor_unidade * (this.pct_chaves || 0) / 100;
            },
            
            get contrato_pos_obra(){
                return this.valor_unidade * (this.pct_contrato_pos_obra || 0) / 100;
            },

            get entrada_pos_obra(){
                return this.valor_unidade * (this.pct_entrada_pos_obra || 0) / 100;
            },

            get parcelas_pos_obra(){
                return this.valor_unidade * (this.pct_parcelas_pos_obra || 0) / 100;
            },
            
            get unidades_a_venda(){
                return this.fc_det_total_unidades - (this.is_permuta_fisica ? this.permuta_fisica : 0);
            },

            get vgv(){
                return this.fc_det_valor_total;
            },
            
            get valor_terreno_precedente(){
                return this.valor_terreno * Math.pow(1 + this.custo_oportunidade / 100, this.mes_terreno);
            },

            get outros_custos_precedentes(){
                return this.outros_custos.reduce((total, custo) => total + (custo.valor * Math.pow(1 + this.custo_oportunidade / 100, custo.mes)), 0);
            },

            get custo_total(){
                if(this.forma_calculo === 'DIRETO') {
                    return this.fc_dir_custo_total;
                } else {
                    return this.area_equivalente_total * this.custo_unitario;
                }
            },

            get custo_mensal(){
                return this.custo_total / this.periodo_obra;
            },

            get administracao(){
                return this.custo_mensal * (this.pct_administracao / 100);
            },

            get juros_mensal(){
                return (Math.pow((1.0 + (this.pct_juros_ano / 100.0)), (1.0/12.0))) - 1.0
            },

            get financiamento(){
                return (this.pct_financiamento / 100.0) * this.custo_mensal;
            },

            get custo_financiamento(){
                if(this.juros_mensal == 0) return this.financiamento * this.periodo_obra;
                
                var k = -1 + Math.pow(1 + this.juros_mensal, this.periodo_obra);
                return this.financiamento * (k / this.juros_mensal);
            },

            get total_juros() {
                return this.custo_financiamento - (this.financiamento * this.periodo_obra);
            },
            
            get publicidade(){
                return this.custo_total * (this.pct_publicidade / 100);
            },

            get manutencao_futura(){
                return this.custo_total * (this.pct_custo_manutencao / 100);
            },
            
            get pct_permuta_fisica(){
                return this.permuta_fisica / this.numero_unidades * 100;
            },

            get pct_permuta_financeira_liquida(){
                return this.pct_permuta_financeira;
            },
            
            fluxoVendas: function(){
                var fluxo_faturamento = [];
                var mod_permuta = (!this.is_permuta_fisica ? (1 - (this.pct_permuta_financeira_liquida / 100) ) : 1);
                
                var faturamento = function(){
                    this.sinal = 0;
                    this.parcela = 0;
                    this.chaves = 0;
    
                    Object.defineProperty(this, 'total', {
                        get: function(){
                            return this.sinal + this.parcela + this.chaves;
                        }
                    });
                }
                
                //Mes das chaves
                fluxo_faturamento[this.periodo_obra + 3] = new faturamento();
                fluxo_faturamento[this.periodo_obra + 4] = new faturamento();
                fluxo_faturamento[this.periodo_obra + 5] = new faturamento();
                fluxo_faturamento[this.periodo_obra + 6] = new faturamento();
                fluxo_faturamento[this.periodo_obra + 7] = new faturamento();
                fluxo_faturamento[this.periodo_obra + 8] = new faturamento();
    
                //Acumulador de vendas realizadas
                for(var i = 0; i < this.vendas.length; i++) {
                    var venda = this.vendas[i];
                    if(venda.qtd == 0) continue;
    
                    var sinal = this.sinal * venda.qtd * mod_permuta;
                    var parcela = (this.parcelas * venda.qtd) / this.numero_parcelas * mod_permuta;
                    var chaves = this.chaves * venda.qtd * mod_permuta;
    
                    if(!fluxo_faturamento[venda.mes]) 
                        fluxo_faturamento[venda.mes] = new faturamento();
                    fluxo_faturamento[venda.mes].sinal += sinal;
                        
                    //Distribuir Chaves em 6 meses
                    fluxo_faturamento[this.periodo_obra + 3].chaves += (chaves/6);
                    fluxo_faturamento[this.periodo_obra + 4].chaves += (chaves/6);
                    fluxo_faturamento[this.periodo_obra + 5].chaves += (chaves/6);
                    fluxo_faturamento[this.periodo_obra + 6].chaves += (chaves/6);
                    fluxo_faturamento[this.periodo_obra + 7].chaves += (chaves/6);
                    fluxo_faturamento[this.periodo_obra + 8].chaves += (chaves/6);

    
                    for(var j = 1; j <= this.numero_parcelas; j++) {
                        if(!fluxo_faturamento[venda.mes + j]) 
                            fluxo_faturamento[venda.mes + j] = new faturamento();;
                        fluxo_faturamento[venda.mes + j].parcela += parcela;
                    }
    
                }

                return fluxo_faturamento;
            },

            fluxoVendasPosObra: function(){
                var fluxo_faturamento = [];
                var mod_permuta = (!this.is_permuta_fisica ? (1 - (this.pct_permuta_financeira_liquida / 100) ) : 1);
                
                var faturamento = function(){
                    this.contrato = 0;
                    this.entrada = 0;
                    this.parcela = 0;
    
                    Object.defineProperty(this, 'total', {
                        get: function(){
                            return this.contrato + this.entrada + this.parcela;
                        }
                    });
                }
    
                //Acumulador de vendas realizadas
                for(var i = 0; i < this.vendas_pos_obra.length; i++) {
                    var venda = this.vendas_pos_obra[i];
                    if(venda.qtd == 0) continue;
    
                    var contrato = this.contrato_pos_obra * venda.qtd * mod_permuta;
                    var entrada = this.entrada_pos_obra * venda.qtd * mod_permuta;
                    var parcela = (this.parcelas_pos_obra * venda.qtd) / this.numero_parcelas_pos_obra * mod_permuta;
    
                    if(!fluxo_faturamento[venda.mes]) 
                        fluxo_faturamento[venda.mes] = new faturamento();
    
                    fluxo_faturamento[venda.mes].contrato += contrato;
                    fluxo_faturamento[venda.mes].entrada += entrada;
    
                    for(var j = 1; j <= this.numero_parcelas_pos_obra; j++) {
                        if(!fluxo_faturamento[venda.mes + j]) 
                            fluxo_faturamento[venda.mes + j] = new faturamento();;
                        fluxo_faturamento[venda.mes + j].parcela += parcela;
                    }
                }
    
                return fluxo_faturamento;
            },

            fluxoTotal: function() {
                var vendas = this.fluxoVendas();
                var vendas_pos_obra = this.fluxoVendasPosObra();
    
                var financiamento = this.financiamento;
                var custo_financiamento = this.custo_financiamento;
                var custo_mensal = this.custo_mensal;
                var administracao = this.administracao;
                var publicidade = this.publicidade / 10;
                var manutencao_futura = this.manutencao_futura;
    
                var size = Math.max((this.periodo_obra + 4), vendas.length, vendas_pos_obra.length);
    
                var fluxo_total = [];
                var evolucao_acumulada = 0;
                for(var i = 0; i < size; i++){
                    var mes = {};
                    var total_custos = 0;
                    var total_faturamento = 0;
                    var total_vendas = 0;
                    //No Mês zero
                    if(i == 0){
                        total_custos += mes.valor_terreno = this.valor_terreno_precedente;
    
                        total_custos += mes.comissao_permuta = this.comissao_terreno;
                        total_custos += mes.outros_custos = this.outros_custos_precedentes;
                    } else {
                        //Publicidade por 10 meses
                        if(i <= 10){
                            total_custos += mes.publicidade = publicidade;
                        }
    
                        //Durante a obra
                        if(i <= this.periodo_obra){
                            total_custos += mes.cronograma_obra = this.custo_total * (this.executado[i-1] ? (this.executado[i-1].pct / 100) : 0);
                            evolucao_acumulada += (this.executado[i-1] ? this.executado[i-1].pct : 0);
                            mes.evolucao_acumulada = evolucao_acumulada;
                            total_faturamento += mes.financiamento = financiamento;
                            total_custos += mes.administracao = administracao;
                        }
                    }
    
                    //3 meses após a obra
                    if(i >= (this.periodo_obra + 3) && i <= (this.periodo_obra + 8)){
                        total_custos += mes.manutencao_futura = (manutencao_futura / 6);
                        total_custos += mes.custo_financiamento = (custo_financiamento / 6);
                    }
                    
                    //Se teve vendas
                    if(vendas[i]) {
                        mes.vendas = vendas[i];
                        total_vendas += mes.vendas.total;
                    }
    
                    //Se teve vendas após a obra
                    if(vendas_pos_obra[i]) {
                        mes.vendas_pos_obra = vendas_pos_obra[i];
                        total_vendas += mes.vendas_pos_obra.total;
                    }
    
                    total_faturamento += total_vendas;
    
                    total_custos += mes.comissao_vendas = (this.pct_comissao_venda / 100) * total_vendas;
                    total_custos += mes.impostos = (this.pct_impostos / 100) * total_vendas;
    
                    mes.total_custos = total_custos;
                    mes.total_faturamento = total_faturamento;
                    mes.total = mes.total_faturamento - mes.total_custos;
    
                    fluxo_total.push(mes);
                }
    
                return fluxo_total;
            }
    
        }
    }

    factory.setPlanoAssociativo = function(plano) {
        return {
            ...plano,
            
            get custo_previo_terreno() {
                return this.valor_compra_terreno * Math.pow(1 + this.custo_oportunidade / 100, this.mes_terreno);
            },

            get custo_previo_obras() {
                var ttl = 0;
                for(var i = 0; i < this.outros_custos.length; i++){
                    var custo = this.outros_custos[i];
                    ttl += custo.valor * Math.pow(1 + this.custo_oportunidade / 100, custo.mes);
                }
                return ttl;
            },

            get total_unidades() {
                return this.unidades.reduce((total, unidade) => total + unidade.qtd, 0);
            },

            get area_privativa_total() {
                return this.unidades.reduce((total, unidade) => total + (unidade.area * unidade.qtd), 0);
            },

            get unidades_a_venda() {
                return this.total_unidades - this.unidades_comercializadas - (this.is_permuta_fisica ? this.permuta_fisica : 0);
            },

            get area_privativa_media() {
                return this.area_privativa_total / this.total_unidades;
            },

            get custo_obra() {
                return this.cub * this.area_privativa_total;
            },

            get valor_bdi() {
                return (this.pct_bdi / 100) * this.custo_obra;
            },

            get custo_obra_bdi() {
                return this.custo_obra;
            },

            get custo_obra_executado() {
                return this.custo_obra_bdi * (this.pct_obra_executada / 100)
            },

            get vgv_total() {
                return this.area_privativa_total * this.valor_avaliacao;
            },

            get vgv_medio() {
                return this.area_privativa_media * this.valor_avaliacao;
            },

            get gestao_custo() {
                return this.custo_obra_executado
                + (this.pct_previo_vendas / 100 * (this.vgv_total + (this.vgv_medio * this.total_permuta) ))
                + this.aporte_financeiro
                + this.apoio_producao_efetivo
                + this.custo_previo_obras;
            },

            get valor_cumprir_segunda_exigencia(){
                return this.vgv_medio * this.unidades_comercializadas;
            },

            get apoio_producao() {
                return this.custo_obra_bdi - this.valor_cumprir_segunda_exigencia;
            },

            get quota_terreno() {
                return this.valor_avaliacao_terreno / this.total_unidades;
            },

            get diferenca_cumprimento_exigencias(){
                return this.custo_obra_bdi
                - this.valor_cumprir_segunda_exigencia
                - this.custo_obra_executado
                - this.aporte_financeiro
                - this.apoio_producao_efetivo
                - this.custo_previo_obras;
            },

            get despesa_comercializacao(){
                return (this.pct_corretagem_vendas / 100) * this.vgv_total;
            },

            get despesas_financeiras() {
                return (this.pct_despesas_financeiras / 100) * this.vgv_total;
            },

            get obra_executada() {
                return (this.pct_obra_executada / 100) * this.custo_obra_bdi;
            },

            get custo_total_empreendimento() {
                return this.despesa_comercializacao
                + this.despesas_financeiras
                + this.valor_compra_terreno
                + this.custo_obra_bdi
            },

            get valor_custo_obra() {
                return this.custo_obra_bdi - this.obra_executada;
            },

            get valor_medio_fracoes() {
                return this.valor_avaliacao_terreno / this.total_unidades;
            },

            get valor_medio_custo_obra() {
                return this.custo_obra_bdi / this.total_unidades;
            },

            get pct_juros_efetivo() {
                return (Math.pow(((this.pct_juros_pj / 1200.0) + 1.0), 12) - 1.0) * 100;
            },

            get unidades_comercializadas() {
                return Math.ceil((this.pct_total_unidades_comercializadas / 100) * this.total_unidades);
            },

            get valor_comercializacao_unidades() {
                return this.unidades_comercializadas * this.vgv_medio;
            },

            get valor_fracao_terreno_unidades(){
                return this.unidades_comercializadas * this.valor_medio_fracoes;
            },

            get saldo_mutuario_pf() {
                return  (this.pct_saldo_mutuario / 100) * this.valor_comercializacao_unidades;
            },

            get valor_financeiro_pj_pretendido() {
                return  this.custo_obra_bdi - ((this.valor_comercializacao_unidades - this.saldo_mutuario_pf) + this.aporte_financeiro + (this.utiliza_fracao_ideal ? this.valor_fracao_terreno_unidades : 0) + this.obra_executada);
            },

            get pct_permuta_fisica(){
                return this.permuta_fisica / this.total_unidades * 100;
            },

            get pct_permuta_financeira_liquida(){
                return this.pct_permuta_financeira;
            },

            get total_vendas_na_obra(){
                var total = 0;
                for(var i = 0; i < this.vendas_durante_obra.length; i++) {
                    total += this.vendas_durante_obra[i].qtd;
                }
    
                return total;
            },

            get total_vendas_apos_obra(){
                return this.vendas_pos_obra.reduce((total, vendas) => total + vendas.qtd, 0);
            },
            
            get juros(){
                return this.fluxoCaixa().reduce((total, caixa) => total + caixa.juros, 0);
            },

            get fluxo_caixa_valor_repasse() {
                return this.vgv_medio * (1 - (this.pct_saldo_mutuario / 100)) * this.unidades_comercializadas;
            },

            get fluxo_caixa_valor_obra_executada() {
                return (this.pct_obra_executada / 100 ) * this.custo_obra_bdi;
            },

            get fluxo_caixa_total_formacao_demanda(){
                return this.fluxo_caixa_valor_repasse + this.fluxo_caixa_valor_obra_executada + this.aporte_financeiro;
            },

            get fluxo_caixa_apoio_producao_maximo(){
                return this.valor_financeiro_pj_pretendido;
            },

            get fluxo_caixa_aporte_terreno() {
                return (this.utiliza_fracao_ideal ? ((this.unidades_comercializadas / this.total_unidades) * this.valor_avaliacao_terreno) : 0);
            },

            fluxoCaixa: function() {
                var fluxo = [];
                
                var vendas_acumuladas = this.unidades_comercializadas;
                var executado_acumulado = this.pct_obra_executada;
                var taxa_juros_mensal = -1 + Math.pow((1 + this.pct_juros_pj/100), (1/12));

                var lastMes = null;
                for(var i = 0; i < this.prazo_obra; i++) {
                    var header = `Mês ${(i+1)}`;

                    var dadosMes = this.vendas_durante_obra.find((dadosMes) => dadosMes.mes === (i+1));
                    
                    var vendas_mes = (dadosMes ? dadosMes.qtd : 0);
                    vendas_acumuladas += vendas_mes;

                    var executado_mes = (dadosMes ? dadosMes.executado : 0);
                    executado_acumulado += executado_mes;
                    
                    var base_calculo = this.vgv_medio * (1 - (this.pct_saldo_mutuario / 100));

                    var pct_executado_mes = (dadosMes.executado > 0) ? ((i === 0 ? executado_acumulado : dadosMes.executado) / 100) : 0;
                    
                    var custo_obra_bdi = this.custo_obra_bdi * pct_executado_mes;

                    var receita_comercializacao_anterior = (this.fluxo_caixa_valor_repasse * pct_executado_mes);
                    var receita_comercializacao_inicial = (vendas_mes * (executado_acumulado / 100) * base_calculo)
                    var receita_comercializacao = ((vendas_acumuladas - this.unidades_comercializadas - vendas_mes)  * (executado_mes / 100) * base_calculo);
                    var receita = receita_comercializacao_anterior + receita_comercializacao_inicial + receita_comercializacao;


                    var devolucao_execucao = this.fluxo_caixa_valor_obra_executada * pct_executado_mes;
                    var aporte_empresa = pct_executado_mes * this.aporte_financeiro;
                    var aporte_terreno = pct_executado_mes * this.fluxo_caixa_aporte_terreno;

                    var receitas = receita + devolucao_execucao + aporte_empresa + aporte_terreno;

                    var saldo = receitas - custo_obra_bdi;
                    var saldo_acumulado = (lastMes ? lastMes.saldo_acumulado : 0) + saldo;


                    var apoio_producao = this.fluxo_caixa_apoio_producao_maximo * pct_executado_mes;
                    var apoio_producao_real = ((saldo < 0 && -saldo <= apoio_producao) ? -saldo : apoio_producao) * (saldo > 0 ? 0 : 1);
                    var juros = ((lastMes ? lastMes.apoio_producao_acumulado : 0)) * taxa_juros_mensal;
                    var apoio_producao_acumulado = (lastMes ? lastMes.apoio_producao_acumulado : 0) + apoio_producao_real + juros;

                    var pagamento_apoio_producao = 0;

                    if(saldo_acumulado > 0 && apoio_producao_acumulado > 0) {
                        pagamento_apoio_producao = apoio_producao_acumulado;
                        apoio_producao_acumulado -= saldo_acumulado;
                        if(apoio_producao_acumulado < 0) {
                            saldo_acumulado = apoio_producao_acumulado * -1;
                            apoio_producao_acumulado = 0;
                        } else {
                            pagamento_apoio_producao = saldo_acumulado;
                            saldo_acumulado = 0;
                        }
                    }

                    lastMes = {
                        lastMes, header, base_calculo,
                        
                        vendas_mes, vendas_acumuladas,

                        executado_mes, executado_acumulado,

                        custo_obra_bdi,

                        //Receita comercialização
                        receita_comercializacao_anterior,
                        receita_comercializacao_inicial,
                        receita_comercializacao,
                        receita,

                        //Outras receitas
                        devolucao_execucao,
                        aporte_empresa,
                        aporte_terreno,

                        receitas, //Total de receitas

                        saldo, saldo_acumulado,

                        //Apoio a Produção
                        apoio_producao, apoio_producao_real,
                        juros,

                        apoio_producao_acumulado,
                        pagamento_apoio_producao
                    }

                    fluxo.push(lastMes);
                }

                return fluxo;
            },
    
            fluxoPoupanca: function() {
                var valor_unitario = this.vgv_medio * (this.pct_saldo_mutuario / 100);
                var fluxo = [];
                
                var valor_antes = (this.unidades_comercializadas * valor_unitario) / this.prazo_financiamento;
    
                for(var i = 0; i < this.prazo_financiamento; i++) {
                    if(!fluxo[i]) fluxo[i] = 0;
                    fluxo[i] += valor_antes;
                }
    
                for(var i = 0; i < this.vendas_durante_obra.length; i++) {
                    var venda = this.vendas_durante_obra[i];
                    var valor = (venda.qtd * valor_unitario) / this.prazo_financiamento;
                    
                    for(var j = 0; j < this.prazo_financiamento; j++) {
                        var index = venda.mes + j - 1;
                        
                        if(!fluxo[index]) fluxo[index] = 0;
                        fluxo[index] += valor;
                    }
                }
    
                for(var i = 0; i < fluxo.length; i++) {
                    if(!fluxo[i]) fluxo[i] = 0;
                }
    
                return fluxo;
            },
    
            fluxoPosObra: function() {
                var fluxo = [];
    
                
                for(var i = 0; i < this.vendas_pos_obra.length; i++) {
                    var venda = this.vendas_pos_obra[i];
    
                    var contrato = venda.qtd * this.vgv_medio * (this.pct_no_contrato / 100);
                    var entrada = venda.qtd * this.vgv_medio * (this.pct_de_entrada / 100);
                    var parcelas = (venda.qtd * this.vgv_medio * (this.pct_mensais / 100)) / this.prazo_financiamento_pos_obra;
    
                    if(!fluxo[venda.mes - 1]) fluxo[venda.mes - 1] = 0;
                    fluxo[venda.mes - 1] += contrato + entrada;
                    
                    for(var j = 0; j < this.prazo_financiamento_pos_obra; j++) {
                        if(!fluxo[venda.mes + j]) fluxo[venda.mes + j] = 0;
                        fluxo[venda.mes + j] += parcelas;
                    }
                }
    
                for(var i = 0; i < fluxo.length; i++) {
                    if(!fluxo[i]) fluxo[i] = 0;
                }
    
                return fluxo;
            },

            fluxoCotaTerreno: function() {
                var valor_cota =  this.valor_avaliacao_terreno / this.total_unidades;
                var fluxo = [];
                
                this.vendas_durante_obra.forEach(venda => {
                    fluxo[venda.mes - 1] = venda.qtd * valor_cota;
                });

                console.log('fluxo cota terreno', valor_cota, fluxo);
                return fluxo;
            },

            fluxoItbiRegistro: function(){
                var valor_itbi = this.vgv_medio * (this.pct_itbi_registro / 100);
                var fluxo = [];
                
                this.vendas_durante_obra.forEach(venda => {
                    fluxo[venda.mes - 1] = venda.qtd * valor_itbi
                });

                this.vendas_pos_obra.forEach(venda => {
                    fluxo[venda.mes - 1] = venda.qtd * valor_itbi;
                }); 
                return fluxo;
            },
    
            fluxoTotal: function(){
                var fluxo = [];
                var fluxo_caixa = this.fluxoCaixa();
                var fluxo_poupanca = this.fluxoPoupanca();
                var fluxo_pos_obra = this.fluxoPosObra();
                var fluxo_cota_terreno = this.fluxoCotaTerreno();
                var fluxo_itbi = this.fluxoItbiRegistro();

                var reducao_permuta_financeira = ((this.is_permuta_fisica === false) ? (1 - (this.pct_permuta_financeira_liquida / 100) ) : 1);
                console.log('permuta financeira', reducao_permuta_financeira, this.pct_permuta_financeira, this.pct_permuta_financeira_liquida);


                //Primeiro Mês
                var mes_zero = {};

                mes_zero.mes = 0;
                mes_zero.valor_executado = this.custo_obra_executado;
                mes_zero.aporte = this.aporte_financeiro;
    
                
                mes_zero.valor_terreno = (this.is_permuta_fisica === null) ? this.custo_previo_terreno : 0;
                mes_zero.corretagem_terreno = this.custo_previo_obras;
                mes_zero.outros_custos = this.custo_previo_obras;
                mes_zero.terreno = (this.utiliza_fracao_ideal ? 0 : this.valor_avaliacao_terreno * (this.unidades_comercializadas / this.total_unidades) );
                mes_zero.impostos = (this.pct_impostos / 100) * this.vgv_medio * this.unidades_comercializadas;
                mes_zero.corretagem_vendas = (this.pct_corretagem_vendas / 100) * this.vgv_medio * this.unidades_comercializadas;
                mes_zero.itbi = (this.pct_itbi_registro / 100) * this.vgv_medio * this.unidades_comercializadas;

                fluxo.push(mes_zero);
            
                var total_pj_juros = 0;
    
                var size = Math.max(this.prazo_obra, fluxo_poupanca.length, fluxo_cota_terreno.length, fluxo_pos_obra.length, fluxo_itbi.length);
                for(var i = 0; i < size; i++) {
                    var este = {};
                    
                    
                    //Index
                    este.mes = i + 1;
                    
                    //Sobre o fluxo de caixa
                    if(i < fluxo_caixa.length) {
                        var caixa = fluxo_caixa[i];
                        //Soma pj para o juros
                        total_pj_juros = caixa.apoio_producao_acumulado;
     
                        este.custo_obra = caixa.custo_obra_bdi;
                        este.receita_comercializacao = caixa.receita * reducao_permuta_financeira;
                        este.apoio_pj = caixa.apoio_producao_real;
                        este.ressarciamento_executado = caixa.devolucao_execucao;
                        este.ressarciamento_aporte = caixa.aporte_empresa;
                        este.ressarciamento_aporte_terreno = caixa.aporte_terreno;
                        este.impostos = (this.pct_impostos / 100) * this.vgv_medio * caixa.vendas_mes;
                        este.corretagem_vendas = (this.pct_corretagem_vendas / 100) * this.vgv_medio * caixa.vendas_mes;
                        este.itbi = (this.pct_itbi_registro / 100) * this.vgv_medio * caixa.vendas_mes;
                        este.juros = caixa.pagamento_apoio_producao;
                    }

                    if(i < fluxo_cota_terreno.length) {
                        este.terreno = fluxo_cota_terreno[i];
                    }
    
                    if(i == fluxo_caixa.length) {
                        este.manutencao = (this.custo_obra_bdi * this.pct_manutencao_futura / 100);
                    }
    
                    if(i < fluxo_poupanca.length) {
                        este.receita_comercializacao_direta = fluxo_poupanca[i] * reducao_permuta_financeira;
                    }
    
                    if(i < fluxo_pos_obra.length) {
                        este.receita_comercializacao_apos_obra = fluxo_pos_obra[i] * reducao_permuta_financeira;
                    }
                    
                    //Durante a obra
                    if(i <= this.prazo_obra) {
                        este.administracao = (este.custo_obra * this.pct_administracao / 100);
                    }
                    
                    //Publicidade
                    if(i < 4)
                        este.publicidade = (this.pct_publicidade / 100) * (this.vgv_total / 4);
                    
                    fluxo.push(este);
                }
            
                //Calcular o Juros
                var parcelaJuros = total_pj_juros / 24;
    
                for(var i = 0; i < 24; i++) {
                    var pos = this.prazo_obra + 1 + i;
                    if(!fluxo[pos]) fluxo[pos] = {
                        mes: this.prazo_obra + 2 + i
                    };
    
                    fluxo[pos].juros = parcelaJuros;
                }
            
                return fluxo;
            }
        }

    }

    factory.setAnaliseVendas = function(vendas) {
        const monthsBetween = function(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth() + 1;
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }
        
        return {
            ...vendas,

            get mes_chaves(){
                if(!this.data_chaves) return 0;
                var tokens = this.data_chaves.split('/');
                var date_target = new Date(tokens[2], tokens[1], tokens[0]);
                return monthsBetween(new Date(), date_target);
            },

            parcelas_pa: function(proposta) {
                var a1 = proposta.valor_parcela * 2 / (proposta.numero_parcelas + 1);
                var razao = a1/ (proposta.numero_parcelas);
    
                var pa = [];
                var a = a1;
                for(var i = 0; i < proposta.numero_parcelas; i++){
                    pa.push(a);
                    a -= razao;
                }
                
                return pa;
            },
            
            total: function(proposta) {
                var fJuros = valor => valor;
                if(this.is_juros_simples === true) {
                    fJuros = (valor, mes, pct) => valor + (valor * (pct / 100) * mes);
                } else if(this.is_juros_simples === false) {
                    fJuros = (valor, mes, pct) => (valor * Math.pow((1 + (pct / 100)), mes));
                }
    
    
                var total = proposta.sinal;
                total += proposta.subsidio || 0;
                total += proposta.fgts || 0;
                // total -= proposta.desconto || 0;
                
                if(proposta.intercaladas){
                    for(var i = 0; i < proposta.intercaladas.length; i++) {
                        if(proposta.intercaladas[i]) {
                            var intercalada = proposta.intercaladas[i].valor
                            var mes = proposta.intercaladas[i].mes;
                            if(mes > this.mes_chaves) {
                                intercalada = fJuros(intercalada, mes - this.mes_chaves, this.pct_juros)
                            }
                            total += intercalada;
                        }
                    }
                }
                
                if(this.parcela_decrescente) {
                    var pa = this.parcelas_pa(proposta);
                    for(var i = 0; i < pa.length; i++) {
                        var mes = i+1;
                        var parcela = pa[i];
    
                        if(mes > this.mes_chaves) {
                            parcela = fJuros(parcela, mes - this.mes_chaves, this.pct_juros)
                        }
    
                        total += parcela;
                    }
                } else {
                    for(var i = 0; i < proposta.numero_parcelas; i++) {
                        var parcela = proposta.valor_parcela;
                        var mes = i + 1;
                        if(mes > this.mes_chaves) {
                            parcela = fJuros(parcela, mes - this.mes_chaves, this.pct_juros)
                        }
                        total += parcela;
                    }
                }
    
                
                total += proposta.chaves;
    
                return total;
            },

            vpl: function(proposta){
    
                var fJuros = valor => valor;
                if(this.is_juros_simples === true) {
                    fJuros = (valor, mes, pct) => valor + (valor * (pct / 100) * mes);
                } else if(this.is_juros_simples === false) {
                    fJuros = (valor, mes, pct) => (valor * Math.pow((1 + (pct / 100)), mes));
                }
    
                var vpl = proposta.sinal;
                vpl += proposta.subsidio || 0;
                vpl += proposta.fgts || 0;
                // vpl -= proposta.desconto || 0;
    
                if(proposta.intercaladas) {
                    for(var i = 0; i < proposta.intercaladas.length; i++) {
                        if(proposta.intercaladas[i]) {
        
                            var intercalada = proposta.intercaladas[i].valor
                            var mes = proposta.intercaladas[i].mes;
                            if(mes > this.mes_chaves) {
                                intercalada = fJuros(intercalada, mes - this.mes_chaves, this.pct_juros)
                            }
                            vpl += intercalada / Math.pow(1 + (this.custo_oportunidade / 100), (mes));
                        }
                    }
                }
    
                if(this.parcela_decrescente) {
                    var pa = this.parcelas_pa(proposta);
                    for(var i = 0; i < pa.length; i++) {
                        var mes = i+1;
                        var parcela = pa[i];
    
                        if(mes > this.mes_chaves) {
                            parcela = fJuros(parcela, mes - this.mes_chaves, this.pct_juros)
                        }
    
                        vpl += parcela / Math.pow(1 + (this.custo_oportunidade / 100), mes);
                    }
                } else {
                    for(var i = 0; i < proposta.numero_parcelas; i++){
                        var parcela = proposta.valor_parcela;
                        
                        var mes = i + 1;
                        if(mes > this.mes_chaves) {
                            parcela = fJuros(parcela, mes - this.mes_chaves, this.pct_juros)
                        }
        
                        vpl += parcela / Math.pow(1 + (this.custo_oportunidade / 100), mes);
                    }
                }
                
                vpl += proposta.chaves / Math.pow(1 + (this.custo_oportunidade / 100), this.mes_chaves);
                
                return vpl;
            },
    
            pago_durante_obra: function(proposta){
                var valor = proposta.sinal + (proposta.fgts || 0) + (proposta.subsidio || 0);
                
                var qtd_parcelas = Math.min(this.mes_chaves, proposta.numero_parcelas);
                valor += qtd_parcelas * proposta.valor_parcela;
        
                for(var i = 0; i < proposta.intercaladas.length; i++){
                    if(proposta.intercaladas[i].mes <= this.mes_chaves) {
                        valor += proposta.intercaladas[i].valor;
                    }
                }
        
                return valor;
            },
    
            minimo_durante_obras: function(total_imovel){
                return (total_imovel * this.pct_minimo_pagamento_durente_obra / 100);
            },
    
            valor_desconto_maximo: function(total_imovel) {
                return (total_imovel * this.desconto_maximo / 100);
            },
        }

    }

    return factory;
}]);