app.factory('DateUtils', [function(){
    var dateUtils = {}
    
    dateUtils.fromString = function(str) {
        var tokens = str.split('/');
        return new Date(tokens[2], tokens[1], tokens[0]);
    }

    dateUtils.yearsBetween = function(d1, d2){
        return (d2.getFullYear() - d1.getFullYear());
    };
    dateUtils.monthsBetween = function(d1, d2){
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth() + 1;
        months += d2.getMonth() + 1;
        return months <= 0 ? 0 : months;
    }

    return dateUtils;
}]);