app.directive('fixedColumn', [function(){
    return {
        restrict: 'A',
        link : function($scope, $elem, $attr){
            let fixed_column = parseInt($attr.fixedColumn);
            console.log(fixed_column);

            let parent = $elem.parent();
            parent.css({
                'position' : 'relative'
            });

            let header = $('<table>');
            header.addClass($elem.attr('class'));
            header.css({
                'position' : 'absolute',
                'top' : '0',
                'width' : $elem.width() + 'px',
                'pointer-events': 'none'
            });
            parent.append(header);

            let header_body = $('<tbody>');
            header.append(header_body);

            let rows = $('tbody tr', $elem);
            
            for(let r = 0; r < rows.length; r++){
                let row = $(rows[r]);
                let header_row = $('<tr>');
                header_row.addClass(row.attr('class'));
                header_row.attr('style', row.attr('style'));

                header_body.append(header_row);

                let cells = row.children();
                for(let c = 0; c < cells.length; c++){
                    if(c <= fixed_column){
                        let cell = $(cells[c]);

                        let header_cell = $('<' + cell.prop('tagName') + '>');
                        header_cell.addClass(cell.attr('class'));
                        header_cell.attr('style', cell.attr('style'));
                        header_cell.append(cell.text());

                        header_row.append(header_cell);
                    }
                }
            }

        }
    }
}]);