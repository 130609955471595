app.controller('tabela-venda.empresario.analise.ctrl', ['$scope', 'analise', 'Message', function($scope, analise, Message){
    $scope.analise = analise;
    $scope.plano = analise.plano_empresario;

    $scope.pctTotal = function() {
        return $scope.plano.pct_sinal + $scope.plano.pct_parcelas + $scope.plano.pct_chaves;
    }
    
    $scope.pctTotalPosObra = function() {
        return $scope.plano.pct_contrato_pos_obra + $scope.plano.pct_entrada_pos_obra + $scope.plano.pct_parcelas_pos_obra;
    }


    $scope.totalVendas = function() {
        var sum = 0;
        for(var i in $scope.plano.vendas){
            sum += $scope.plano.vendas[i].qtd;
        }
        return sum;
    }

    $scope.totalVendasPosObra = function() {
        var sum = 0;
        for(var i in $scope.plano.vendas_pos_obra){
            sum += $scope.plano.vendas_pos_obra[i].qtd;
        }
        return sum;
    }

    $scope.refreshVendas = function(){
        var tabela;
        var size = $scope.plano.periodo_obra;

        if(!$scope.plano.vendas) tabela = [];
        else tabela = angular.copy($scope.plano.vendas);
       
        if(tabela.length > size) {
            tabela = tabela.splice(0, size);
        } else while(tabela.length < size) {
            tabela.push({
                mes: tabela.length + 1, 
                qtd: 0
            });
        }

        $scope.plano.vendas = tabela; 

        for(var i = 0; i < $scope.plano.vendas_pos_obra.length; i++) {
            $scope.plano.vendas_pos_obra[i].mes = $scope.plano.periodo_obra + i + 1;
        }
    }
    $scope.refreshVendas();
    
    $scope.addVendaPosObra = function(){
        if(!$scope.plano.vendas_pos_obra) 
            $scope.plano.vendas_pos_obra = [];

        $scope.plano.vendas_pos_obra.push({
            mes: $scope.plano.periodo_obra + $scope.plano.vendas_pos_obra.length + 1,
            qtd: 0
        });
    }
    $scope.removeVendaPosObra = function(){
        if(!$scope.plano.vendas_pos_obra) 
            $scope.plano.vendas_pos_obra = [];

        $scope.plano.vendas_pos_obra.pop();
    }

    $scope.verificar = function() {
        Message.clear();
        if(($scope.totalVendas() + $scope.totalVendasPosObra()) > ($scope.plano.unidades_a_venda)) {
            Message.showWarning('O total de unidades vendidas é maior que a quantidade de unidades a venda', '', 20000)
        }
    }
    
}]);