app.controller('tabela-vendas.associativo.analise.ctrl', ['$scope', 'analise', 'Message', function($scope, analise, Message){
   //dados para trabalho
    $scope.analise = analise;
    $scope.plano = analise.plano_associativo;

    if(!$scope.plano.prazo_financiamento)
        $scope.plano.prazo_financiamento = $scope.plano.prazo_obra;

    $scope.refreshVendas = function(){

        var tabela;
        var size = $scope.plano.prazo_obra;

        if(!$scope.plano.vendas_durante_obra) tabela = [];
        else tabela = angular.copy($scope.plano.vendas_durante_obra);
       
        if(tabela.length > size) {
            tabela = tabela.splice(0, size);
        } else while(tabela.length < size) {
            tabela.push({
                mes: tabela.length + 1, 
                qtd: 0
            });
        }

        $scope.plano.vendas_durante_obra = tabela; 
    }

    $scope.totalVendas = function() {
        var total = 0;
        for(var i = 0; i < $scope.plano.vendas_durante_obra.length; i++) {
            total += $scope.plano.vendas_durante_obra[i].qtd;
        }

        return total;
    }

    $scope.refreshVendas();

    $scope.refreshPosVendas = function(){

        var tabela;
        var size = $scope.plano.prazo_financiamento_pos_obra;

        if(!$scope.plano.vendas_pos_obra) tabela = [];
        else tabela = angular.copy($scope.plano.vendas_pos_obra);
       
        if(tabela.length > size) {
            tabela.splice(size);
        } else while(tabela.length < size) {
        
            tabela.push({
                mes: $scope.plano.prazo_obra + tabela.length + 1, 
                qtd: 0
            });
        }

        $scope.plano.vendas_pos_obra = tabela; 
    }

    $scope.totalVendasPosObras = function() {
        var total = 0;
        for(var i = 0; i < $scope.plano.vendas_pos_obra.length; i++) {
            total += $scope.plano.vendas_pos_obra[i].qtd;
        }

        return total;
    }

    $scope.refreshPosVendas();

    $scope.verificar = function() {
        Message.clear();
        if(($scope.totalVendas() + $scope.totalVendasPosObras()) > ($scope.plano.unidades_a_venda)) {
            Message.showWarning('O total de unidades vendidas é maior que a quantidade de unidades a venda', '', 20000)
        }
    }

    $scope.pctTotal = function(){
        return $scope.plano.pct_no_contrato
        + $scope.plano.pct_de_entrada 
        + $scope.plano.pct_mensais;
    }
}]);