app.factory('Estado', ['$http', '$url', function($http, $url){
    var estado = {};

    estado.list = function(){
        return $http.get($url(['estado']));
    }

    estado.municipios = function(estado){
        var estadoId = estado._id ? estado._id : estado;
        return $http.get($url(['estado', estadoId, 'municipios']));
    }
    
    return estado;
}]);