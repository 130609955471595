app.factory('Empreendimento', ['$http', function($http){
    var empreendimento = {};

    empreendimento.list = function(offset, limit){
        return $http.get('/empreendimento?offset=' +offset + '&limit=' + limit);
    }

    empreendimento.findById = function(id, plano, callback){
        var url = '/empreendimento/' + id + '/' + plano;
        return $http.get(url);
    }

    empreendimento.fluxoCaixa = function(id, plano){
        return $http.get('/empreendimento/' + id + '/' + plano + '/fluxo-caixa');
    }

    empreendimento.fluxoTotal = function(id, plano){
        return $http.get('/empreendimento/' + id + '/' + plano + '/fluxo-total');
    }

    empreendimento.create = function(empreendimento){
        return $http.post('/empreendimento', empreendimento);
    }

    empreendimento.update = function(t){
        t = angular.copy(t);
        return $http.put('/empreendimento/'+t.tipo_analise, t);
    }

    empreendimento.delete = function(){
        return $http.delete('/empreendimento/' +  empreendimento._id);
    }

    empreendimento.vpl = function(taxa, values, attr, print){
        let vpl = 0;

        for(let i in values){
            var v = values[i];
            if(attr) v = v[attr];
            
            var t = (v / Math.pow(1 + taxa, i))

            // if(print) console.log('@' + i + ' valor: ' + v + ', vpl: ' + t);
            vpl += t;
        }

        // if(print) console.log('VPL = ' + vpl);

        return vpl;
    },

    empreendimento.tir = function(values, attr){

        const ITERATIONS = 100;
        const PRECISION = 0.001;
        const LOW_RATE = 0.000001;
        const HIGH_RATE = 0.9999;

        
        var oldGuess = LOW_RATE, newGuess = LOW_RATE;
        var lowGuess = LOW_RATE, highGuess = HIGH_RATE;
        var newGuess = LOW_RATE;
        var oldVpl, newVpl;
        var guess = LOW_RATE;

        for(var i = 0; i < ITERATIONS; i++){
            var vpl = empreendimento.vpl(guess, values, attr);
            // console.log("Tentativa= " + i + ";\nTIR = " + guess + ";\nVPL = " + vpl);

            if(vpl > 0 && vpl < PRECISION) break;
            
            if(!oldVpl) oldVpl = vpl;
            else oldVpl = newVpl;

            newVpl = vpl;

            if(i > 0){
                if(oldVpl < newVpl){
                    if(oldVpl < 0 && newVpl < 0) highGuess = newGuess; 
                    else lowGuess = newGuess;
                } else {
                    if(oldVpl > 0 && newVpl > 0) lowGuess = newGuess;
                    else highGuess = newGuess;
                }
            }

            oldGuess = guess;
            guess = (lowGuess + highGuess) / 2;
            newGuess = guess;
        }

        return guess;
    }

    return empreendimento;
}]);