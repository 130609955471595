app.controller('profile.controller', ['$scope', '$http', 'Message', 'Session', 'estados', function($scope, $http, Message, Session, estados){
    $scope.view = 'dados';

    $scope.estados = estados;
    $scope.user = Session.getCredentials();

    $scope.local = {};

    $scope.setUser = function(){
        if($scope.local.password != $scope.local.confirmPassword) return;
        if(!this.checkPassword()) return;
        if(!$scope.local.email) return;
    }

    $scope.resetPassword = function(){

    }

    $scope.checkPassword = function(){
        if(!$scope.local.password) return true;
        if($scope.local.password.length < 6) {
            console.log($scope.local.password.length);
            $scope.local.passwordError = "A senha deve conter 6 ou mais caracteres"
            return false
        }

        return true;
    }

    $scope.save = function(){
        Message.showSpinner();
        $http.put('/profile', $scope.user).then(function(res){
            Message.showSuccess("Dados atualizados com sucesso!");
        }, function(err){
            Message.showError("Ocorreu um erro ao atualizar os seus dados, tente novamente mais tarde.");
        }).finally(function(){
            Message.hideSpinner();
        })
    }


}]);