app.directive('numberFormat', ['$filter', 'formatador', function($filter, formatador){
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function($scope, $elem, $attr, ngModel){
            
            //Se o comprimento não for informado, tratar como decimal de 2 casas
            var length = $attr.numberFormat || 2;
            var allowEmpty = $attr.allowEmpty;

            //Processar somente quando ocorrer o blur
            ngModel.$overrideModelOptions({
                updateOn: 'blur'
            });

            //Formatar o input quando der blur
            $elem.blur(function(){
                var modelValue = formatador.parseFloat($elem.val());
                if(!allowEmpty && !modelValue) modelValue = 0;
                $elem.val($filter('number')(modelValue, length));
            });

            //Formatar o input quando for alterado programaticamente
            ngModel.$formatters.push(function(val){
                if(!allowEmpty && !val) val = 0;
                return $filter('number')(val, length);
            });
            
            //converter o input para float
            ngModel.$parsers.push(function(val){
                val = formatador.parseFloat(val);
                if(!allowEmpty && !val) val = 0;
                return val;
            });
        }
    }
}]);