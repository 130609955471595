app.factory('$url', [function(){
    return function(path, params){
        var url = '';
        
        for(var i in path){
            url += '/'
            url += path[i]
        }

        if(params){
            url += '?';
            var first = true;
            for(var i in params) {
                if(first) first = false;
                else url += '&';
                
                url += i;
                url += '=';
                url += params[i];
            }
        }

        return url;
    }
}]);