app.directive('guage', function(){
    return {
        restrict: 'E',

        template : `
            <div class="guage-container">
                <div class="meter" style="
                    transform: rotate({{(rotation())}}deg);
                    -ms-transform: rotate({{(rotation())}}deg);
                    -moz-transform: rotate({{(rotation())}}deg);
                    -mo-transform: rotate({{(rotation())}}deg);
                    -webkit-transform: rotate({{(rotation())}}deg);">
                </div>
                <div class="overlay1">{{(value / max * 100) | number : 2}}%</div>
                <div class="overlay2">{{name}}</div>
            </div>
        `,
        scope :{
            max : '=',
            value : '=',
            name : '@',
            min : '='
        },
        link : function(s, e, a){
            s.rotation = function(){
                var rotation = s.value / s.max;
                if(rotation > 1) rotation = 1;
                if(rotation < 0) rotation = 0;
                return rotation * 180.0;
            }

            if(s.min){
                let rotation = s.min / s.max * 180.0;
                $('.guage-container', e).css({
                    background: '-webkit-repeating-linear-gradient(' + rotation + 'deg, #F44336 0%, #F44336 50%, #2196F3 50%, #2196F3 100%)',
                    background: '-o-repeating-linear-gradient(' + rotation + 'deg, #F44336 0%, #F44336 50%, #2196F3 50%, #2196F3 100%)',
                    background: '-moz-repeating-linear-gradient(' + rotation + 'deg, #F44336 0%, #F44336 50%, #2196F3 50%, #2196F3 100%)',
                    background: 'repeating-linear-gradient(' + rotation + 'deg, #F44336 0%, #F44336 50%, #2196F3 50%, #2196F3 100%)'
                })
            }
        }
    }
})