app.factory('Negociacao', ['$http', '$url', function($http, $url){
    var factory = {};

    factory.newIntercalada = function() {
        return new (function() {
            this.mes = 0.0;
            this.valor = 0.0;
        });
    }

    factory.process = function(list) {
        return list;
    }

    factory.find = function(negociacao){
        if(negociacao._id) negociacao = negociacao._id;
        return $http.get($url(['negociacao', negociacao]));
    }

    factory.page = function(analise, page){
        if(analise._id) analise = analise._id;
        return $http.get($url(['negociacao', analise, 'page', page]));
    }

    factory.update = function(negociacao, body, params) {
        if(negociacao._id) negociacao = negociacao._id;
        return $http.put($url(['negociacao', negociacao], params), body);
    }

    factory.aceitar = function(negociacao, proposta) {
        if(negociacao._id) negociacao = negociacao._id;
        return $http.put($url(['negociacao', negociacao, 'aceitar', proposta]));
    }

    factory.recusar = function(negociacao, proposta) {
        if(negociacao._id) negociacao = negociacao._id;
        return $http.put($url(['negociacao', negociacao, 'recusar', proposta]));
    }

    return factory;
}]);