app.factory('Session', ['$http', function($http){
    var factory = {};

    factory.getCredentials = function(){
        var token = $('#session').val();
        if(!token) return false;
        return JSON.parse(atob(token));
    }

    return factory;
}]);