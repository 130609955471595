app.component('negociacaoPaginator', {
    template: //html
    `   
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="list-group">
                    <a ui-sref="app.group.analise.negociacao({negociacao: negociacao._id})" class="list-group-item list-group-item-action text-dark" ng-repeat="negociacao in $ctrl.list">
                        <h5>{{negociacao.comprador.nome}} ({{negociacao.comprador.cpf}}) <small class="float-right" ng-class="{'text-crossed': !negociacao.apartamento.disponivel}">Apartamento {{negociacao.apartamento.codigo}}</small></h5>
                        <div class="row justify-content-between">
                            <div class="col-12 col-md-6">
                                <strong>Corretor:</strong> {{negociacao.corretor.name}}
                            </div>
                            <div class="col-12 col-md-6">
                                <strong>Contato:</strong> {{negociacao.comprador.email}} / {{negociacao.comprador.telefone}}
                            </div>
                        </div>
                    </a>
                </div>
            </div>

            <nav class="mt-2">
                <ul class="pagination">
                    <li class="page-item" ng-class="{'disabled': ($ctrl.loading || $ctrl.current_page == 1)}">
                        <a class="page-link" href="javascript:void(0)" ng-click="$ctrl.first()"><i class="fa fa-angle-double-left"></i></a>
                    </li>

                    <li class="page-item" ng-class="{'disabled': ($ctrl.loading || $ctrl.current_page == 1)}">
                        <a class="page-link" href="javascript:void(0)" ng-click="$ctrl.previous()"><i class="fa fa-angle-left"></i></a>
                    </li>

                    <li class="page-item disabled">
                        <a class="page-link" href="javascript:void(0)">
                            <span ng-show="$ctrl.loading"><i class="fa fa-cog fa-spin"></i> Carregando</span>
                            <span ng-hide="$ctrl.loading">Página {{$ctrl.current_page}} de {{$ctrl.page_count}}</span>
                        </a>
                    </li>
                    
                    <li class="page-item" ng-class="{'disabled': ($ctrl.loading || $ctrl.current_page == $ctrl.page_count)}">
                        <a class="page-link" href="javascript:void(0)" ng-click="$ctrl.next()"><i class="fa fa-angle-right"></i></a>
                    </li>

                    <li class="page-item" ng-class="{'disabled': ($ctrl.loading || $ctrl.current_page == $ctrl.page_count)}">
                        <a class="page-link" href="javascript:void(0)" ng-click="$ctrl.last()"><i class="fa fa-angle-double-right"></i></a>
                    </li>
                </ul>
            </nav>
        </div>
    `,
    bindings : {
        analise: '='
    },
    controller: ['Negociacao', function(Negociacao){
        var $scope = this;
        
        $scope.refresh = function(){
            $scope.loading = true;
            Negociacao.page($scope.analise_id, $scope.current_page).then(res => {
                $scope.current_page = res.data.current_page;
                $scope.page_count = res.data.page_count;
                $scope.list = Negociacao.process(res.data.list);
            }, err => {
                console.log(err);
            }).finally(function(){
                $scope.loading = false;
            });
        }


        $scope.first = function(){
            $scope.current_page = 1;
            $scope.refresh();
        }

        $scope.next = function(){
            $scope.current_page++;
            $scope.refresh();
        }

        $scope.previous = function(){
            $scope.current_page--;
            $scope.refresh();
        }

        $scope.last = function(){
            $scope.current_page = $scope.page_count;
            $scope.refresh();
        }

        $scope.$onInit = function(){
            $scope.list = [];
            $scope.current_page = 1;
            $scope.page_count = 0;

            $scope.analise_id = $scope.analise._id;
            $scope.venda = $scope.analise[$scope.analise.recurso.nome];

            $scope.refresh();
        }
    }]
});