app.factory('Cobranca', ['$http', function($http){
    var cobranca = {};
    
    cobranca.list = function(parameters){
        var url = '/cobranca';

        var first = true;
        for(var i in parameters){
            if(first) {
                url += '?'
                first = false;
            } else url += '&'
            url += i + '=' + parameters[i];
        }

        return $http.get(url);
    }

    //TODO create, updaate, delete

    cobranca.save = function(plano, data){
        var url = '/cobranca/' + plano;
        return $http.post(url, data);
    }

    return cobranca;
}]);