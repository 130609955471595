app.controller('fluxo-caixa.associativo.analise.ctrl', ['$scope', 'analise', 'Message', function($scope, analise, Message){
    //dados para trabalho
    $scope.analise = analise;
    $scope.plano = analise.plano_associativo;


    $scope.reload = function() {
        $scope.total_saldo_mensal = 0;
        $scope.total_pj_disponivel = 0;
        $scope.total_pj_utilizado = 0;

        $scope.fluxo = $scope.plano.fluxoCaixa();

        // for(var i = 0; i < $scope.fluxo.length; i++) {
        //     $scope.total_saldo_mensal += $scope.fluxo[i].saldo_mensal
        //     $scope.total_pj_disponivel += $scope.fluxo[i].apoio_producao
        //     $scope.total_pj_utilizado += $scope.fluxo[i].apoio_producao_utilizavel
        // }
    }

    $scope.reload();
}]);