app.controller('fluxo-total.empresario.analise.ctrl', ['$scope', 'analise', 'Message', function($scope, analise, Message){
    $scope.analise = analise;
    $scope.plano = analise.plano_empresario;

    $scope.reload = function() {
        $scope.fluxo = analise.plano_empresario.fluxoTotal();

        var executado = 0;
        for(var i = 0; i < $scope.plano.executado.length; i++) {
            executado += ($scope.plano.executado[i].pct || 0);
        }

        Message.clear();
        if(executado <= 100) {
            Message.showInfo('Total de obra executado: ' + executado + '%');
        } else {
            Message.showWarning('ATENÇÃO! O total de obra executado excede 100% : ' + executado + '%');
        }
    }

    if(!$scope.plano.executado) $scope.plano.executado = [];

    for(var i = 0; i < $scope.plano.periodo_obra; i++) {
        if(!$scope.plano.executado[i]) $scope.plano.executado[i] = {pct: 0};
    }

    if($scope.plano.executado.length > $scope.plano.periodo_obra)
        $scope.plano.executado.splice($scope.plano.periodo_obra, $scope.plano.executado.length);

    $scope.reload();
}]);