app.factory('Apartamento', ['$http', '$url', function($http, $url){
    var factory = {};
    
    //Analise
    factory.list = function(groupId, analiseId, tipologiaId){
        return $http.get($url(['apartamento', groupId, analiseId, tipologiaId]));
    }

    factory.create = function(groupId, analiseId, tipologiaId, apartamento) {
        return $http.post($url(['apartamento', groupId, analiseId, tipologiaId]), apartamento);
    }

    factory.delete = function(groupId, analiseId, tipologiaId, apartamento) {
        return $http.delete($url(['apartamento', groupId, analiseId, tipologiaId, apartamento._id]));
    }

    return factory;
}]);