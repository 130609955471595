app.controller('analise.controller', ['$scope', 'situacao', 'Message', 'Analise', 'Formulas', 'analise', 'group',  '$state',
function($scope, situacao, Message, Analise, Formulas, analise, group, $state) {

    $scope.status = situacao;
    $scope.analise = analise;
    $scope.other = false;
    $scope.groupId = group._id;
    $scope.grupo =  group;

    $scope.benchmarkName = '';
    $scope.history = [];

    if(situacao.error) {
        Message.alert(situacao.message, situacao.detail);
    }
    
    $scope.save = function() {
        var hide = Message.showLoading("Salvando alterações...");
        Analise.update(group._id, analise).then(function(res){
            if(res.data.error) {
                Message.alert(res.data.message, res.data.detail);
            } else {
                Message.showSuccess("Alterações salvas com sucesso!", 'check');
                $scope.analise.ultima_alteracao = Date.now();
            }
        }, function(err){
            console.log(err);
            Message.alert("Erro", "Ocorreu um erro ao tentar salvar as alterações, tente novamente mais tarde");
        }).finally(function(){
            hide();
        });
    }

    $scope.benchmark = function() {
        var loading = Message.loadBlock('Criando benchmark...');
        $('#benchmark-modal').modal('hide');
        Analise.createBenchmark(group._id, analise._id, {
            nome: $scope.benchmarkName,
            analise: analise,

        }).then(function(res){
            if(res.data.error) {
                Message.alert(res.data.message, res.data.detail);
            } else {
                Message.showSuccess("Benchmark criada com sucesso!", 'check');
            }
        }, function(err){
            Message.alert("Erro", "Ocorreu um erro ao tentar salvar as alterações, tente novamente mais tarde");
        }).finally(function(){
            loading.close();
        });
    }

    $scope.showHistory = function(){

        var loading = Message.loadBlock('Carregando benchmarks...');
        Analise.listBenchmarks(group._id, analise._id).then(function(res){
            if(res.data.error) {
                Message.alert(res.data.message, res.data.detail);
            } else {
                $scope.history = res.data;
                $('#benchmark-history').modal();
            }
        }, function(err){
            Message.alert("Erro", "Ocorreu um erro ao tentar carregar o historico, tente novamente mais tarde");
        }).finally(function(){
            loading.close();
        })
    }

    $scope.compare = function(benchmark) {

        var loading = Message.loadBlock('Carregando "' + benchmark.nome + '"...');
        $('#benchmark-history').modal('hide');

        Analise.getBenchmark(group._id, analise._id, benchmark._id).then(function(res){
            if(res.data.error) {
                Message.alert(res.data.message, res.data.detail);
            } else {
                Formulas.set(res.data);

                $scope.other = res.data[analise.recurso.nome];
            }
        }, function(err){
            Message.alert("Erro", "Ocorreu um erro ao tentar carregar a benchmark, tente novamente mais tarde");
        }).finally(function(){
            loading.close();
        });
    }

    $scope.share = function(state){
        var previousState = $scope.analise.is_public;
        $scope.analise.is_public = state;

        Analise.share(group._id, analise).then(function(result){
            if(result.data.error) {
                Message.alert(result.data.message, result.data.detail);
                $scope.analise.is_public = previousState;
            }

        }, function(err){
            Message.alert('Erro', 'Ocorreu um erro ao publicar essa analise.');
            $scope.analise.is_public = previousState;
        });
    }

    $scope.shareState = function(){
        return $state.href(analise.recurso.share_state, {
            analise: analise._id
        }, {absolute: true});
    }

    $scope.copied_to_clip = false;
    $scope.copyShareLink = function(){
        document.getElementById("share-url").select();
        document.execCommand("copy");
        $scope.copied_to_clip = true;
    }

}]);