app.factory('Tipologia', ['$http', '$url', function($http, $url){
    var factory = {};
    
    factory.new = function(_analise){
        return new (function(analise) {
            this.nome = "";
            this.analise = analise;
            
            this.proposta = {
                valor_avaliacao: 0.0,
    
                desconto: 0.0,
                subsidio: 0.0,
                fgts: 0.0,
    
                sinal: 0.0,
                intercaladas: [],
                numero_parcelas: 0.0,
                valor_parcela: 0.0,
                chaves: 0.0,
            };
    
            this.apartamentos = [];
        })(_analise);
    }

    factory.newIntercalada = function() {
        return new (function() {
            this.mes = 0.0;
            this.valor = 0.0;
        });
    }

    factory.process = function(list) {
        for(var i = 0; i < list.length; i++) {
            var tipologia = list[i];
            
            var monthsBetween = function(d1, d2) {
                var months;
                months = (d2.getFullYear() - d1.getFullYear()) * 12;
                months -= d1.getMonth() + 1;
                months += d2.getMonth();
                return months <= 0 ? 0 : months;
            }

            Object.defineProperty(tipologia, 'venda', {
                get: function(){
                    const { nome } = this.analise.recurso;
                    return this.analise[nome] || {};
                }
            });

            Object.defineProperty(tipologia.venda, 'mes_chaves', {
                get: function(){
                    if(!this.data_chaves) return 0;
                    var tokens = this.data_chaves.split('/');
                    var date_target = new Date(tokens[2], tokens[1], tokens[0]);
                    return monthsBetween(new Date(), date_target);
                }
            });

            Object.defineProperty(tipologia, 'f_juros', {
                get: function(){
                    var f_juros = valor => valor;
                    if(this.venda.is_juros_simples === true) {
                        f_juros = (valor, mes, pct) => valor + (valor * (pct / 100) * mes);
                    } else if(this.venda.is_juros_simples === false) {
                        f_juros = (valor, mes, pct) => (valor * Math.pow((1 + (pct / 100)), mes));
                    }
                    return f_juros;
                }
            });

            Object.defineProperty(tipologia, 'parcelas_decrescentes', {
                get: function(){
                    var a1 = this.proposta.valor_parcela * 2 / (this.proposta.numero_parcelas + 1);
                    var razao = a1/ (this.proposta.numero_parcelas);
        
                    var pa = [];
                    var a = a1;
                    for(var i = 0; i < this.proposta.numero_parcelas; i++){
                        pa.push(a);
                        a -= razao;
                    }
                    
                    return pa;
                }
            });

            Object.defineProperty(tipologia.proposta, 'parcelas_pa', {
                get: function() {
                    var a1 = this.valor_parcela * 2 / (this.numero_parcelas + 1);
                    var razao = a1/ (this.numero_parcelas);
        
                    var pa = [];
                    var a = a1;
                    for(var i = 0; i < this.numero_parcelas; i++){
                        pa.push(a);
                        a -= razao;
                    }
                    
                    return pa;
                }
            });

            Object.defineProperty(tipologia, 'pro_soluto', {
                get: function(){
                    var pro_soluto = 0.0;

                    if(this.proposta.intercaladas){
                        for(var i = 0; i < this.proposta.intercaladas.length; i++) {
                            var intercalada = this.proposta.intercaladas[i];
                            if(intercalada) {
                                if(intercalada.mes > this.venda.mes_chaves) {
                                    pro_soluto += this.f_juros(intercalada.valor, intercalada.mes - this.venda.mes_chaves, this.venda.pct_juros)
                                } else {
                                    pro_soluto += intercalada.valor;
                                }
                            }
                        }
                    }

                    if(this.venda.parcela_decrescente) {
                        var pa = this.proposta.parcelas_pa;
                        console.log(pa);
                        for(var i = 0; i < pa.length; i++) {
                            var mes = i+1;
                            var parcela = pa[i];
        
                            if(mes > this.mes_chaves) {
                                parcela = this.f_juros(parcela, mes - this.venda.mes_chaves, this.venda.pct_juros)
                            }
        
                            pro_soluto += parcela;
                        }
                    } else {
                        for(var i = 0; i < this.proposta.numero_parcelas; i++) {
                            var parcela = this.proposta.valor_parcela;
                            var mes = i + 1;
                            if(mes > this.venda.mes_chaves) {
                                parcela = this.f_juros(parcela, mes - this.venda.mes_chaves, this.venda.pct_juros)
                            }
                            pro_soluto += parcela;
                        }
                    }

                    return pro_soluto;
                }
            });

            Object.defineProperty(tipologia, 'total', {
                get: function(){
                    var total = this.proposta.sinal;
                    total += this.proposta.subsidio || 0;
                    total += this.proposta.fgts || 0;

                    total += this.pro_soluto;

                    total += this.proposta.chaves;

                    return total;
                }
            });

            Object.defineProperty(tipologia, 'vpl', {
                get: function(){
                    
                }
            });

        }

        return list;
    }

    //Analise
    factory.list = function(groupId, analiseId){
        return $http.get($url(['tipologia', groupId, analiseId]));
    }

    factory.page = function(groupId, analiseId, page){
        return $http.get($url(['tipologia', groupId, analiseId, 'page', page]));
    }

    factory.create = function(groupId, analiseId, tipologia) {
        return $http.post($url(['tipologia', groupId, analiseId]), tipologia);
    }

    factory.update = function(groupId, analiseId, tipologia) {
        return $http.put($url(['tipologia', groupId, analiseId]), tipologia);
    }

    factory.delete = function(groupId, analiseId, tipologia) {
        return $http.delete($url(['tipologia', groupId, analiseId, tipologia._id]));
    }

    return factory;
}]);