app.component('chatBody', {
    template : //html
    `
        <div id="chat" class="chat-body">
            <div class="chat-message-board">
                <button class="btn btn-primary btn-block mb-4" ng-click="$ctrl.loadPrevious()" ng-disabled="$ctrl._loading_messages">
                    <span ng-show="$ctrl._loading_messages"><i class="fa fa-cog fa-spin"></i> Carregando</span>
                    <span ng-hide="$ctrl._loading_messages">Mensagens Antigas</span>
                </button>

                <div class="d-flex w-100" ng-repeat="message in $ctrl.messages" ng-class="{'justify-content-end': $ctrl.isMine(message)}">
                    <span class="alert" ng-class="{'alert-success': $ctrl.isMine(message), 'alert-light': !$ctrl.isMine(message)}">
                        <strong>{{message.username}}</strong>
                        <div>{{message.text}}</div>
                        <small class="text-muted">{{message.timestamp | date : 'dd/MM/yyyy HH:mm:ss'}}</small>
                    </span>
                </div>
            </div>
            <div class="chat-form">
                <form class="input-group" ng-submit="$ctrl.send();">
                    <input ng-model="$ctrl.message" class="form-control">
                    <div class="input-group-btn">
                        <button type="submit" class="btn btn-primary"><i class="fa fa-paper-plane"></i></button>
                    </div>
                </form>
            </div>
        </div>
    `,
    bindings : {
        room: "<",
        username: "<",
        userid: "<",
        onNotify: '&'
    },
    controller: ['$scope', 'Messages', function($scope, Messages){
        var self = this;

        self.isMine = function(message) {
            if(!message.userid && !message.user && !self.userid) return true;
            if(message.userid == self.userid || message.user == self.userid) return true;
            return false;
        }

        self.send = function() {
            if(!self.message) return;
            var message = {
                userid: self.userid,
                username: self.username,
                timestamp: Date.now(),
                text: self.message 
            };

            self.socket.emit('message', message);
            self.messages.push(message);
            self.scrollBottom();
            
            self.message = "";
        }

        self.loadPrevious = function(callback) {
            self._loading_messages = true;
            Messages.list('proposta', self.room, self.messages.length).then((result) => {
                if(!result.data.error) {
                    for(var i = 0; i < result.data.length; i++) {
                        self.messages.unshift(result.data[i]);
                    }
                    if(callback) callback();
                }
            }).finally(() => {
                self._loading_messages = false;
            });
        }

        self.scrollBottom = function(){
            $scope.$$postDigest(() => {
                var element = document.getElementsByClassName('chat-message-board')[0];
                element.scrollTop = element.scrollHeight - element.clientHeight
            });
        }

        self.$onInit = function(){
            self.alerts = 0;
            self.socket = io.connect('/proposta');
            self.messages = [];

            self.loadPrevious(() => {
                self.scrollBottom();
            });
            
            self.socket.on('connect', () => {
                self.socket.emit('room', {
                    userid: self.userid,
                    username: self.username, 
                    room: self.room
                });
            });

            self.socket.on('message', (message) => {
                $scope.$apply(function(){
                    self.messages.push(message);
                    if(self.onNotify) self.onNotify();
                    self.scrollBottom();
                });
            });
        }
    }]
});