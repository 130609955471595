app.controller('apartamento.publico.ctrl', ['$scope', 'apartamento', 'NegociacaoPublico', 'Message', function($scope, apartamento, NegociacaoPublico, Message) {
    
    $scope.apartamento = apartamento;
    $scope.analise = apartamento.tipologia.analise;
    $scope.recurso = apartamento.tipologia.analise.recurso;
    $scope.venda = apartamento.tipologia.analise[apartamento.tipologia.analise.recurso.nome];
    $scope.grupo = apartamento.tipologia.analise.grupo;
    $scope.proposta = apartamento.tipologia.proposta;

    //Montar proposta inicial
    $scope.negociacao = {
        apartamento: apartamento,
        comprador: {},
        proposta: {
            sinal: angular.copy(apartamento.tipologia.proposta.sinal),
            intercaladas: [],
            numero_parcelas: angular.copy(apartamento.tipologia.proposta.numero_parcelas),
            valor_parcela: 0,
            chaves: 0
        }
    }
    delete $scope.negociacao.proposta._id;


    $scope.negociacao.proposta.prazo_financiamento = $scope.recurso.params.prazo_padrao;

    $scope.form_status = {
        buyer: {error: true},
        financiamento: {error: true},
        proposta: {error: true}
    }

    $('#comprador-tab').on('shown.bs.collapse', () => {
        $("body").scrollTop(0);
    });

    $('#financiamento-tab').on('shown.bs.collapse', () => {
        $("body").scrollTop(0);
    });

    $('#proposta-tab').on('shown.bs.collapse', () => {
        $("body").scrollTop(0);
    });

    $('#resumo-tab').on('shown.bs.collapse', () => {
        $("body").scrollTop(0);
    });

    $scope.showComprador = function() {
        if($scope.finApi) $scope.finApi.reset();
        $('#comprador-tab').collapse('show');
    }

    $scope.showFinanciamento = function() {
        if(!$scope.form_status.buyer.error) {
            $('#financiamento-tab').collapse('show');
        }
    }

    $scope.showProposta = function() {
        if(!$scope.form_status.financiamento.error) {
            $('#proposta-tab').collapse('show');
        }
    }

    $scope.showResumo = function() {
        if(!$scope.form_status.financiamento.error) {
            $('#resumo-tab').collapse('show');
        }
    }

    $scope.apis = {};
    $scope.passApi = function(name, api) {
        $scope.apis[name] = api;
    }

    $scope.finApi = null;
    $scope.passFinApi = function(api){
        $scope.finApi = api;
    }

    $scope.refreshProposta = function() {
        $scope.negociacao.proposta.fgts = $scope.negociacao.comprador.fgts;
        $scope.negociacao.proposta.subsidio = $scope.negociacao.comprador.subsidio;
        for(var i in $scope.apis) {
            $scope.apis[i].triggerChange();
        }
    }

    $scope.negociacaoId = "";

    $scope.sold = false;
    $scope.send = function(){
        if($scope.form_status.proposta.error) return;
        
        $scope.loading = true;
        NegociacaoPublico.create($scope.negociacao).then((res) => {
            $scope.negociacaoId = res.data._id;
            $('#sent-modal').modal({backdrop: 'static', keyboard: false});
            if(typeof res.data.proposta_aceita !== 'undefined' || res.data.proposta_aceita != null) {
                $scope.sold = true;
            }
        }, () => {
            Message.alert("Erro", "Falha ao enviar proposta, tente novamente mais tarde.");
        }).finally(function(){
            $scope.loading = false;
        });
    }

    

}]);