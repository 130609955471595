app.component('analiseCard', {
    template: //html
    `
        <div class="card">
            <div class="card-header">
                <h5>{{$ctrl.data.nome}}</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 mb-3" >
                        <span ng-show="$ctrl.data.last_update">
                            Alterado {{$ctrl.data.last_update.date | relativeDate}}
                        </span>
                        <span ng-hide="$ctrl.data.last_update">
                            Criado {{$ctrl.data.creation.date | relativeDate}}
                        </span>
                    </div>
                    <div class="col-12 mb-3" >
                        <span ng-show="$ctrl.data.last_update">
                            por {{$ctrl.data.last_update.user.name}}
                        </span>
                        <span ng-hide="$ctrl.data.last_update">
                            por {{$ctrl.data.creation.user.name}}
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <a class="btn btn-danger btn-block" href="javascript:void(0)" ng-click="$ctrl.onRemove()"
                            title="Excluir" bs-tooltip  ng-if="$ctrl.userGroup.authority == 'OWNER'">
                            <i class="fa fa-trash"></i>
                        </a>
                    </div>
                    <div class="col-9 pl-0">
                        <a class="btn btn-primary btn-block" ng-if="$ctrl.data.recurso.nome == 'plano_empresario'"
                            ui-sref="app.group.analise.plano-empresario.informacoes-negocio({analise: $ctrl.data._id})"
                            title="Editar">
                            <i class="fa fa-edit"></i> Editar
                        </a>

                        <a class="btn btn-primary btn-block"" ng-if="$ctrl.data.recurso.nome == 'plano_direto'"
                            ui-sref="app.group.analise.plano-direto.informacoes-negocio({analise: $ctrl.data._id})"
                            title="Editar">
                            <i class="fa fa-edit"></i> Editar
                        </a>

                        <a class="btn btn-primary btn-block"" ng-if="$ctrl.data.recurso.nome == 'plano_associativo'"
                            ui-sref="app.group.analise.plano-associativo.informacoes-negocio({analise: $ctrl.data._id})"
                            title="Editar">
                            <i class="fa fa-edit"></i> Editar
                        </a>

                        <a class="btn btn-primary btn-block"" ng-if="($ctrl.userGroup.authority != 'AGENT' && ($ctrl.data.recurso.nome == 'sbpe' || $ctrl.data.recurso.nome == 'mcmv_faixa_15' || $ctrl.data.recurso.nome == 'mcmv_faixa_2'|| $ctrl.data.recurso.nome == 'mcmv_faixa_3'))"
                            ui-sref="app.group.analise.proposta-vendas.empresa({analise: $ctrl.data._id})"
                            title="Editar">
                            <i class="fa fa-edit"></i> Editar
                        </a>

                        <a class="btn btn-primary btn-block" ng-if="($ctrl.userGroup.authority == 'AGENT' && ($ctrl.data.recurso.nome == 'sbpe' || $ctrl.data.recurso.nome == 'mcmv_faixa_15' || $ctrl.data.recurso.nome == 'mcmv_faixa_2'|| $ctrl.data.recurso.nome == 'mcmv_faixa_3'))"
                            ui-sref="app.group.analise.propostas-corretor({analise: $ctrl.data._id})" title="Propostas">
                            <i class="fa fa-handshake"></i> Propostas
                        </a>

                    </div>
                </div>
            </div>
        </div>
    `,
    bindings : {
        onRemove: '&',
        data: '=',
        userGroup: '='
    }
});