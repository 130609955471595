app.component('resumoComponent', {
    template : //html
    ` 
    <table class="table table-striped table-sm">
        <tbody>
            <tr>
                <th>Sinal (Valor de Entrada)</th>
                <td class="text-right">{{$ctrl.proposta.sinal | currency : 'R$ '}}</td>
            </tr>
            <tr ng-if="!venda.parcela_decrescente">
                <th>Mensais (Pro Soluto)</th>
                <td class="text-right" >{{($ctrl.proposta.numero_parcelas * $ctrl.proposta.valor_parcela) | currency : 'R$ '}}</td>
            </tr>
            <tr ng-if="venda.parcela_decrescente">
                <th>Mensais (Pro Soluto)</th>
                <td class="text-right">{{($ctrl.proposta.valor_parcela) | currency : 'R$ '}}</td>
            </tr>
            <tr ng-if="!venda.parcela_decrescente">
                <td class="text-right"><small>{{$ctrl.proposta.numero_parcelas}}x</small></td>
                <td class="text-right"><small>{{$ctrl.proposta.valor_parcela | currency : 'R$ '}}</small></td>
            </tr>
            <tr>
                <th colspan="2">Intercaladas (Pro Soluto)</th>
            </tr>
            <tr ng-repeat="i in $ctrl.proposta.intercaladas">
                <td class="text-right"><small>{{i.mes}}º Mês</small></td>
                <td class="text-right"><small>{{i.valor | currency : 'R$ '}}</small></td>
            </tr>
            <tr>
                <th>Financiamento</th>
                <td class="text-right">{{$ctrl.proposta.chaves | currency : 'R$ '}}</td>
            </tr>
            <tr>
                <td class="text-right"><small>Prazo do Financiamento</small></td>
                <td class="text-right"><small>{{$ctrl.prazo_financiamento | number : 0}} Mêses</small></td>
            </tr>
            <tr>
                <td class="text-right"><small>Primeira Parcela</small></td>
                <td class="text-right"><small>{{$ctrl.status.financiamento.primeira_parcela | currency : 'R$ '}}</small></td>
            </tr>
            <tr>
                <td class="text-right"><small>Ultima Parcela</small></td>
                <td class="text-right"><small>{{$ctrl.status.financiamento.ultima_parcela | currency : 'R$ '}}</small></td>
            </tr>
            <tr ng-show="$ctrl.proposta.fgts">
                <th>FGTS</th>
                <td class="text-right">{{$ctrl.proposta.fgts | currency : 'R$ '}}</td>
            </tr>
            <tr ng-show="$ctrl.isMcmv">
                <th>Subsidio</th>
                <td class="text-right">{{$ctrl.proposta.subsidio | currency : 'R$ '}}</td>
            </tr>
            <tr class="table-primary">
                <th>Valor Total</th>
                <th class="text-right">{{$ctrl.venda.total($ctrl.proposta) | currency : 'R$ '}}</th>
            </tr>
        </tbody>
    </table>
    `,
    bindings : {
        id: '@',
        venda: '<',
        proposta: '=',
        status: '=',
        isMcmv: '<'
    }
})