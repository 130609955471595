app.component('voucher', {
    template: //html
    `
    <form ng-submit="$ctrl.aplicar()">
        <div class="row">
            <div class="col-12">
                <label>Voucher</label>
                <div class="input-group">
                    <span class="input-group-addon" ng-class="{'text-success' : $ctrl.valido, 'text-warning': (!$ctrl.valido && $ctrl.loading), 'text-danger': (!$ctrl.valido && !$ctrl.loading)}">
                        <i class="fa" ng-class="{'fa-check' : $ctrl.valido, 'fa-refresh fa-spin': (!$ctrl.valido && $ctrl.loading), 'fa-times': (!$ctrl.valido && !$ctrl.loading)}"></i>
                    </span>
                    <input class="form-control" ng-model="$ctrl.voucher" ng-model-options='{ debounce: 1000 }' pattern="[a-zA-Z0-9]{10,}" ng-change="$ctrl.validate()"> 
                </div>
                <small class="form-text text-muted">Deve conter 10 ou mais caracteres, somente letras e números </small>
                
            </div>
        </div>

        <div class="row">
            <div class="col-12 py-2" >
                <div class="alert alert-danger" ng-show="$ctrl.error">
                    {{$ctrl.error}}
                </div>
            </div>

            <div class="col-12 text-right">
                <button class="btn btn-secondary" type="reset" ng-click="$ctrl.cancel()" ng-show="$ctrl.canCancel">
                    Cancelar
                </button>
                <button class="btn btn-primary" type="submit" ng-disabled="$ctrl.aplicando" >
                    <i class="fa" ng-class="{'fa-check': !$ctrl.aplicando, 'fa-refresh fa-spin': $ctrl.aplicando}"></i>
                    <span ng-hide="$ctrl.aplicando">Resgatar</span>
                    <span ng-show="$ctrl.aplicando">Resgatando...</span>
                </button>
            </div>
        </div>

    </form>
    `,
    bindings: {
        grupo: '=',
        onSuccess: '&',
        onCancel: '&',
        canCancel: '<'
    },
    controller: ['Voucher', function(Voucher){
        var scope = this;
        scope.voucher = '';
        
        scope.valido = false;
        scope.loading = false;

        scope.validate = function(){
            scope.valido = false;
            scope.loading = false;
           
            if(!scope.voucher || !scope.voucher.match(/[a-zA-Z0-9]{10,}/g)){
                return;
            }

            scope.loading = true;
            Voucher.find(scope.voucher).then(function(res){
                if(res.data && res.data.qtd_usos > 0){
                    scope.valido = true;
                }
            }).finally(function(){
                scope.loading = false;
            });
        }

        scope.aplicar = function(){

            if(!scope.voucher || !scope.voucher.match(/[a-zA-Z0-9]{10,}/g)){
                scope.error = 'Informe um código valido';
                return;
            }

            scope.aplicando = true;
            Voucher.apply(scope.voucher, scope.grupo).then(function(res){
                if(res.data.error) {
                    scope.error = res.data.message;
                    return;
                }
                scope.onSuccess({result: res.data});
            }, function(err){
                $scope.error = "Ocorreu um erro ao resgatar o voucher, verifique sua conexão com a internet ou tente novamente mais tarde."
            }).finally(function(){
                scope.aplicando = false;
            });
        }

        scope.cancel = function(){
            scope.voucher = '';
            scope.onCancel()
        }

    }]
})