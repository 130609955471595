app.factory('Group', ['$http', '$url', function($http, $url){
    var group = {};

    group.list = function(){
        return $http.get('/group');
    }

    group.read = function(id){
        return $http.get('/group/'+id);
    }

    group.create = function(group){
        return $http.post('/group', group);
    }

    group.update = function(group){
        return $http.put('/group', group);
    }

    group.availableUsers = function(group, query) {
        if(group._id) group = group._id
        return $http.get($url(['group', group, 'available-users'], query));
    }

    group.users = function(group) {
        return $http.get('/group/' + group._id + '/users');
    }

    group.grant = function(group, user, permissions){
        if(group._id) group = group._id;
        if(user._id) user = user._id;

        return $http.post('/group/' + group + "/grant/" + user, permissions);
    }

    group.revoke = function(group, user){
        if(group._id) group = group._id;
        if(user._id) user = user._id;

        return $http.delete('/group/' + group + "/revoke/" + user);
    }

    group.leave = function(group){
        if(group._id) group = group._id;

        return $http.delete('/group/' + group + "/leave");
    }

    group.delete = function(group){
        //Nothing yet
    }


    return group;
}]);