app.factory('Plano', ['$http', function($http){
    var plano = {};
    
    plano.list = function(parameters){
        var url = '/plano';

        var first = true;
        for(var i in parameters){
            if(first) {
                url += '?'
                first = false;
            } else url += '&'
            url += i + '=' + parameters[i];
        }

        return $http.get(url);
    }

    //TODO create, updaate, delete

    return plano;
}]);