app.factory('Recurso', ['$http', function($http){
    var recurso = {};
    
    recurso.list = function(parameters){
        var url = '/recurso';

        var first = true;
        for(var i in parameters){
            if(first) {
                url += '?'
                first = false;
            } else url += '&'
            url += i + '=' + parameters[i];
        }

        return $http.get(url);
    }

    //TODO create, updaate, delete

    return recurso;
}]);