app.component('manageUserModal', {
    template: //html 
    `
    <div id="manage-user-modal" class="modal fade">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5>Gerenciar Usuários</h5>
                    <button class="close" data-dismiss="modal">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="list-group">
                        <div class="list-group-item d-flex justify-content-between align-items-center" ng-repeat="ug in $ctrl.userGroups">
                            <span class="mr-auto">{{ug.user.name}}</span>
                            <select ng-disabled="$ctrl.userGroup.authority != 'OWNER'" ng-model="ug.authority" class="mr-1" ng-change="$ctrl.onChange(ug);">
                                <option value="OWNER">PROPIETÁRIO</option>
                                <option value="ANALIST">ANALISTA</option>
                                <option value="AGENT">CORRETOR</option>
                            </select>
                            <button ng-show="$ctrl.userGroup.authority == 'OWNER'" class="btn btn-danger" ng-click="$ctrl.removeUser(ug);">
                                <i class="fa fa-user-minus"></i>
                            </button>
                        </div>
                    </div>
                    <div ng-show="$ctrl.error" class="alert alert-danger mt-2">
                        {{$ctrl.error}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    `,
    bindings: {
        group: '=',
        userGroup: '='
    },
    controller: ['Group', 'Message', function(Group, Message){
        var scope = this;

        scope.refresh = function(){
            Group.users(scope.group).then((result) => {
                if(result.data.error){
                    Message.alert(result.data.message, result.data.detail);
                    return;
                }

                scope.userGroups = angular.copy(result.data);
            });
        }

        scope.onChange = function(ug) {
            Group.grant(scope.group, ug.user, ug).then((result) => {
                if(result.data.error) {
                    scope.error = result.data.detail;
                }

                scope.refresh();
            });
        }

        scope.removeUser = function(ug) {
            Group.revoke(scope.group, ug.user).then((result) => {
                if(result.data.error) {
                    scope.error = result.data.detail;
                }

                scope.refresh();
            });
        }

        scope.$onInit = function(){
            scope.userGroups = [];
            $('manage-user-modal').on('show.bs.modal', () => {
                scope.refresh();
            });
        }
    }]
})