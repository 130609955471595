
app.controller('tabela-venda.direto.analise.ctrl', ['$scope', 'analise', 'Message', function($scope, analise, Message){
    $scope.analise = analise;
    $scope.plano = analise.plano_direto;

    $scope.totalVendas = function() {
        var sum = 0;
        for(var i in $scope.plano.vendas){
            sum += $scope.plano.vendas[i].qtd;
        }
        return sum;
    }

    $scope.displayArray = [];

    $scope.initVendasArr = () => {
        //Creates the initial array
        if(!$scope.plano.vendas){
            $scope.plano.vendas = [];
            var i = 0;
            while(i < $scope.plano.periodo_obra)
                $scope.plano.vendas.push({mes: i + 1, qtd: 0});
        } else while($scope.plano.vendas.length < $scope.plano.periodo_obra) {
            $scope.plano.vendas.push({mes: $scope.plano.vendas.length + 1, qtd: 0});
        }
    }

    //Vendas
    $scope.refreshArray = () => {
        
        var arr = [];
        var regras = $scope.plano.tabela_vendas.sort((v1, v2) => v2.mes_inicio - v1.mes_inicio);
        regras.forEach((regra, index) => {
            var next = regras[index - 1];
            var vendas = $scope.plano.vendas.filter((venda) => (venda.mes >= regra.mes_inicio && (next ? venda.mes < next.mes_inicio : true)));
            var conflito = $scope.plano.tabela_vendas.filter((outra) => outra.mes_inicio === regra.mes_inicio).length > 1

            arr.push({
                regra: regra,
                vendas: vendas,
                conflito: conflito
            });
        })

        $scope.displayArray = arr.reverse();
        $scope.validate();
    }

    //Regras
    $scope.addRegra = () => {
        var nextMes = $scope.plano.tabela_vendas.reduce((t, regra) => regra.mes_inicio > t ? regra.mes_inicio : t, 0) + 1;
        $scope.plano.tabela_vendas.push({
            mes_inicio: nextMes,
            pct_sinal: 0,
            
            numero_parcelas: 0,
            pct_parcela: 0,
            
            intercaladas: [],
            pct_intercaladas: 0,

            pct_chaves: 0,
        });
        $scope.refreshArray();
    }

    $scope.remove = (model) => {
        $scope.plano.tabela_vendas = $scope.plano.tabela_vendas.filter((m) => m !== model);
        $scope.refreshArray();
    }

    $scope.onRegraChange = (field) => {
        if(field === 'mes-apuracao')
            $scope.refreshArray();
    }

    $scope.validate = () => {
        $scope.error = {};
        $scope.alert = {};

        var mes1 = $scope.plano.tabela_vendas.find((m) => m.mes_inicio === 1);
        if(!mes1) $scope.alert.mes1 = 'Não existe regra de venda para o 1º mês';

    }
    
    $scope.initVendasArr();
    $scope.refreshArray();
}]);